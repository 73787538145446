@import '../../../../css/import/import.scss';
.p-photographsShow_preview {
  max-width: 1000px;
  max-height: 690px;
  display: block;
}
.p-photographsShow_previewMask {
  background-color: red;
  opacity: 0.5;
  position: absolute;
}
.p-photographsShow_previewBox {
  position: relative;
}
.p-photographsShow_thumbnail {
  max-width: 180px;
  max-height: 180px;
}

.p-photographsShow_tableCaption {
  text-align: left;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: $interval-s;
}
.p-photographsShow_tableCell {
  border: 1px solid $scales;
  min-width: 100px;
}

.p-photographsShow_trimmingSpace {
  border: 3px solid $primary;
  display: block;
  position: relative;
  background-color: gray;
}

.p-photographsShow_OriginalPreview {
}

.p-photographsShow_trimmingPreview {
  position: absolute;
  top: 0;
  left: 0;
}
