.photographySubject {
  align-items: center;
  display: flex;
}

.totalSubjectLabel {
  font-weight: bold;
}

.totalSubjects {
  text-align: end;
}
