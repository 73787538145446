.p-flyer_eventInputLine {
  .c-checkboxLabel {
    line-height: 0;
  }
  .c-label_checkbox {
    padding-right: 6px;
  }
  input[type='checkbox'] {
    height: 0;
  }
}
.p-flyer_textInputWrapper {
  flex-grow: 1;
}
.p-flyer_titleInput {
  min-height: 50px;
}
