@import '@/css/import/import';

.table {
  width: 100%;
}

.tableCell {
  background-color: white;
  padding: 10px;
  text-align: left;

  .tableRow:not(:last-child) {
    border-bottom: 1px solid $scales60;
  }

  .tableHeadRow & {
    border-bottom: 3px solid $scales60;
  }
}

.closedLabel {
  background-color: $scales60;
  color: $white;
  font-size: 1.2rem;
  font-weight: bold;
  margin-right: 10px;
  padding: 5px;
}

.notYetClosed {
  color: $text-sub;
}
