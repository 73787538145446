@import '@/css/import/import';

.sideMenu {
  background-color: #fff;
  border-radius: 4px;
  bottom: 0;
  margin: 10px;
  overflow-x: hidden;
  overflow-y: scroll;
  position: sticky;
  right: 0;
  top: 0;
}

.sideMenuTitle {
  font-size: 1.5rem;
  font-weight: bold;
}

.sideMenuEvent {
  line-height: 2;
  margin: 20px;
  width: calc(100% - 40px);

  dl {
    border-bottom: 1px solid $bg_paleGr;
  }

  dt {
    background: $bg_paleGr;
    border: 1px solid $bg_paleGr;
    border-collapse: collapse;
    padding: 4px;
    padding-left: 10px;
  }

  dd {
    border-collapse: collapse;
    border-left: 1px solid $bg_paleGr;
    border-right: 1px solid $bg_paleGr;
    min-height: 30px;
    padding-left: 10px;
  }
}

.sideMenuLinkGroup {
  background-color: $bg_paleGr;
  line-height: 2;
  margin: 20px;
  padding: 10px;
  width: calc(100% - 40px);
}

.requestOptionContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 2px;
}

.requestOption {
  background-color: $bg_paleGr;
  margin: 2px;
  padding: 1px 5px;
}
