@import '@/css/import/import';

.requestFeeEditLabel {
  flex: 0 0 150px;
  font-weight: bold;
  line-height: 1.2;
  margin-right: 10px;
}

.requestFeeAddButton {
  background-color: #fff;
  border: 1px solid grey;
  border: solid 1px;
  border-radius: 6px;
  color: grey;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 5px;
  margin-left: -1px;
  margin-right: 10px;
  min-height: 32px;
  padding: 6px 10px;
  padding-left: 10px;
  padding-right: 16px;
  transition: 0.2s opacity linear;
}

.requestFeeDeleteButton {
  background-color: #fff;
  border: 1px solid grey;
  border-radius: 6px;
  color: grey;
  cursor: pointer;
  display: inline-block;
  margin-bottom: 5px;
  margin-left: -1px;
  margin-right: 10px;
  min-height: 32px;
  padding: 6px 10px;
  padding-left: 10px;
  padding-right: 16px;
  transition: 0.2s opacity linear;
}

.requestFeeDottedLine {
  border-bottom: 2px dotted grey;
  margin: 20px 0;
}

.requestFeeDisabled {
  opacity: 0.2;
  pointer-events: none;
}
