@import '@/css/import/import';

.summaryTitle {
  font-size: $font-m;
  font-weight: bold;
  text-align: center;
}

.summaryTable {
  border: 2px solid $frame-border;
  margin: auto;
  width: max-content;

  & tr {
    border: 2px solid $frame-border;

    & th,
    & td {
      padding: $base-padding;
    }

    & th {
      color: $primary;
      font-weight: bold;
      text-align: center;
    }

    .totalNumberCell {
      text-align: right;
    }
  }
}

.summaryCloseButton {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
}
