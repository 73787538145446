@import '../../../../css/import/import.scss';

ul {
  &.a-administrator-labelList {
    list-style: inside;
    li {
      display: inline-flex;
      margin-right: 15px;
      &:nth-child(n + 3) {
        margin-right: 5px;
      }

      div {
        &.a-administrator-icon {
          margin-right: 5px;
        }
      }
    }
  }

  &.a-administrator-infoList {
    li {
      display: inline-flex;
      margin-right: 5px;
      width: 150px;
      &:nth-child(3) {
        width: 300px;
      }
      span {
        &.a-administrator-unregistLabel {
          opacity: 0.5;
        }
      }
    }
  }
}

.a-administrator-btn_toggle_sort {
  color: #333;
  border-color: transparent;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  padding-top: 3px;
  padding-bottom: 5px;
  padding-right: 12px;
  padding-left: 22px;
  box-shadow: 0 2px 2px rgb(0 0 0 / 20%);
  background: url(../../../../images/icon_sort_default.png) no-repeat 8px 50% /
    12px auto #fff;
  margin: 0 5px;
  &.is-desc {
    background-color: #fff0e6;
    border: 1px solid #ff8e42;
    font-weight: bold;
  }
  &.is-asc {
    background-image: url(../../../../images/icon_sort_asc.png);
  }
  &.is-desc {
    background-image: url(../../../../images/icon_sort_desc.png);
  }
}
