@import '../../../../css/import/import.scss';

.p-salesForecastsTable_wrap {
  width: 100%;
  overflow: auto;
}

.p-salesForecastsTable {
  border-bottom: 2px solid $frame-border;
  width: max-content;

  &_title {
    font-size: $font-m;
    font-weight: bold;
  }

  & tr {
    border-top: 2px solid $frame-border;

    & th,
    & td {
      padding: $base-padding;
      font-size: $font-s;
    }

    & th {
      color: $primary;
    }

    .p-salesForecastsTable_fixed1,
    .p-salesForecastsTable_fixed2,
    .p-salesForecastsTable_fixed3,
    .p-salesForecastsTable_fixed4,
    .p-salesForecastsTable_fixed5 {
      position: sticky;
      left: 0;
      z-index: 1;
      background-color: $white;
      pointer-events: none;
      &:before {
        content: '';
        position: absolute;
        top: -1px;
        left: -1px;
        width: 100%;
        height: 100%;
      }
    }
    .p-salesForecastsTable_fixed1 {
      left: 0;
    }

    .p-salesForecastsTable_fixed2 {
      background-color: #eff7e6;
      left: 110px;
    }

    .p-salesForecastsTable_fixed3 {
      left: 220px;
    }

    .p-salesForecastsTable_fixed4 {
      left: 420px;
      background-color: #eff7e6;
    }

    .p-salesForecastsTable_fixed5 {
      left: 500px;
      &:before {
        border-right: 1px solid $scales60;
      }
    }
  }
}

.p-totalForecastsArea {
  display: inline-flex;
  font-size: $font-m;
  &.p-totalForecastsArea__small {
    font-size: $font-default;
    padding: $base-padding;
  }
  &_title {
    font-weight: bold;
  }

  &_price {
    background-color: $white;
    padding: 6px 14px;
  }
}

.c-statusLabel {
  &.c-statusLabel__salesForecast-small {
    font-size: $font-xs;
    padding: 2px 5px;
  }

  &__yellow {
    background-color: #fdf7d9 !important;
    border: 1px solid $yellow !important;
    color: $yellow !important;
  }
}

.p-salesForecastsIndex-bg-lightgray {
  background-color: $bg_paleGr !important;
}

.p-salesForecastsIndex-bg-gray {
  background-color: $scales60 !important;
}

.p-salesForecastsIndex-bg-darkgray {
  background-color: $scales60 !important;
}

.p-salesForecastsIndex-bg-darkgray-overlay {
  background-color: $scales30;
}

.p-salesForecastsIndex-bg-yellow {
  background-color: rgba($yellow, 20%) !important;
}

.p-salesForecastsIndex-bg-white {
  background-color: $white !important;
}

.p-salesForecastsIndex-bg-green {
  background-color: #eff7e6 !important;
}

.p-salesForecastsIndex-w-80 {
  width: 80px;
}

.p-salesForecastsIndex-w-100 {
  width: 100px;
}

.p-salesForecastsIndex-w-110 {
  width: 110px;
}

.p-salesForecastsIndex-w-130 {
  width: 130px;
}

.p-salesForecastsIndex-w-200 {
  width: 200px;
}

.p-salesForecastsIndex-event-request-icon {
  padding: $base-padding;
  pointer-events: auto;
}
.p-salesForecastsIndex-event-name-link {
  pointer-events: auto;
}
