@import '@/css/import/import';

.parent {
  align-items: center;
}

.text {
  font-size: 1.5rem;
}

.body {
  margin: 15px;
}
