.p-photographerOrganizationShow_frame {
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #e6e6e6;
  padding: calc(10px * 1.5);
  margin-bottom: 15px;
  width: 1000px;
}

.p-photographerOrganizationShow_label {
  flex: 0 0 300px;
  margin-right: 10px;
  font-weight: bold;
  line-height: 2;
  margin: 0;
  padding: 0;
  list-style-type: none;
  width: 500px;
}

.p-photographerOrganizationShow_link {
  background: #fff;
  width: 100%;
  height: 60px; /* 縦幅を調整 */
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  button {
    margin: 20px;
    padding: 10px 50px;
    border: none;
    color: #fff;
    font-weight: bold;
    &.back {
      background-color: grey;
    }
    &.edit {
      background-color: #ff8e42;
    }
  }
}
