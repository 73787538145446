.c-priceTable {
  font-size: $font-s;
  border-bottom: 1px solid $scales60;
  margin-bottom: 25px;
  width: 100%;
  background-color: white;
  thead th {
    color: $primary;
    font-weight: bold;
    text-align: left;
    &.u-align_center {
      text-align: center;
    }
  }
  th,
  td {
    border-top: 1px solid $scales60;
    border-right: 2px dotted $scales60;
    padding: $base-padding-narrow $base-padding;
    width: 7rem;
    &:last-child {
      border-right: none;
    }
    &.p-priceIndex_background {
      background-color: $bg_paleGr40;
    }
    &.p-priceIndex_bold {
      font-weight: bold;
      white-space: nowrap;
    }
    p {
      &.p-priceIndex_primary {
        color: $primary;
      }
    }
    &.p-priceIndex_inputmode {
      padding: 4px;
    }
    input {
      &.p-priceInput {
        padding: 0px;
        margin: 0px;
        font-size: 12px;
        min-height: 12px;
      }
    }
  }
}
