@import '../../../../../../css/import/import';

.arrow {
  cursor: pointer;
}

.assignStatusLabel {
  display: inline-block;
  font-size: 1rem;
  font-weight: bold;
  margin-right: 5px;
  padding-left: 5px;
  padding-right: 5px;

  &.decided {
    background-color: $primary;
    border: 1px solid $primary;
    color: white;
  }

  &.trainee {
    border: 1px solid $primary;
    color: $primary;
  }
}

.closeLinkContainer {
  margin-top: 10px;
  text-align: center;
}

.closeLinkWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
}

.closeLink {
  align-items: center;
  color: $scales60;
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-decoration: underline;
}
