@import '@/css/import/import';

.table {
  width: 100%;
}

.tableDetailMailHead {
  font-weight: normal;
  width: 100px;
}

.tablePhotographingDayHead {
  font-weight: normal;
  width: 150px;
}

.tableEventHead {
  font-weight: normal;
  max-width: 0;
}

.tableHead {
  background: $bg_paleGr40;
  border: 1px solid $scales;
  padding: 5px 10px;
}

.currentEvent {
  background: $primary-variant;
}

.tableEventCell {
  max-width: 0;
}

.tableCell {
  border: 1px solid $scales;
  overflow: hidden;
  padding: 5px 10px;
  white-space: nowrap;
}

.eventLinkBox {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
}

.eventLink {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.eventLinkIcon {
  flex-shrink: 0;
}

.unsent {
  color: $primary;
  font-weight: bold;
}

.cancelledLabel {
  @extend %label;

  background-color: #000;
  flex-shrink: 0;
}
