.restTimeWrap {
  display: flex;
}

.restTimeWrap > *:not(:first-child) {
  flex: 0 0 100px;
  margin-right: 10px;
}

.timeBetweenText {
  flex: none !important;
  margin-top: 10px;
  width: 10px;
}
