@import '../../../../css/import/import.scss';

.p-photographerOrganizationCreate_box {
  min-height: 32px;
  padding: 6px 10px;
  background-color: #fff;
  border: 1px solid #a6a6a6;
  border-radius: 6px;
  width: 100%;
  font-size: 1.4rem;
  &.auto {
    background-color: #e6e6e6;
  }
}

.p-photographerOrganizationCreate_button {
  border: none;
  color: #fff;
  padding: 6px 40px;
  border-color: transparent;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
  &.api {
    background-color: #005ebb;
  }
  &.submit {
    background-color: #ff8e42;
  }
}

.p-photographerOrganizationCreate_businessResistation_Area {
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #e7e7e7;
  border-radius: 6px;
}

.p-photographerOrganizationCreate_businessResistation_title {
  padding-bottom: 30px;
  font-weight: bold;
}

.p-photographerOrganizationForm_link {
  background: #fff;
  width: 100%;
  height: 60px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @extend %default-box;
}
