@import '../../../../css/import/import.scss';
.p-photographs_breadnav {
  font-size: 2rem;
  margin-bottom: 20px;
}
.p-photographs_breadnav_separator {
  margin: 0 5px;
}
.p-photographs_linkContainer {
  flex-grow: 1;
}
.p-photpgraphs_eventThumbnailContainer {
  max-width: 180px;
}
.p-photographs_photoContainer {
  width: 220px;
  height: 350px;
  position: relative;
  color: $scales;
  border: 1px solid $bg_paleGr60;
  &.p-photographs_photoContainer__mainThumbnail {
    background-color: #fff550;
  }
  &.p-photographs_photoContainer__hide {
    background-color: silver;
  }
  &:hover {
    border: 1px solid #000;
  }
  &.p-photographs_photoContainer__validationError {
    border: 1px solid red;
  }
  &:hover .p-photographs_photographername {
    visibility: visible;
  }
  &:hover .p-photographs_photoControls {
    visibility: visible;
  }
}
.p-photographs_validationError {
  color: red;
  display: block;
}
.p-photographs_photoLink {
  display: block;
}
.p-photographs_photo {
  max-width: 180px;
  max-height: 180px;
  display: block;
  margin: 10px auto 0;
}
.p-photographs_photoname {
  margin: 0 auto;
  display: block;
}
.p-photographs_photographername {
  visibility: hidden;
  text-align: center;
}
.p-photographs_jizenngflag {
  text-align: center;
}
.p-photographs_soldCount {
  text-align: center;
}

.p-photographs_photoControls {
  font-size: 0.8rem;
  visibility: hidden;
  text-align: center;
}
.p-photographs_photoControl {
  margin: 0 3px;
}
.p-photographs_salesInfo {
  font-size: 1.6rem;
  color: red;
  font-weight: bold;
}
.p-photographs_linkStr {
  margin: 0 3px;
  &.p-photographs_linkStr__selected {
    font-weight: bold;
  }
}
.p-photographs_categoryMenu {
  border: 1px solid $scales;
  width: 100%;
}

.p-photographs_categoryList {
  margin-right: 30px;
  margin-top: 10px;
}

.p-photographs_categoryMenu_cell {
  border: 1px solid $bg_paleBr10;
  padding: 5px;
  background: white;
  &:nth-child(even) {
    background: $bg_paleGr40;
  }
}

.p-photographs_categoryMenu_link__selected {
  font-weight: bold;
}

.p-photographs_categoryMenuWrapper {
  &.is-close {
    height: 80px;
    overflow-y: hidden;
    position: relative;
    &::after {
      position: absolute;
      content: '';
      display: block;
      width: 100%;
      height: 40px;
      background: linear-gradient(
        rgba(255, 255, 255, 0.3),
        rgba(255, 255, 255, 1)
      );
      bottom: 0;
    }
  }
}

.p-photographs_categoryMenuButtonWrapper {
  width: 100%;
  text-align: center;
  &.is-close {
    position: absolute;
    top: 40px;
    z-index: 1;
  }
}

.p-photographs_starImg {
  width: 12px;
  height: 12px;
}

.p-photographs_tableCell {
  padding: 5px;
}

.p-photographs_tableCaption {
  color: $primary;
  font-weight: 700;
  padding: 5px;
  text-align: center;
}
.p-photographs_tableRow {
  border-bottom: 1px solid rgba(166, 166, 166, 0.6);
}
.p-photographs_labelRow {
  display: none;
}
