@import '../../../../css/import/import.scss';

span {
  &.o-owneraccounts-statusLabel {
    margin: 5px 0;
    margin-bottom: 18px;
    float: left;
  }
}

hr {
  &.o-owneraccounts-line_plane {
    clear: both;
  }
}
