@import '../import';

.c-arrangePhotographers_photographer {
  white-space: nowrap;
  &.c-arrangePhotographers_photographer__checkbox {
    padding: 2px 4px;
    cursor: pointer;
    padding-left: 20px;
    position: relative;

    &:before {
      content: '';
      width: 12px;
      height: 12px;
      background: #e4e4e4;
      position: absolute;
      top: 50%;
      left: 4px;
      margin-top: -6px;
    }
    &:after {
      content: '';
      position: absolute;
    }
    .c-checkbox:checked + &:after {
      width: 4px;
      height: 8px;
      border-bottom: 2px solid #ff8e42;
      border-right: 2px solid #ff8e42;
      transform: rotate(45deg);
      top: 48%;
      left: 8px;
      margin-top: -7px;
    }
  }
}
