.container {
  display: flex;
}

.title {
  flex: 0 0 90px;
  font-weight: bold;
  line-height: 1.2;
  margin-right: 10px;
  padding: 8px 10px;
}

.wideTitle {
  flex: 0 0 140px;
}

.content {
  display: flex;
  flex: 1 1;
  justify-content: space-between;
  padding: 5px 10px;
  position: relative;
  text-align: -webkit-match-parent;
  word-break: break-all;
}
