@import '../import';

/* ===================
期間設定フォーム
==================== */
.scrollbar {
  font-family: Osaka, 'ＭＳ Ｐゴシック', Arial, sans-serif;
  margin: 15px 0;
  overflow-x: auto;
  width: 100%;

  &::-webkit-scrollbar {
    height: 8px;
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(#a6a6a6, 60%);
    border-radius: 10px;
  }
}

.grid_wordBreak {
  word-break: break-word;
}

.grid_row {
  display: contents;

  &.grid_row__close {
    & .grid_value,
    & .idLink_hasBg {
      background: $scales;
      color: $white;
    }
  }
}

.grid_other_department_container {
  display: grid;
  background: #fafafa;
  border-bottom: 1px solid rgba(#a6a6a6, 60%);
  min-width: 2500px;
  grid-template-columns: 50px // チェックボックス
    140px // 団体
    50px // 事前確認
    60px // その他の注意点
    60px // プラン
    140px // 撮影日
    250px // イベント
    60px // OP用メモ
    50px // 種類
    60px // 申請オプション
    50px // 公開設定
    80px // 認証タイプ
    210px // 認証キー
    60px // 固定掲載
    repeat(4, 140px) // 掲載開始日、バリュー終了日、掲載終了日、責任者終了日
    50px // チラシの有無
    90px // チラシ発送指定日の有無
    repeat(4, 140px) // チラシ、SB、FAX用紙、DVD
;
  &.finished {
    background: #e8e8e8;

    & .grid_value {
      background: #e8e8e8;

      &:nth-child(2n) {
        background: #e8e8e8;
      }
    }
  }
}

.grid_other_department_container_wrap {
  // 見づらいので一日以下
  min-width: 2500px;
  background: #fafafa;
  max-height: 90vh;

  // 見づらいので一日以下
  min-width: 2400px;

  &.grid_header {
    border-top: 1px solid rgb(166 166 166 / 60%);
    position: sticky;
    top: 0;
    z-index: 30;
  }
}

.grid_caption,
.grid_value {
  background: #fafafa;
  font-size: 1rem;
  padding: 5px;
  text-align: center;

  &:nth-child(2n) {
    background: #fff;
  }

  &.error {
    background: rgba(#f25252, 10%);

    & .icon-error {
      margin: 2px 2px 0 0;
    }
  }

  & .textlink {
    @extend %textdecoration-none;
  }

  & .idLink_hasBg {
    display: flex;
  }
}

.grid_caption {
  color: #ff8e42;
  font-weight: bold;
}

.pdt_xs {
  padding-top: 2px;
}

/* ===================
tooltip
==================== */
.tooltip {
  &_fixed {
    background: $dark70;
    border-radius: calc(#{$base-radius} / 2);
    color: $white;
    font-size: $font-xs;
    height: 0;
    opacity: 0;
    padding: 3px 5px;
    position: fixed;
    top: -10000px;
    white-space: nowrap;

    @extend %transition_default;
  }

  &:hover .tooltip_fixed {
    bottom: 20px;
    font-size: $font-xs;
    height: initial;
    left: 55px;
    opacity: 1;
    text-align: left;
    top: initial;
    z-index: 100;
  }
}
