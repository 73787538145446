@import '@/css/import/import';

.arrow {
  cursor: pointer;
}

.closeLinkContainer {
  margin-top: 10px;
  text-align: center;
}

.closeLinkWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
}

.closeLink {
  align-items: center;
  color: $scales60;
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-decoration: underline;
}

.internalMemoBox {
  align-items: stretch;
  background-color: #fafafa;
  border: 1px solid $scales60;
  display: flex;
  margin-top: 20px;
  padding: 20px;
}

.internalMemoTitle {
  background-color: #fafafa;
  padding-right: 40px;
}

.internalMemoText {
  flex-grow: 1;
}

.internalMemoButton {
  @extend %btn-shadow;
  @extend %link-el;
  @extend %textdecoration-none;

  background-color: $dark;
  border-radius: 0 $base-radius $base-radius 0;
  color: $white;
}
