@import '../../../../css/import/import.scss';

/* 一覧画面 */
.p-groupsList {
  & > li {
    @extend %transition_default;
    &:hover {
      @extend %lines-hover;
    }
  }
}
.p-groups_id {
  min-width: 65px;
  font-size: $font-xs;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.p-groupsList_detailBox {
  width: calc(100% - 80px);
}
.p-groupsList_upper {
  margin-bottom: 5px;
  width: 100%;
}
.p-groupsList_info {
  font-size: $font-s;
  margin-top: -10px;
  margin-bottom: 5px;
  padding-bottom: 5px;
  & li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 10px;
  }
  &_address {
    width: 95%;
    margin-bottom: 2px;
  }
  &_other {
    width: 120px;
  }
}
.p-groupsList_names {
  width: calc(100% - 460px);
}
.p-groupsList_contract {
  border-top: 1px solid $scales15;
}
.p-groupsList_contractStatus {
  font-size: $font-s;
  margin-top: 5px;
  padding-bottom: 5px;
  & li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 10px;
  }
}
.p-groupsList_contractInfo {
  background: $bg_paleBr10;
  padding: 2px 5px;
  border-radius: calc(#{$base-radius} / 2);
  font-size: $font-xs;
  margin-top: 3px;
}
.p-groupsList_contractInfoCaption {
  color: $brown;
  font-weight: bold;
  margin-right: 10px;
}
.p-groupsList_contractInfoValue {
  display: inline-block;
  text-align: right;
  padding-right: 5px;
  margin-right: 5px;
}
.p-groupsList_contractInfoValue.has-separate {
  border-right: 1px solid $bg_paleBr10;
}
.p-groupsLabel {
  &_operation {
    & .c-statusLabel {
      font-size: $font-xs;
      width: 48px;
      padding-right: 5px;
      padding-left: 5px;
      margin-right: 5px;
    }
  }
}
.p-groupsList_contract {
  .p-groupsList_contractCancell {
    background: $scales;
    color: $white;
  }
}

.p-groupsList_detail_names {
  word-break: break-word;
}

@include media_sp {
  .p-groupsList_detailBox,
  .p-groupsList_names {
    width: 100%;
  }
  .p-groupsList_detail_names {
    width: calc(100% - 120px);
  }
  .p-groupsList_names,
  .p-groupsList_detail_names {
    margin-top: 5px;
    margin-bottom: 10px;
  }
  .p-groupsList_upper {
    justify-content: flex-end;
  }
  .p-groupsList_contractInfo {
    justify-content: flex-start;
    margin-right: 0;
  }
  .p-groupsList_info {
    & li {
      margin-right: 0;
    }
  }
}
