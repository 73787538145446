@import '../../../css/import/import';

.c-arrangePhotographers {
  &_photographer {
    @extend %label;
    padding: 2px 4px;
    margin-right: 4px;
    margin-top: 6px;
    font-size: 1.4rem;
    border: 1px solid $scales;
    background: $white;
    color: $text;

    &__doingArrange {
      background-color: $scales15;
    }
  }
}
