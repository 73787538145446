@import '../../../../css/import/import';

.p-photographersShow_partnerMenuLinkArea {
  position: fixed;
  right: 6%;

  li {
    margin-top: 3px;
  }
}

@media screen and (max-width: 1700px) {
  .p-photographersShow_partnerMenuLinkArea {
    right: 1%;
    position: relative;
  }
}

.p-photographersShow_pageTitle span {
  height: 20px;
  margin-top: 9px;

  &.c-statusLabel {
    color: #333333;
    background: #ffffff;
  }
}

.p-photographersShow_pageLinkArea {
  margin-left: 10px;
  margin-bottom: 6px;

  a {
    text-decoration: none;

    div {
      margin-top: 2px;
    }
  }
}

.p-photographersShow_pageLinkIcon {
  transform: rotate(-90deg);
  width: 10px;
  font-size: 16px;
  line-height: 2px;
}

@include media-sp {
  .p-photographersShow_profileInfo {
    div.p-photographersShow_full_with_isSpMode {
      width: 100%;
    }
  }
}

.p-photographersShow_photographerTable {
  width: 100%;

  img {
    max-width: 150px;
  }

  tr {
    border-top: 2px dotted rgba(166, 166, 166, 0.6);
    border-right: 2px dotted rgba(166, 166, 166, 0.6);
    border-collapse: collapse;

    td {
      padding: 0;
    }

    .c-dataValue ul {
      height: auto;
      width: 100%;
    }
  }

  tr:last-child {
    border-bottom: 2px dotted rgba(166, 166, 166, 0.6);
  }

  ul {
    height: 100%;

    li {
      padding-left: 10px;
      padding-top: 8px;
      padding-bottom: 7px;
    }

    li:nth-child(1) {
      flex: 0 0 160px;
      background: #eee;
      height: 100%;
    }

    li li.c-dataLabel {
      background: #fff;
    }

    li li:nth-child(1) {
      flex: 0 0 70px;
    }
  }

  .l-col_wrap.l-flex {
    margin: 0;
  }
}

.p-photographersShow_eventTable {
  width: 100%;

  td,
  th {
    line-height: 2;
    text-align: center;
    border: 2px dotted rgba(166, 166, 166, 0.6);
    border-collapse: collapse;
  }

  thead th {
    background: #eee;
  }
}

.p-photographersShow_facePhotoNothing {
  background-color: #ccc;
  width: 131px;
  border-radius: 1px;
  text-align: center;
  line-height: 151px;
}

.p-photographersShow_facePhoto {
  height: 151px;
  margin: 0.4em 0;
}
