@import './import/import.scss';

@include media_pc {
  .u-pcOff {
    display: none;
  }
}
@include media_sp {
  .u-spOff {
    display: none;
  }
  img {
    width: 100%;
  }
}

/* mediaクエリ内でのextend用 */
.link-el {
  @extend %link-el;
}
.form-common {
  @extend %form-common;
}
.btn-shadow {
  @extend %btn-shadow;
}
.default-box {
  @extend %default-box;
}

// .c-clearfix {
//   &:after {
//     content: "";
//     display: table;
//     clear: both;
//   }
// }
.c-textlink {
  @extend %link-el;
  @extend %text-link-el;
  &_clear {
    @extend %link-el;
    @extend %text-link-el;
    font-size: 1rem;
    display: inline-block;
    font-weight: normal;
  }
  //   &.c-iconlink {
  //     min-height: 14px;
  //     display: inline-block;
  //     padding-right: 18px;
  //     position: relative;
  //     &:after,&:before {
  //       content: "";
  //       position: absolute;
  //       top: 50%;
  //     }
  //     &:after {
  //       width: 14px;
  //       height: 14px;
  //       border-radius: calc(#{$base-radius} / 2);
  //       background: rgba($blue, 80%);
  //       margin-top: -7px;
  //       right: 0;
  //     }
  //     &:before {
  //       width: 5px;
  //       height: 5px;
  //       border-top: 1px solid $white;
  //       border-right: 1px solid $white;
  //       transform: rotate(45deg);
  //       right: 5px;
  //       z-index: 1;
  //       margin-top: -2.5px;
  //     }
  //   }
  &.c-idLink_hasBg {
    display: inline-block;
    padding: 2px 5px;
    background-color: $bg_paleGr;
    border-radius: calc(#{$base-radius} / 2);
  }
  //   &.c-popuplink {
  //     position: relative;
  //     &:after {
  //       content: "";
  //       position: absolute;
  //       width: 14px;
  //       height: 14px;
  //       background: url(/images/icon_popup.png) no-repeat center center / 100% auto;
  //       top: 2px;
  //     }
  //   }
  //   &.c-popuplink__isRight {
  //     padding-right: 20px;
  //     &:after {
  //       right: 0;
  //     }
  //   }
  //   &.c-popuplink__isLeft {
  //     padding-left: 20px;
  //     &:after {
  //       right: auto;
  //       left: 0;
  //     }
  //   }
}
.c-idLink_hasBg {
  display: inline-block;
  padding: 2px 5px;
  background-color: $bg_paleGr;
  border-radius: calc(#{$base-radius} / 2);

  &.c-idLink_hasBg__flex {
    display: inline-flex;
    padding: 5px 10px 5px 5px;
    align-items: center;

    i {
      margin-right: 3px;
    }
  }
}
// @include media_pc {
//   .c-textlink__isPc {
//     text-decoration: none;
//     color: $text;
//     pointer-events: none;
//   }
// }
// @include media_sp {
//   .c-textlink__isSp {
//     text-decoration: none;
//     color: $text;
//     pointer-events: none;
//   }
// }
.c-textOmit {
  display: block;
  @extend %text-ellipsis;
}

// .c-idLink {
//   font-size: $font-xs;
//   display: inline-block;
//   & .c-textlink {
//     vertical-align: top;
//   }
// }

// .c-text_horizontal {
//   display: inline-block;
//   margin-right: 1em;
// }
.c-error_text,
.c-alert_text {
  color: $brightRed;
}
.c-error_text,
.c-success_text {
  font-size: $font-s;
}
.c-error_block,
.c-alert_box,
.c-warning_block,
.c-success_block {
  width: 100%;
  border-radius: $base-radius;
  padding: 15px;
}
.c-error_block,
.c-alert_box {
  background: rgba($brightRed, 10%);
  margin-bottom: 30px;
}
// .c-warning_block {
//   background: rgba($yellow, 15%);
// }
// .c-success_block {
//   background: rgba($green, 10%);
// }
@include media_sp {
  .c-error_block {
    margin-bottom: 20px;
    border-radius: 0;
  }
}
// .c-success_text {
//   color: $green;
// }

.c-inputError {
  font-size: $font-xs;

  &_item {
    color: $brightRed;
  }
}

.c-error_listItem,
.c-success_listItem {
  list-style-type: disc;
  margin-left: 1em;
  font-size: $font-s;
  margin-bottom: 3px;
}
// .c-success_listItem {
//   color: $green;
// }
.c-error_listItem {
  color: $brightRed;
}
.c-emptyState_box {
  background: $bg_paleGr;
  text-align: center;
  padding: 15px 10px;
  color: $scales;
}

.c-label {
  @extend %label;
  &.c-label {
    &__monotoneLine {
      color: $text;
      border: 1px solid $scales;
      background: $white;
    }
    &__monotone {
      color: $text;
      background: $scales30;
    }
  }
}

/* status表示 */
.c-statusLabel {
  @extend %label;
  // TODO: duplicated .small -> .c-statusLabel__smallを使ってね
  &.c-statusLabel__small,
  &.small {
    font-size: $font-xs;
    padding: 0 8px;
    margin-right: 5px;
  }
  &.c-statusLabel {
    &__positive {
      color: $primary;
      background: $primary-variant;
      border: 1px solid $primary;
    }
    &__negative {
      border: 1px solid $scales;
      background: $scales30;
      color: $scales;
    }
    &__undecided {
      color: $yellow;
      background: rgba($yellow, 15%);
      border: 1px solid $yellow;
    }
    // FIXME: 前方互換のため残していますが c-label__monotoneLineを使ってください
    &__monotoneLine {
      color: $text;
      border: 1px solid $scales;
      background: $white;
    }
    // FIXME: 前方互換のため残していますが c-label__monotoneを使ってください
    &__monotone {
      color: $text;
      background: $scales30;
    }
  }
}

/*タイトル周り*/
.c-page_title {
  color: $text;
  text-decoration: none;
  margin-right: 10px;
  font-size: 2.4rem;
  font-weight: bold;
}
.c-page_back {
  display: inline-block;
  position: relative;
  font-size: $font-s;
  background: $scales30;
  padding: 4px 8px 4px 18px;
  margin-bottom: 5px;
  border-radius: calc(#{$base-radius} / 2);
  color: $text;
  @extend %textdecoration-none;
  @extend %btn-shadow;
  &:before {
    content: '';
    width: 6px;
    height: 6px;
    border-top: 1px solid $text;
    border-right: 1px solid $text;
    position: absolute;
    top: 50%;
    left: 8px;
    margin-top: -3px;
    transform: rotate(-135deg);
  }
}
@include media_sp {
  .c-page_title {
    font-size: $font-l;
  }
}
.c-section_title {
  font-size: $font-m;
  font-weight: bold;
}
.c-section_title.tin {
  font-weight: normal;
}
.c-section_subTitle,
.c-section_subTitle_br,
.c-section_subTitle_red,
.c-section_subTitle_gry,
.c-section_subTitle_green,
.c-section_subTitle_white,
.c-section_subTitle_yellow {
  padding: 6px 10px;
  background: rgba($primary, 20%);
  border-radius: $base-radius;
  font-size: $font-s;
  font-weight: normal;
  &_br {
    background: $bg_paleBr10;
  }
  &_red {
    background: rgba($brightRed, 10%);
  }
  &_gry {
    background: $scales15;
  }
  &_green {
    background: rgba($green, 10%);
  }
  &_white {
    background: $white;
  }
  &_yellow {
    background: rgba($yellow, 15%);
  }
}

/*フィルターリスト*/
// .c-title_filter_box {
//   position: relative;
// }
// .c-title_filter {
//   @extend %form-common;
//   @extend %link-el;
//   padding: 10px 50px 10px 10px;
//   position: relative;
//   min-width: 220px;
//   &:before,&:after {
//     content: "";
//     position: absolute;
//     top: 50%;
//   }
//   &:before {
//     width: 28px;
//     height: 28px;
//     background: $bg_paleGr;
//     right: 7px;
//     margin-top: -14px;
//     border-radius: 4px;
//   }
//   &:after {
//     width: 6px;
//     height: 6px;
//     border-top: 2px solid $primary;
//     border-right: 2px solid $primary;
//     right: 18px;
//     margin-top: -6px;
//     transform: rotate(135deg);
//   }
// }
// .c-title_filterList {
//   @extend %default-box;
//   max-height: 288px;
//   overflow-y: scroll;
//   overflow-x: hidden;
//   font-size: $font-s;
//   position: absolute;
//   top: 100%;
//   left: 0;
//   min-width: 100%;
//   z-index: 1000;
//   white-space: nowrap;
// }
// .c-title_filterListItem {
//   &.c-title_filterListItem__isActive {
//     @extend %list-is-active;
//     background-position: 92% 50%;
//     background-size: 12px auto;
//   }
//   & a {
//     display: block;
//     padding: 10px;
//     @extend %link-el;
//     @extend %textdecoration-none;
//     color: $text;
//   }
// }

/* リストボックス */
/* FIXME: duplicated .c-index .c-indexList をつかってネ */
.c-indexList {
  border-top: 1px solid $scales60;
  margin-bottom: 25px;
  &.c-indexList__isEven .c-indexListItem:nth-child(even) {
    background: $bg_paleGr40;
  }
}
.c-indexListItem {
  border-bottom: 1px solid $scales60;
  padding: $base-padding 0;
}
/* リスト */
// .c-textListItem,.c-numberListItem {
//   margin-left: 1em;
// }
/* リストテキスト */
// .c-textListItem {
//   list-style-type: disc;
// }
/* numリスト */
// .c-numberListItem {
//   list-style-type: decimal;
// }

/* アコーディオンリスト */
// .c-list_accordion {
//   background: $primary-variant;
//   border-radius: $base-radius;
// }
// .c-list_accordion_item {
// }
// .c-list_accordion_item:not(:last-child) {
//   border-bottom: 1px solid $scales60;
// }
// .c-list_accordion {
//   &_title {
//     @extend %link-el;
//     position: relative;
//   }
//   &_title, &_body {
//     padding: calc(#{$base-padding} * .8);
//   }
//   &_body {
//     padding-top: 0;
//   }
// }
// .c-list_accordion_title {
//   &:after {
//     @extend %transition_default;
//     content: "";
//     width: 6px;
//     height: 6px;
//     border-top: 2px solid $primary;
//     border-right: 2px solid $primary;
//     transform: rotate(135deg);
//     position: absolute;
//     top: 50%;
//     right: 15px;
//     margin-top: -6px;
//   }
//   &.c-list_accordion_title__isOpen:after {
//     transform: rotate(-45deg);
//     margin-top: -4px;
//   }
// }

/* ファイルアップロード用 */
.c-fileUpload {
  min-height: 32px;
  border-radius: $base-radius;
  border: 1px solid $scales60;
  background: $scales30;
  color: $scales;
}

/*一覧表示*/
.c-index,
.c-summary {
  .c-indexList {
    &_id {
      margin-bottom: 5px;
      &__short {
        min-width: 8rem;
      }
    }
  }
  table.c-indexList {
    border-bottom: 1px solid $scales60;
    margin-bottom: 25px;
    width: 100%;
    thead th {
      color: $primary;
      font-weight: bold;
      text-align: left;

      &.u-align_center {
        text-align: center;
      }
    }
    tr.c-indexList_row {
      &.c-indexList_row__canceled {
        td {
          background-color: $bg_paleGr40;
          &:nth-child(2n) {
            background-color: $bg_paleGr50;
          }
        }
      }
    }
    th,
    td {
      border-top: 1px solid $scales60;
      padding: $base-padding-narrow $base-padding;
      width: 7rem;
      &:nth-child(2n) {
        background-color: $bg_paleGr40;
      }
    }
    .c-statusLabel {
      font-size: $font-s;
      padding: 2px 5px;

      &.c-statusLabel__small {
        font-size: $font-xs;
      }
      &.c-statusLabel__wide {
        padding: 2px 15px;
      }
    }
    .c-indexList_column {
      &__nowrap {
        white-space: nowrap;
      }
      &_xxs {
        width: 3rem;
        min-width: 3rem;
      }
      &_xs {
        width: 5rem;
        min-width: 5rem;
      }
      &_s {
        width: 7rem;
        min-width: 5rem;
      }
      &_m {
        width: 10rem;
        min-width: 7rem;
      }
      &_ml {
        width: 15rem;
        min-width: 10rem;
      }
      &_l {
        width: 20rem;
        min-width: 15rem;
        word-break: break-all;
      }
      &_xl {
        width: 30rem;
        min-width: 15rem;
        word-break: break-all;
      }
      &_xxl {
        width: 50rem;
        min-width: 30rem;
        word-break: break-all;
      }
    }
  }
  .c-frame {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}

/*サマリ*/
.c-summary {
  .l-col_wrap > li {
    padding: $base-padding-narrow 0;
  }
}
@include media-pc {
  .c-summary {
    .l-col_wrap {
      border-top: 2px dotted $scales60;

      &:last-child > li {
        padding: $base-padding-narrow 0 0 0;
      }

      &.l-col_wrap__noBorder {
        border-top: none;
        & > li {
          padding: 0 0 $base-padding-narrow 0;
        }
      }
    }
  }
}
@include media-sp {
  .c-summary {
    .l-col_wrap {
      & > li {
        border-top: 2px dotted $scales60;
      }
      &:last-child > li:last-child {
        padding: $base-padding-narrow 0 0 0;
      }

      &.l-col_wrap__noBorder > li:first-child {
        border-top: none;
        padding: 0 0 $base-padding-narrow 0;
      }
    }
  }
}

/*入力用*/
.c-label_line {
  margin-top: 5px;
  padding-bottom: 5px;
}
.c-label_innerHalf {
  width: 48%;
}
.c-dataLabel,
.c-dataLabel_hasOption {
  flex: 0 0 90px;
  margin-right: 10px;
  font-weight: bold;
  line-height: 1.2;
  &.tin {
    font-weight: normal;
  }
  & small {
    font-size: $font-xs;
  }
  & .c-required {
    display: block;
  }
}
.c-required {
  color: $brightRed;
  font-weight: normal;
}
.c-dataValue {
  flex: 1;
  word-break: break-all;
}
.c-label_innerHalf__isPostcode {
  margin-right: 2%;
}
.c-searchForm {
  .c-frame {
    padding: calc(#{$base-padding} * 1.5) $base-padding-narrow;
    overflow: visible;
  }
  .c-dataLabel {
    padding: $base-padding-narrow $base-padding;
  }
  .c-dataValue {
    padding: 5px $base-padding;
  }
  .c-label_checkbox,
  .c-label_radioBtn {
    margin-right: 5px;
  }
}

@include media_sp {
  .c-dataLabel_hasOption {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px;
  }
  .c-label_innerHalf {
    width: 100%;
    padding-bottom: 10px;
  }
  .c-label_innerHalf__isPostcode {
    width: 48%;
  }
  .c-label_innerHalf:last-child {
    padding-bottom: 0;
  }
  .c-dataLabel {
    & small {
      &.c-required {
        display: inline-block;
      }
    }
  }
  .c-searchForm {
    .c-searchForm_column {
      width: 100%;
    }
  }
}

.c-radioLabel,
.c-checkboxLabel {
  display: inline-block;
  &.is-disabled {
    pointer-events: none;
    & .c-label_checkbox,
    & .c-label_radioBtn {
      @extend %disabled;
    }
  }
}

.c-label_checkbox,
.c-label_radioBtn {
  display: inline-block;
  @include form-common;
  @include link-el;
  padding-right: 16px;
  padding-left: 30px;
  margin-right: 10px;
  margin-bottom: 5px;
  margin-left: -1px;
  position: relative;
  &.small {
    min-height: 30px;
    padding-top: 5px;
    padding-bottom: 6px;
    padding-right: 12px;
    font-size: $font-s;
  }
  &:hover {
    border-color: $primary;
  }
  &:before {
    content: '';
    width: 14px;
    height: 14px;
    background: #e4e4e4;
    position: absolute;
    top: 50%;
    left: 10px;
    margin-top: -7px;
  }
  &.is-disabled {
    @extend %disabled;
    box-shadow: none;
  }
}
.c-label_checkbox {
  &:before {
    border-radius: calc(#{$base-radius} / 2);
  }
}
.c-checkbox_listbox {
  display: inline-block;
  padding: 6px 10px;
  background: $primary-variant;
  border-radius: $base-radius;
  &.is-disabled {
    @extend %disabled;
  }
}
.c-checkbox_list {
  display: none;
}
.c-label_radioBtn {
  &:before {
    border-radius: 50%;
  }
}
.c-checkbox:checked + .c-label_checkbox,
.c-radioBtn:checked + .c-label_radioBtn {
  font-weight: bold;
  background: $primary-variant;
  border-color: $primary;
  &:before {
    background: $white;
  }
  &:after {
    content: '';
    position: absolute;
  }
}
.c-label_radioBtn_inline,
.c-label_radioBtn_inline_white {
  @extend %link-el;
  padding-right: 10px;
  padding-left: 20px;
  margin-right: 10px;
  margin-left: -1px;
  position: relative;
  &:before {
    content: '';
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: #e4e4e4;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -7px;
  }
}
.c-label_radioBtn_inline_white {
  &:before {
    background: $white;
  }
}
.c-radioBtn:checked + .c-label_radioBtn_inline,
.c-label_radioBtn_inline_white {
  font-weight: bold;
  &:before {
    background: $primary-variant;
  }
  &:after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $primary;
    top: 50%;
    left: 3px;
    margin-top: -4px;
  }
}
.c-checkbox:checked + .c-label_checkbox {
  &:after {
    width: 5px;
    height: 10px;
    border-bottom: 2px solid $primary;
    border-right: 2px solid $primary;
    transform: rotate(45deg);
    top: 46%;
    left: 15px;
    margin-top: -7px;
  }
}
.c-radioBtn:checked + .c-label_radioBtn {
  &:after {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: $primary;
    top: 50%;
    left: 13px;
    margin-top: -4px;
  }
}
// .c-label_radioBtn_inline , .c-label_radioBtn_inline_white {
//   @extend %link-el;
//   padding-right: 10px;
//   padding-left: 20px;
//   margin-right: 10px;
//   margin-left: -1px;
//   position: relative;
//   &:before {
//     content: "";
//     width: 14px;
//     height: 14px;
//     border-radius: 50%;
//     background: #e4e4e4;
//     position: absolute;
//     top: 50%;
//     left: 0;
//     margin-top: -7px;
//   }
// }
// .c-radioBtn:checked + .c-label_radioBtn_inline ,.c-label_radioBtn_inline_white {
//   font-weight: bold;
//   &:before {
//     background: $primary-variant;
//   }
//   &:after {
//     content:"";
//     position: absolute;
//     width: 8px;
//     height: 8px;
//     border-radius: 50%;
//     background: $primary;
//     top: 50%;
//     left: 3px;
//     margin-top: -4px;
//   }
// }
.c-label_checkbox_inline,
.c-label_checkbox_list {
  font-size: $font-s;
  display: inline-block;
  @extend %link-el;
  position: relative;
  &:before {
    content: '';
    width: 14px;
    height: 14px;
    border-radius: calc(#{$base-radius} / 2);
    background: #e4e4e4;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -7px;
  }
  /* FIXME: 雑だけど取り急ぎ。何も書かないときのチェックボックス */
  &.c-label_checkbox_list__empty {
    padding-left: 14px;
  }
}
.c-label_checkbox_inline {
  padding-left: 20px;
  margin-right: 7px;
  margin-bottom: 5px;
  position: relative;
  &:before {
    left: 1px;
  }
}
.t-bgBox_gray {
  .c-label_checkbox_inline,
  .c-label_checkbox_list {
    &:before {
      background: $white;
      border: 1px #e4e4e4 solid;
    }
  }
}
// .is-disabled {
//   & .c-label_checkbox_inline,
//   & .c-label_radioBtn_inline,
//   & .c-label_radioBtn_inline_white {
//     color: $scales;
//   }
// }
.c-label_checkbox_list {
  padding-left: 18px;
  min-height: 16px;
  vertical-align: text-bottom;
}
.c-checkbox_inline:checked + .c-label_checkbox_inline,
.c-checkbox_list:checked + .c-label_checkbox_list {
  &:before {
    background: $primary;
  }
  &:after {
    content: '';
    position: absolute;
    width: 5px;
    height: 10px;
    border-bottom: 2px solid $white;
    border-right: 2px solid $white;
    transform: rotate(45deg);
    top: 46%;
    left: 5px;
    margin-top: -7px;
  }
}
.c-checkbox_inline:checked + .c-label_checkbox_inline {
  &:after {
    left: 5px;
  }
}
// .c-label_checkbox_primary,
// .c-label_checkbox_gray {
//   padding-top: 5px;
//   padding-bottom: 5px;
//   @extend %btn-shadow;
//   &:before {
//     background: $white;
//   }
// }
// .c-label_checkbox_primary {
//   border-color: $primary;
//   background: $primary;
//   color: $white;
//   &:hover {
//     border-color: $primary;
//   }
// }
// .c-label_checkbox_gray {
//   border-color: $scales;
//   background: $scales;
//   color: $white;
//   &:hover {
//     border-color: $scales;
//   }
// }
.c-checkbox_gray:checked + .c-label_checkbox_gray,
.c-checkbox:checked + .c-label_checkbox_primary {
  background: $primary;
  border-color: $primary;
}
// @include media_sp {
//   .c-label_checkbox, .c-label_radioBtn {
//     margin-right: 6px;
//     margin-left: -1px;
//     margin-bottom: 8px;
//     font-size: $font-s;
//     line-height: 1.4;
//     &.small {
//       padding-top: 7px;
//       font-size: $font-xs;
//     }
//   }
//   .c-label_checkbox_gray {
//     width: 100%;
//     margin-right: 0;
//     padding-top: 9px;
//   }
// }
/* 下部FIXボックス */
// .c-fixedBox_bottom {
//   background: $white;
//   box-shadow: 0 -2px 2px $shadow10;
//   width: 100%;
//   padding: 20px 0;
//   position: fixed;
//   bottom: -150px;
//   left: 0;
//   z-index: 100;
//   text-align: center;
//   transition: all 0.2s ease-in-out;
// }
// .c-fixedBox_bottom__isOpen {
//   bottom: 0;
// }
/* override */
// .c-fixedBox_bottom__isOpen + .l-center_wrap {
//   padding-bottom: 140px;
// }

// .c-switchBox {
//   display: flex;
//   justify-content: flex-start;
//   align-items: center;
//   padding: 5px 10px;
//   border-radius: $base-radius;
//   background: $bg_paleGr;
//   & > span {
//     display: inline-block;
//   }
// }
// @include media_sp {
//   .c-switchBox {
//     padding: 10px 10px 10px 0;
//     background: none;
//   }
// }
.c-switch_label {
  width: 50px;
  position: relative;
  display: inline-block;

  &.is-disabled {
    pointer-events: none;
    & .c-switch_content {
      pointer-events: none;
      &:before {
        background-color: $scales15;
      }
    }
  }
  &.c-switch_label__small {
    width: 30px;
    & .c-switch_content {
      height: 18px;
    }
    & .c-switch_circle {
      top: 1px;
      width: 16px;
      height: 16px;
    }
    & .c-switch_input:checked ~ .c-switch_circle {
      left: 13px;
    }
  }
}
.c-switch_content {
  display: block;
  cursor: pointer;
  position: relative;
  border-radius: 30px;
  height: 26px;
  overflow: hidden;
  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    border-radius: 30px;
  }
  &:before {
    width: calc(100% - 3px);
    height: calc(100% - 3px);
    top: 0;
    left: 0;
    border: 1px solid $scales30;
    background-color: $white;
  }
  &:after {
    background-color: transparent;
    width: 0;
    height: 0;
    top: 50%;
    left: 50%;
    transition: all 0.5s;
  }
}
.c-switch_input {
  display: none;
}
.c-switch_circle {
  display: block;
  top: 2px;
  left: 2px;
  position: absolute;
  box-shadow: 0 2px 6px $scales;
  width: 21px;
  height: 21px;
  border-radius: 20px;
  background-color: $white;
  transition: all 0.2s;
}
.c-switch_input:checked ~ .c-switch_circle {
  left: 27px;
}
.c-switch_input:checked ~ .c-switch_content:after {
  background-color: $primary;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
/* 検索 */
.c-searchBox {
  margin-bottom: 10px;
}
.c-search_filterBox {
  background: $bg_paleGr;
  border-radius: $base-radius;
  padding: 20px;
  display: flex;
}
.c-searchOption_title,
.c-searchOption_box {
  display: flex;
}
.c-searchOption_title {
  align-items: center;
  justify-content: space-between;
}
.c-searchOption_box {
  justify-content: flex-start;
}
.c-searchOption_box {
  & > *:first-child {
    margin-right: 10px;
    width: 80%;
    width: 100%;
  }
}
.c-searchKeyword_input {
  flex: 11;
  margin-right: 15px;
  text-indent: 1.5em;
}
.p-searchKeyword_icon {
  position: absolute;
  top: 11px;
  left: 10px;
}
@include media_sp {
  .c-searchKeyword_input {
    margin-right: 0;
  }
}

// .c-stickyBtnBox {
//   width: 100%;
//   position: sticky;
//   position: -webkit-sticky;
//   bottom: 0px;
//   padding: 10px;
//   margin: 0;
//   z-index: 100;
// }

@include media_sp {
  //   .c-searchBox {
  //     background: $bg_paleGr;
  //     border-radius: $base-radius;
  //     padding: 10px 10px 0;
  //   }
  //   .c-search_filterBox {
  //     border-radius: 0;
  //     padding: 10px 0;
  //   }
  //   .c-search_filterBox > div {
  //     width: 100%;
  //   }
  //   .c-searchOption_title {
  //     justify-content: space-between;
  //   }
  //   .c-searchOption_box {
  //     display: block;
  //   }
  //   .c-searchOption_box {
  //     width: 100%;
  //     & > *:first-child {
  //       margin-right: 0;
  //       margin-bottom: 10px;
  //       width: 100%;
  //     }
  //   }
  //   .c-stickyBtnBox_sp {
  //     background: $white;
  //   }
  .c-stickyBtnBox_search_sp {
    width: 100%;
    position: sticky;
    position: -webkit-sticky;
    bottom: 0px;
    padding: 10px;
    margin: 0;
    z-index: 99;
  }
  //   .c-checkbox__isSearch {
  //     display: none;
  //   }
}
// .c-separation_toggleLine {
//   position: relative;
//   margin-bottom: 10px;
//   &:before {
//     content: "";
//     width: 100%;
//     border-bottom: 2px dotted $scales60;
//     position: absolute;
//     top: 50%;
//     left: 0;
//   }
// }
// .c-separation_toggleBtn {
//   cursor: pointer;
//   font-size: $font-s;
//   position: relative;
//   display: inline-block;
//   background-color: $primary-variant;
//   padding: 2px 24px 3px 10px;
//   border-radius: calc(#{$base-radius} * 2);
//   font-weight: bold;
//   position: relative;
//   &:after {
//     content: "";
//     width: 5px;
//     height: 5px;
//     border-top: 2px solid $primary;
//     border-right: 2px solid $primary;
//     position: absolute;
//     right: 9px;
//     top: 50%;
//     margin-top: -4px;
//     z-index: 1;
//     transform: rotate(135deg);
//     @extend %transition_default;
//   }
//   &.c-separation_toggleBtn__isOpen:after {
//     transform: rotate(-45deg);
//   }
// }
// @include media_sp {
//   .c-separation_toggleLine {
//     &:before {
//       content: none;
//     }
//   }
//   .c-separation_toggleBtn {
//     display: block;
//     background-color: $primary-variant;
//     padding: 3px 24px 5px 10px;
//     border-radius: calc(#{$base-radius} * 3);
//   }
// }
/* 検索結果件数表示 */
.c-text_resultNumber {
  font-weight: bold;
  & > span {
    font-size: 2rem;
  }
}

/*フォーム関連*/
.c-input_plane,
.c-select_plane,
.c-input_txtarea,
.c-input_count,
.c-person_input_component {
  @include form-common;
  @extend %font-family-base;
  width: 100%;
  &.is-disabled {
    @extend %disabled;
  }
  &.is-error {
    border-color: $brightRed;
  }
  &.c-input_plane__small,
  &.c-select_plane__small,
  &.c-input_txtarea__small,
  &.c-input_count__small,
  &.c-person_input_component__small {
    min-height: 30px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
  &.c-input__short,
  &.c-select__short {
    width: 10rem;
  }
}
@include media_sp {
  .c-input_plane,
  .c-select_plane,
  .c-input_txtarea,
  .c-input_count {
    font-size: $font-m;
  }
}
.c-select_plane {
  padding-right: 32px;
  position: relative;
  background-image: url('/images/select_arrow.png');
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 32px auto;
}
.c-select_filter {
  background-color: $primary-variant;
}
.c-input_date {
  position: relative;
  background-repeat: no-repeat;
  background-position: 99% 50%;
  background-size: auto 26px;
  &.c-input_date__small {
    min-height: 30px;
    padding-top: 3px;
    padding-bottom: 3px;
    background-size: auto 20px;
  }
  &.c-input_date__xsmall {
    width: 110px;
    min-height: 20px;
    padding: 4px 8px;
    background-size: auto 18px;
    border-radius: 4px;
  }
}
.c-input_date {
  &:hover::-webkit-clear-button,
  &:focus::-webkit-clear-button {
    opacity: 0;
  }
  &::-webkit-calendar-picker-indicator {
    position: absolute;
    right: 0.9%;
    top: 4px;
    padding: 0;
    width: 33px;
    height: 33px;
    background-color: transparent;
    color: transparent;
    cursor: pointer;
  }
}
.c-input_date {
  background-image: url('/images/icon_datepicker.png');
}
.c-input_multiSelect {
  @extend %font-family-base;
  &__control {
    min-height: 32px !important;
    border-radius: 6px !important;
    border-color: #a6a6a6 !important;
  }
  &.is-error .c-input_multiSelect__control {
    border-color: $brightRed !important;
  }
  &__menu-portal {
    // modal内でselectが表示されるよう.c-pcModalよりも上にする
    z-index: 101 !important;
  }
}

.c-input_select__xxsmall {
  @include form-common;
  @extend %font-family-base;
  width: 100%;
  font-size: 1rem;
  padding: 0;
  min-height: 22px;
  -webkit-appearance: auto;
  appearance: auto;
}

// .c-inpit_countbox {
//   width: 50%;
//   margin-right: 5px;
//   position: relative;
//   &.is-disabled {
//     color: $scales;
//     & .c-input_count {
//       background: $scales15;
//     }
//     & .c-input_count,
//     & .c-input_count_down,
//     & .c-input_count_up {
//       pointer-events: none;
//       color: $scales;
//     }
//   }
// }
// .c-input_range {
//   -webkit-appearance: none;
//   appearance: none;
//   height: 5px;
//   background: $scales15;
//   border-radius: $base-radius;
//   &:focus,
//   &:active {
//     outline: none;
//   }
// }
// .c-input_range::-webkit-slider-thumb {
//   -webkit-appearance: none;
//   appearance: none;
//   cursor: pointer;
//   position: relative;
//   border: none;
//   width: 22px;
//   height: 22px;
//   display: block;
//   @extend %btn-shadow;
//   border-radius: 50%;
//   -webkit-border-radius: 50%;
//   background-color: $primary;
// }
// .is-disabled::-webkit-slider-thumb {
//   @extend %disabled;
//   box-shadow: none;
//   background-color: #dadada;
// }
// .c-input_range:active::-webkit-slider-thumb {
//   box-shadow: 0 0 0 4px rgba(250, 240, 230, .6);
//   transition: .4s;
// }
// .is-disabled_active::-webkit-slider-thumb {
//   box-shadow: none;
// }

// @include media_sp {
//   .c-inpit_countbox {
//     width: 80%;
//   }
// }
// .c-input_count {
//   padding-left: 37px;
//   padding-right: 37px;
// }
// .c-input_count_down,.c-input_count_up {
//   @extend %textdecoration-none;
//   color: $text;
//   position: absolute;
//   top: 50%;
//   width: 40px;
//   height: 34px;
//   text-align: center;
//   margin-top: -16px;
//   line-height: 2.4;
//   cursor: pointer;
// }
// .c-input_count_down {
//   left: 0;
//   border-right: 1px solid $scales60;
// }
// .c-input_count_up {
//   right: 0;
//   border-left: 1px solid $scales60;
// }
.c-input_txtarea {
  min-height: 74px;
  resize: vertical;
}
.c-value_read {
  display: inline-block;
  width: 100%;
  min-height: 32px;
  vertical-align: middle;
  background-color: $scales15;
  border-radius: $base-radius;
  padding: 6px 10px;
}
// .c-value_readItem {
//   display: inline-block;
//   background-color: $scales30;
//   border-radius: $base-radius;
//   padding: 3px 5px;
//   margin-right: 5px;
//   margin-bottom: 3px;
//   font-size: 1.2rem;
// }
// .c-value_reference {
//   display: inline-block;
//   padding: 0 15px 3px;
//   line-height: 1.2;
//   background: $bg_paleGr;
//   border-radius: calc(#{$base-radius} * 3);
//   color: $scales;
// }
// .c-value_placeholder {
//   color: $scales;
// }
.c-input_timeBlock,
.c-input_dateBlock,
.c-select_rangeBlock {
  display: flex;
  justify-content: space-between;
  position: relative;
  & > li {
    width: 45%;
  }
  &:after {
    content: '〜';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);
    margin-top: -10px;
  }
}
/*
  日時入力
*/
// .c-date_time_block {
//   width: 200px;
//   margin-right: 20px;
// }
// .c-time {
//   width: 167px;
//   position: relative;
//   &:before {
//     content: ":";
//     position: absolute;
//     top: 7px;
//     right: 82px;
//   }
// }
// .c-select_time {
//   display: inline-block;
//   width: 76px;
//   &:first-child {
//     margin-right: 15px;
//   }
// }
// .c-times_block {
//   display: flex;
//   justify-content: flex-start;
//   align-items: flex-start;
//   width: 366px;
//   &.c-times_block__isRange .c-time {
//     width: 167px;
//     position: relative;
//     &:before {
//       content: ":";
//       position: absolute;
//       top: 7px;
//       right: 82px;
//     }
//     &:first-child {
//       margin-right: 32px;
//       &:after {
//         content: "〜";
//         position: absolute;
//         top: 7px;
//         right: -22px;
//       }
//     }
//   }
// }
// @include media_sp {
//   .c-date_time_block {
//     width: 100%;
//     margin-right: 0;
//     margin-bottom: 10px;
//   }
//   .c-time {
//     width: 100%;
//     display: flex;
//     &:before {
//       top: 7px;
//       right: auto;
//       left: 80px;
//     }
//     &:first-child {
//       margin-right: 0;
//       margin-bottom: 25px;
//     }
//   }
//   .c-select_time {
//     width: 72px;
//     &:first-child {
//       margin-right: 20px;
//     }
//   }
//   .c-times_block {
//     display: block;
//     width: 100%;
//     margin-bottom: 10px;
//     &.c-times_block__isRange .c-time {
//       width: 100%;
//       display: flex;
//       &:before {
//         top: 7px;
//         right: auto;
//         left: 80px;
//       }
//       &:first-child {
//         margin-right: 0;
//         margin-bottom: 25px;
//         &:after {
//           content: "↓";
//           top: auto;
//           bottom: -23px;
//           right: auto;
//           left: 75px;
//         }
//       }
//     }
//   }
// }
.c-input_withUnit,
.c-input_withUnit_short {
  margin-right: 5px;
}
.c-input_withUnit {
  width: calc(100% - 3em);
}
.c-input_withUnit_short {
  width: calc(50% - 3em);
}
// .c-input_file {
//   &::-webkit-file-upload-button {
//     display: none;
//   }
// }
// .c-input_textCounterBox {
//   position: relative;
// }
// .c-input_textCounter {
//   padding-right: 34px;
// }
// .c-text_textCounter {
//   font-size: $font-xs;
//   position: absolute;
//   right: 6px;
//   bottom: 10%;
// }
// @include media_sp {
//   .c-text_textCounter {
//     bottom: 10px;
//   }
// }

// .c-select_sort {
//   @extend %link-el;
//   @extend %btn-shadow;
//   display: inline-block;
//   padding: 5px 25px 5px 10px;
//   background: $bg_paleBr10;
//   border-radius: calc(#{$base-radius} / 2);
//   position: relative;
//   &:after {
//     content: "";
//     width: 5px;
//     height: 5px;
//     border-top: 1px solid $dark;
//     border-right: 1px solid $dark;
//     position: absolute;
//     top: 50%;
//     right: 10px;
//     margin-top: -5px;
//     transform: rotate(135deg);
//   }
// }
// .c-select_sortList {
//   position: absolute;
//   top: 100%;
//   left: 0;
//   @extend %default-box;
//   z-index: 100;
// }
// .c-select_sortList_item {
//   @extend %link-el;
//   padding: 10px 10px 5px 30px;
//   white-space: nowrap;
//   word-break: keep-all;
//   & a {
//     @extend %textdecoration-none;
//     color: $text;
//   }
//   &:last-child {
//     padding-bottom: 10px;
//   }
//   &.c-select_sortList_item__isCurrent {
//     @extend %list-is-active;
//     background-size: 12px auto;
//     background-position: 10px 50%;
//   }
//   &:first-child__isCurrent {
//     border-top-left-radius: $base-radius;
//     border-top-right-radius: $base-radius;
//   }
//   &:last-child__isCurrent {
//     border-bottom-left-radius: $base-radius;
//     border-bottom-right-radius: $base-radius;
//   }
// }

/*担当者名入力*/
// .c-person_input_component {
//   padding: 0;
//   cursor: text;
//   position: relative;
// }
// .c-persons_inputbox {
//   width: 30%;
//   border: none;
//   outline: none;
// }
// .c-person_input_decided:empty + span .c-persons_inputbox {
//   width: 100%;
// }
// .c-person_text {
//   display: inline-block;
//   background-color: $primary-variant;
//   border-radius: calc(#{$base-radius} / 2);
//   padding: 0px 8px;
//   margin-bottom: 4px;
//   margin-right: 8px;
// }
// .c-person_input_list {
//   @extend %default-box;
//   width: 100%;
//   position: absolute;
//   top: 100%;
//   left: 0;
//   z-index: 100;
//   max-height: 144px;
//   overflow-y: scroll;
//   padding: 10px;
// }
// .c-person_input_component {
//   &.c-person_input_component__isStatic {
//     & .c-person_input_list {
//       position: static;
//     }
//   }
// }
// .c-person_input_listItem {
//   cursor: pointer;
//   padding: 3px 5px;
//   font-size: $font-s;
//   &.c-person_input_listItem__selectedPersonInputItem,
//   &:hover {
//     background-color: $primary-variant;
//   }
// }
// .c-person_input_decided {
//   display: inline-block;
//   bottom: 0;
//   left: 0;
//   padding: 10px 10px 0;
// }
// .c-person_input_decided span {
//   white-space: nowrap;
// }
// @include media_sp {
//   .c-person_input_button {
//     padding-bottom: 5px;
//   }
// }

/*ページネーション*/
.c-pagination {
  width: 280px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: $bg_paleGr;
  border-radius: $base-radius;
  margin-bottom: 15px;
  height: 34px;

  input {
    padding: 2px 4px;
    background-color: #fff;
    border: 1px solid #a6a6a6;
    border-radius: 4px;
    margin-right: 6px;
    width: 5rem;
    text-align: right;
  }
}
$paginationItems: allprev, lastprev, prev, next;
@each $paginationItem in $paginationItems {
  .c-pagination_#{$paginationItem} {
    flex: 0 0 34px;
    height: 100%;
    & a {
      display: block;
      height: 100%;
    }
    &.is-disabled {
      & a {
        pointer-events: none;
      }
      & .c-pagination_#{$paginationItem}Link::before {
        filter: grayscale(100%);
        opacity: 0.3;
      }
    }
  }
  .c-pagination_#{$paginationItem}Link::before {
    content: '';
    display: inline-block;
    width: 34px;
    height: 34px;
    background-repeat: no-repeat;
    background-size: 34px 34px;
  }
}
.c-pagination_next {
  margin-left: auto;
}
.c-pagination_allprev {
  & a {
    background: rgba($dark, 5%);
    border-radius: 6px 0 0 6px;
  }
}
.c-pagination_lastprev {
  & a {
    background: rgba($dark, 5%);
    border-radius: 0 6px 6px 0;
  }
}
.c-pagination_allprevLink::before {
  background-image: url('/images/pager_allprev.png');
}
.c-pagination_prevLink::before {
  background-image: url('/images/pager_prev.png');
}
.c-pagination_nextLink::before {
  background-image: url('/images/pager_next.png');
}
.c-pagination_lastprevLink::before {
  transform: scale(-1, 1);
  background-image: url('/images/pager_allprev.png');
}
.c-pagination_number {
  color: $scales;
  font-size: $font-s;
  flex-grow: 1;
}
/*ページネーション（多件数リストの上に出す用）*/
.c-pagination_upper {
  margin-left: auto;
  & .c-pagination {
    width: 240px;
    margin-right: 0;
    margin-bottom: 0;
  }
}
@include media_sp {
  .c-spSearchBox {
    display: block;
  }
  .c-spSearchBtn_Box {
    text-align: center;
    margin-bottom: 10px;
  }
}

/* 詳細ページごとの前後ボタン */
// .c-btn_pagination_wrap {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// }
// .c-btn_pagination {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   background: $bg_paleGr;
//   border-radius: $base-radius;
//   height: 34px;
//   font-size: $font-s;
//   @extend %textdecoration-none;
//   &.c-btn_pagination__isNext {
//     padding-left: 22px;
//   }
//   &.c-btn_pagination__isPrev {
//     padding-right: 22px;
//   }
//   & img {
//     height: 34px;
//   }
//   &.is-disabled {
//     @extend %disabled;
//     & img {
//       filter: grayscale(100%);
//       opacity: 0.3;
//     }
//   }
// }
// @include media_sp {
//   .c-btn_pagination {
//     width: 34px;
//     &.c-btn_pagination__isNext {
//       padding-left: 0;
//     }
//     &.c-btn_pagination__isPrev {
//       padding-right: 0;
//     }
//   }
// }

/*btn size*/
// TODO: btn -> button
.c-btn {
  &_large,
  &_middle,
  &_middle_wide,
  &_toggleAll,
  &_toggleDetails,
  &_rectangle,
  &_small,
  &_drawer {
    @extend %textdecoration-none;
    @extend %link-el;
    @extend %btn-shadow;
    color: $white;
    border-color: transparent;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: $base-radius;
  }
}
.c-btn_large {
  padding: 6px 20px;
}
.c-btn_small {
  padding: 3px;
  border-radius: 3px;
}
@include media_sp {
  .c-btn_large {
    padding: 6px 15px;
  }
}
.c-btn_rectangle {
  padding: 8px;
}
.c-btn_middle {
  padding: 8px;
}
.c-btn_middle_wide {
  padding: 4px 16px;
}

.c-btn_toggleAll,
.c-btn_toggleDetails {
  text-align: center;
  position: relative;
  padding: 3px 16px;
  font-size: $font-s;
  &:after {
    content: '';
    width: 5px;
    height: 5px;
    border-top: 1px solid $scales;
    border-right: 1px solid $scales;
    transform: rotate(135deg);
    position: absolute;
    top: 38%;
    right: 7px;
    @extend %transition_default;
  }
  &.c-btn_toggleAll.is-open:after,
  &.c-btn_toggleDetails.is-open:after {
    top: 42%;
    transform: rotate(-45deg);
  }
}
.c-btn_toggleAll {
  background-color: $scales;
  &:after {
    border-top: 1px solid $white;
    border-right: 1px solid $white;
  }
}
.c-btn_toggleDetails {
  color: $scales;
  background: $white;
  border: 1px solid $scales;
}
@include media_sp {
  .c-btn_toggleAll,
  .c-btn_toggleDetails {
    padding: 5px 10px;
    &.c-btn_toggleAll.is-open:after,
    &.c-btn_toggleDetails.is-open:after {
      top: 42%;
      transform: rotate(-45deg);
    }
  }
  .c-btn_toggleAll {
    background-color: $scales;
    &:after {
      content: none;
    }
  }
  .c-btn_toggleDetails {
    padding: 12px;
    &:after {
      content: '';
      top: 38%;
      left: 50%;
      margin-left: -3px;
    }
  }
}
.c-accordion {
  position: relative;

  .c-accordion_button {
    position: absolute;
    right: 0;
    &.is-open {
      top: -34px;
    }
    &:not(.is-open) {
      top: -24px;
    }
  }
}

.c-accordion_content {
  &.is-open {
    opacity: 1;
    max-height: 800px;
    transition: 0.5s;
    margin-top: 10px;
  }
  &.is-open:not(.isDisableScroll) {
    overflow-y: scroll;
  }
  &:not(.is-open) {
    max-height: 0;
    overflow: hidden;
    padding: 0;
    opacity: 0;
    transition: 0.5s;
  }
}
.c-btn_delete,
.c-btn_delete_s {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: $danger;
}
// .c-btn_reset {
//   background-color: $white;
//   border: 1px solid $dark;
// }
.c-btn_edit,
.c-btn_Search {
  background-color: $dark;
}
.c-btn_create,
.c-btn_primary {
  background-color: $primary;
}
.c-btn_secondary {
  background-color: white;
  border: 1px solid $primary;
  color: $primary;
  &.is-disabled {
    border: none;
    color: white;
  }
}
.c-btn_drawer {
  background: $bg_paleGr;
  color: $text-link;
}
.c-btn_danger {
  background: $brightRed;
}
.c-btn_manage {
  background-color: $green;
  // &.c-btn_manage__isImport {
  //   padding-left: 40px;
  //   background-position: 22px 50%;
  //   background-repeat: no-repeat;
  //   background-size: 14px auto;
  //   background-image: url(/images/icon_import.png);
  // }
  // &.c-btn_manage__isExport {
  //   padding-left: 40px;
  //   background-position: 22px 50%;
  //   background-repeat: no-repeat;
  //   background-size: 14px auto;
  //   background-image: url(/images/icon_export.png);
  // }
}
[class^='c-btn'].is-disabled,
[class*=' c-btn'].is-disabled {
  @extend %btn-disabled;
}
.c-btn_cancel {
  background-color: $scales;
}
.c-btn_gray_outline {
  background-color: $white;
  border: 1px solid $scales;
  color: $scales;
}
.c-btn_black {
  background-color: #202020;
  color: $white;
  text-decoration: none;
}

$buttonTypes: primary, edit, Search, manage, cancel;
@each $buttonType in $buttonTypes {
  .c-btn_#{$buttonType} {
    &.c-btn_#{$buttonType}__isArrow,
    &.c-btn_#{$buttonType}__isDownArrow,
    &.c-btn_#{$buttonType}__isUpArrow,
    &.c-btn_#{$buttonType}__isCloseIcon {
      background-position: 93% 50%;
      background-repeat: no-repeat;
    }
    &.c-btn_#{$buttonType}__isArrow,
    &.c-btn_#{$buttonType}__isDownArrow,
    &.c-btn_#{$buttonType}__isUpArrow {
      background-size: 8px auto;
    }
    &.c-btn_#{$buttonType}__isArrow {
      background-image: url(/images/icon_btn_done.png);
    }
    &.c-btn_#{$buttonType}__isDownArrow {
      background-image: url(/images/icon_btn_downArrow.png);
    }
    &.c-btn_#{$buttonType}__isUpArrow {
      background-image: url(/images/icon_btn_upArrow.png);
    }
    &.c-btn_#{$buttonType}__isCloseIcon {
      background-image: url(/images/sp_btn_menuClose.png);
      background-size: 15px auto;
      padding-right: 30px;
    }
  }
}
.c-btn_Search {
  padding-left: 20px;
  padding-right: 20px;
}
// .c-btn_toggle_sort {
//   font-size: $font-s;
//   color: $text;
//   padding-top: 3px;
//   padding-bottom: 3px;
//   padding-right: 12px;
//   padding-left: 22px;
//   background: url(/images/icon_sort_default.png) no-repeat 8px 50% / 12px auto,$white;
//   border: 1px solid $scales;
//   &.c-btn_toggle_sort__isAsc,
//   &.c-btn_toggle_sort__isDesc {
//     background-color: $primary-variant;
//     border: 1px solid $primary;
//     font-weight: bold;
//   }
//   &.c-btn_toggle_sort__isAsc {
//     background-image: url(/images/icon_sort_asc.png);
//   }
//   &.c-btn_toggle_sort__isDesc {
//     background-image: url(/images/icon_sort_desc.png);
//   }
// }
// @include media_sp {
//   .c-btn_toggle_sort {
//     font-size: $font-xs;
//     padding-right: 10px;
//     padding-left: 20px;
//   }
// }
.c-btnText_middle,
.c-btnText_large {
  display: inline-block;
  @extend %textdecoration-none;
}
// .c-btnText_middle {
//   padding: 0 20px;
// }
.c-btnText_large {
  padding: 0 80px;
}
.c-input_submit {
  @extend %textdecoration-none;
  border: none;
  padding: 6px 40px;
}
@include media_sp {
  .c-input_submit {
    padding: 6px 24px;
  }
}

/* 団体種別ラベル */
// .c-label-grouptype {
//   background: $white;
//   display: inline-block;
//   padding: 2px 5px;
//   font-size: $font-s;
//   & dt,& dd {
//     display: inline-block;
//   }
//   & dt {
//     position: relative;
//     font-weight: bold;
//     &:after {
//       content: "：";
//     }
//   }
//   & .c-label-classification {
//     position: relative;
//     &:before {
//       content: "/";
//       display: inline-block;
//       margin: 0 5px;
//     }
//   }
// }

/* 作成日 */
// .c-timeStamp {
//   display: flex;
//   & dt, & dd {
//     display: inline-block;
//     font-size: $font-xs;
//   }
//   & dt {
//     font-weight: bold;
//     margin-right: 4px;
//   }
// }

/* 郵便記号表示 */
// .c-address-read {
//   display: flex;
//   font-size: $font-s;
//   & > *:first-child {
//     margin-right: 10px;
//     position: relative;
//     &:before {
//       content: "\03012";
//     }
//   }
// }
// @include media_sp {
//   .c-address-read {
//     display: block;
//   }
// }

/* 担当者名 */
// .c-parsons_small {
//   font-size: $font-xs;
//   display: flex;
// }
// .c-parsonsLabel_small {
//   font-weight: bold;
//   margin-right: 5px;
// }
// @include media_sp {
//   .c-parsons_small.sp_parsons {
//     justify-content: flex-end;
//   }
// }

/*フレーム*/
.c-frame {
  margin-bottom: 15px;
}

@include media_pc {
  .c-frame {
    @include default-box;
    padding: calc(#{$base-padding} * 1.5);
  }
}

@include media_sp {
  .c-frame {
    background: $white;
    padding: $base-padding;
  }
}

/*pc modal*/
@include media_pc {
  .c-pcModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0;
    height: 100vh;
    overflow-y: scroll;
    z-index: 100;
    background: $dark70;
  }
  .c-frame_modal {
    max-height: 90vh;
    width: 80%;
    min-width: 800px;
    margin-right: auto;
    margin-left: auto;
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
    padding: 30px 20px;
    overflow-y: scroll;
    &.c-frame_modal__overflowVisible {
      overflow-y: visible;
    }
  }
  .c-pcModal_headBtn {
    display: block;
    font-size: 2rem;
    position: absolute;
    top: 10px;
    right: 20px;
    @include link-el;
  }
}
/*sp modal*/
@include media_sp {
  /*モーダル隠す*/
  __isSpHidden {
    display: none;
  }
  /*モーダル本体*/
  .c-spModal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0;
    height: 100%;
    /* height: 100vh; */
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 101;
    background: $white;
  }
  /*モーダルの中でのframe*/
  .c-frame_modal,
  .c-frame_spModal {
    padding-top: 0;
    height: 100%;
    /* height: 100vh; */
    background-color: $white;
  }
  /*モーダルヘッド*/
  .c-spModal_head {
    background: $dark;
    padding: 5% 2%;
    margin: 0 -10px 10px;
  }
  .c-spModal_headName,
  .c-spModal_headBtn {
    color: $white;
  }
  .c-spModal_headBtn {
    display: block;
    text-align: center;
    width: 30px;
    @include link-el;
  }
  /*モーダル内テキスト用*/
  .c-modal_textBox {
    padding: 30px 0;
  }
}
/* smeiモーダル */
// .c-semiModal {
//   @extend %transition_default;
//   @extend %default-box;
//   position: fixed;
//   width: calc(100% - 20px);
//   margin-left: 10px;
//   margin-right: 10px;
//   padding: 20px;
//   bottom: -40%;
//   left: 0;
//   z-index: 1000;
// }
// .c-semiModal__isOpen {
//   bottom: 10px;
// }
// .c-semiModal__isOpen + .l-content {
//   padding-bottom: 220px;
// }
// .c-semiModal_contentBox {
//   max-height: 160px;
//   overflow: scroll;
//   -webkit-overflow-scrolling: touch;
// }
// @include media_sp {
//   .c-semiModal {
//     padding: 15px;
//   }
//   .c-semiModal__isOpen + .l-content {
//     padding-bottom: 20vh;
//   }
//   .c-semiModal_contentBox {
//     max-height: 20vh;
//   }
// }

/* listpage */
// .c-listPage_filter_caption,
// .c-listPage_listCaption {
//   display: none;
// }
// @include media_sp {
//   .c-listPage_filter_caption__isSp,
//   .c-listPage_listCaption__isSp {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//   }
//   .c-listPage_filter_caption__isSp {
//     padding-bottom: 10px;
//   }
//   .c-listPage_listCaption__isSp {
//     border-top: 1px solid $scales60;
//     background: $bg_paleGr;
//     padding: 10px;
//   }
//   .c-listPage_listCaption__isSp + .c-grid_wrap {
//     margin-top: 0;
//   }
// }

/*grid set*/
.c-grid_wrap {
  margin: 15px 0;
}
.c-grid--2,
.c-grid--3,
.c-grid--4,
.c-grid--5,
.c-grid--6,
.c-grid--7 {
  &:first-child {
    border-top: 1px solid $scales60;
  }
}
@include media_pc {
  .c-grid--2,
  .c-grid--3,
  .c-grid--4,
  .c-grid--5,
  .c-grid--6,
  .c-grid--7 {
    display: grid;
    grid-template-rows: repeat(auto-fill, auto);
  }
  .c-grid--2 {
    grid-template-columns: 1fr 3fr;
  }
  .c-grid--3 {
    grid-template-columns: repeat(3, 33.3%);
  }
  .c-grid--4 {
    grid-template-columns: repeat(4, 25%);
  }
  .c-grid--5 {
    grid-template-columns: repeat(5, 20%);
  }
  .c-grid--6 {
    grid-template-columns: repeat(6, 16.6%);
  }
  .c-grid--7 {
    grid-template-columns: repeat(7, 14.2%);
  }
  .c-grid_caption,
  .c-grid_value {
    font-size: $font-s;
    padding: calc(#{$base-padding} / 1.5);
    word-break: break-all;
  }
  .c-grid_caption {
    color: $primary;
    border-bottom: 1px solid $scales60;
  }
  .c-grid_value {
    border-bottom: 1px solid $scales60;
  }
  .c-grid_row {
    display: contents;
  }
  .c-grid_caption.sp_caption {
    display: none;
  }
  .pc_caption .c-grid_caption:nth-child(2n) {
    background: $bg_paleGr40;
  }
  .c-grid_value:nth-child(4n) {
    background: $bg_paleGr40;
  }
}
@include media_sp {
  /* modalでc-grid使用時 */
  .c-grid_wrap__isSpModal {
    margin: 10px 0;
    border-top: 1px solid $scales60;
  }

  .c-grid--2,
  .c-grid--3,
  .c-grid--4,
  .c-grid--5,
  .c-grid--6,
  .c-grid--7 {
    border-bottom: 1px solid $scales60;
  }
  .c-grid--2,
  .c-grid--3,
  .c-grid--4,
  .c-grid--5,
  .c-grid--6,
  .c-grid--7 {
    &:nth-child(2n) {
      background: $bg_paleGr40;
    }
  }
  .c-grid_row {
    padding-top: 8px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
  .c-grid_row__pcCaption {
    display: none;
  }
  .c-grid_caption {
    width: 30%;
  }
  .c-grid_value {
    width: 70%;
  }
  .c-grid_caption,
  .c-grid_value {
    padding: 0 10px 8px;
  }
  .c-grid_caption {
    font-weight: bold;
  }
}

// .c-errorBox {
//   width: 100%;
//   background: rgba($white, 65%);
//   text-align: center;
//   padding: 120px 0;
// }
// .c-errorImage {
//   width: 60%;
//   max-width: 690px;
//   margin: 0 auto;
//   & img {
//     width: 100%;
//   }
// }
// .c-errorCode,.c-errorText {
//   color: $primary;
// }
// .c-errorCode {
//   @extend %font-family-Lato;
//   font-size: 5.0rem;
// }

// @include media_sp {
//   .c-errorImage {
//     width: 80%;
//     margin: 0 auto;
//   }
// }

// @include media_sp {
//   .c-spfloating_btn {
//     font-size: $font-s;
//     display: inline-block;
//     background: $primary;
//     color: $white;
//     padding: 5px 10px;
//     border-radius: calc(#{$base-radius} / 1.5);
//     @include btn-shadow;
//     position: fixed;
//     bottom: 10px;
//     right: 10px;
//     z-index: 100;
//   }
// }
.c-nullMessage,
.c-nullMessage_text {
  color: $scales;
}
// .c-nullMessage {
//   background: $bg_paleGr;
//   border-radius: $base-radius;
//   color: $scales;
// }
// .c-stepState_list {
//   width: 100%;
//   display: flex;
//   justify-content: center;
//   margin-top: 10px;
// }
// .c-stepState_listItem {
//   text-align: center;
//   flex: 1;
//   padding-top: 30px;
//   position: relative;
//   z-index: 1;
//   color: $scales60;
//   &:before,&:after {
//     content: "";
//     background: #cfcfcf;
//     position: absolute;
//   }
//   &:before {
//     width: 100%;
//     height: 2px;
//     top: 10px;
//     left: 0;
//     z-index: -1;
//   }
//   &:after {
//     width: 24px;
//     height: 24px;
//     border-radius: 25px;
//     top: 0;
//     left: 50%;
//     transform: translate(-50%);
//     z-index: 0;
//   }
//   &:first-child:before,
//   &:last-child:before {
//     width: 50%;
//   }
//   &:first-child:before {
//     left: auto;
//     right: 0;
//   }
//   &.c-stepState_listItem__isDone {
//     color: $primary;
//     font-weight: bold;
//     &:before,&:after {
//       background: $primary;
//     }
//   }
// }
// @include media_sp {
// .c-stepState_listItem {
//   font-size: $font-s;
//   padding-top: 25px;
//     &:after {
//       width: 20px;
//       height: 20px;
//     }
//   }
// }
/* tooltip */
.c-tooltip {
  position: relative;
}
.c-tooltip {
  & + .c-tooltip_body {
    @extend %transition_default;
    opacity: 0;
    position: absolute;
    white-space: nowrap;
    border-radius: calc(#{$base-radius} / 2);
  }
  &:hover + .c-tooltip_body {
    opacity: 1;
  }
}

.c-help {
  &_tooltip {
    opacity: 0;
    height: 0;
    font-size: 0;
    @extend %transition_default;
    white-space: nowrap;
    position: absolute;
    top: -10px;
    left: 0;
    background: $dark70;
    padding: 3px 5px;
    border-radius: calc(#{$base-radius} / 2);
    color: $white;
  }
  &:hover .c-help_tooltip {
    top: -20px;
    opacity: 1;
    height: 18px;
    font-size: $font-xs;
  }
  /* FIXME: 雑だけど2行以上に対応できていないので取り急ぎ... */
  &:hover .c-help_tooltip.c-help_tooltip__large {
    height: 36px;
  }
}

/* 撮影中止ラベル */
// .c-label_cancel {
//   border-radius: $base-radius;
//   margin-bottom: 10px;
//   padding: 5px 5px 5px 10px;
//   display: flex;
// }
// @include media_sp {
//   .c-label_cancel {
//     display: block;
//   }
// }
// .c-label_text {
//   color: $white;
//   font-size: $font-m;
//   font-weight: bold;
//   margin-right: 15px;
// }
// .c-label_cancel {
//   background: $scales;
//   background: $scales60;
// }
// .c-cancelReason {
//   background: $white;
//   border-radius: calc(#{$base-radius} * .8);
//   padding: 3px 5px;
//   flex: auto;
//   font-size: 1.2rem;
// }

/*popup*/
.c-popup_details {
  @extend %default-box;
  position: absolute;
  padding: 10px;
  z-index: 100;
  text-align: left;
  color: $text;
}
.c-popup_details_title {
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 14px auto;
}
.c-popup_details_close {
  width: 10px;
  height: 10px;
  color: $text-sub;
  font-size: $font-xs;
  float: right;
  cursor: pointer;
}
.c-popup_notice {
  @extend %transition_default;
  @extend %default-box;
  position: fixed;
  z-index: 101;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 150%);
  width: 60%;
  text-align: center;
  font-weight: bold;
  padding: 10px;
  border: none;
  &.c-popup_notice__isComplete {
    background: $green;
    color: $white;
  }
  &.c-popup_notice__isUp {
    transform: translate(-50%, 0);
  }
}
.c-popup_closeBtn {
  @extend %link-el;
  @extend %textdecoration-none;
  font-size: $font-s;
  display: flex;
  color: $text;
  background: $scales30;
  padding: 5px;
  margin: 10px 0 0 0;
  border-radius: calc(#{$base-radius}/ 2);
  justify-content: center;
  align-items: center;
  & .c-icon_close {
    width: 12px;
    margin-right: 6px;
  }
}
.c-popup_submenuWrap {
  right: 0;
  top: 110%;
  white-space: nowrap;
}
.c-popup_submenuList {
  border-top: 1px solid $scales30;
  &_item {
    padding: 5px 20px 5px 0;
    background: url('/images/pager_next.png') no-repeat right center / 24px auto;
    border-bottom: 1px solid $scales30;
    & .c-textlink {
      display: block;
    }
    &__logout {
      background: none;
    }
    &.is-disabled {
      pointer-events: none;
      color: $text-sub;
      border: none;
    }
  }
}

.c-textlink {
  @extend %link-el;
  @extend %text-link-el;
  &.is-disabled {
    pointer-events: none;
    color: $text-sub;
    border: none;
  }
}

/* テーブルレイアウト ベース */
// .c-table {
//   & th,& td {
//     padding: 5px;
//     border: 1px solid;
//     border: 1px solid $scales60;
//   }
// }

/*loader animation large*/
// @keyframes ball-pulse-sync {
//   33% { transform: translateY(10px); }
//   66% { transform: translateY(-10px); }
//   100% { transform: translateY(0); }
// }
// .c-loading-ball-pulse-sync > div {
//   &:nth-child(1) {
//     animation: ball-pulse-sync 0.6s -0.14s infinite ease-in-out;
//   }
//   &:nth-child(2) {
//     animation: ball-pulse-sync 0.6s -0.07s infinite ease-in-out;
//   }
//   &:nth-child(3) {
//     animation: ball-pulse-sync 0.6s 0s infinite ease-in-out;
//   }
// }
// .c-loading-ball-pulse-sync > div {
//   background-color: $primary;
//   width: 10px;
//   height: 10px;
//   border-radius: 100%;
//   margin: 1px;
//   animation-fill-mode: both;
//   display: inline-block;
// }
// .c-loading-ball-pulse-sync.height-large {
//   margin-top: 60px;
//   margin-bottom: 60px;
// }
// .c-loading-ball-pulse-sync.height-small {
//   margin-top: 30px;
//   margin-bottom: 30px;
// }

/*loader animation small*/
// .c-loading-ball-pulse {
//   &.c-loading-ball-pulse__isGray > div {
//     background-color: $scales;
//   }
//   & > div {
//     background-color: $primary;
//     width: 3px;
//     height: 3px;
//     border-radius: 100%;
//     margin: 0;
//     animation-fill-mode: both;
//     display: inline-block;
//     &:nth-child(1) {
//       animation: scale 0.75s -0.24s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
//     }
//     &:nth-child(2) {
//       animation: scale 0.75s -0.12s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
//     }
//     &:nth-child(3) {
//       animation: scale 0.75s 0s infinite cubic-bezier(0.2, 0.68, 0.18, 1.08);
//     }
//   }
// }
// @keyframes scale {
//   0% {
//     transform: scale(1);
//     opacity: 1;
//   }
//   45% {
//     transform: scale(0.1);
//     opacity: 0.7;
//   }
//   80% {
//     transform: scale(1);
//     opacity: 1;
//   }
// }

/*
  theme
*/
.t-bgBox_org,
.t-bgBox_brown,
.t-bgBox_gray,
.t-bgBox_green,
.t-bgBox_white,
.t-bgBox_yellow,
.t-bgBox_red,
.t-bgBox_orange {
  padding: $base-padding;
  border-radius: $base-radius;
}
.t-bgBox_org {
  background: $primary-variant;
}
.t-bgBox_brown {
  margin-bottom: 10px;
  background: $bg_paleBr10;
}
.t-bgBox_white {
  background: $white;
}
// .t-bgBox_green {
//   background: rgba($green, 10%);
// }
// .t-bgBox_yellow {
//   background: rgba($yellow, 15%);
// }
.t-bgBox_gray {
  background: $scales15;
}
.t-bgBox_orange {
  background: $primary-variant;
}
// .t-bgBox_red {
//   background: rgba($brightRed, 15%);
// }
// .t-bgBox_list {
//   & li {
//     list-style-type: disc;
//     margin-left: 1.6em;
//     font-size: 1.2rem;
//   }
// }
.c-submenu_rectangle {
  margin-left: 10px;
  padding: 8px;
}
.c-submenu_main {
  flex: 1 1 auto;
}
.c-submenu_sub {
  flex: 0 0 185px;
  border-left: 1px solid rgba(166, 166, 166, 0.6);
  margin-left: 15px;
}
.c-submenu_linkList {
  border-top: 1px solid $scales30;
  margin-left: 15px;
  & .c-submenu_linkList_item {
    padding: 5px 20px 5px 0;
    background: url(/images/pager_next.png) no-repeat right center / 24px auto;
    border-bottom: 1px solid $scales30;
    & .c-textlink {
      display: block;
    }
  }
}
// .t-textColor_white {
//   color: $white;
// }
.t-textColor_base {
  color: $text;
}
// .t-textColor_sub {
//   color: $text-sub;
// }
// .t-textColor_primary {
//   color: $primary;
// }
// .t-textColor_green {
//   color: $green;
// }
// .t-textColor_beige {
//   color: $beige;
// }
// .t-textColor_brown {
//   color: $brown;
// }
// .t-textColor_blue {
//   color: $blue;
// }
// .t-textColor_red {
//   color: $brightRed;
// }
// .t-textColor_yellow {
//   color: $yellow;
// }
.t-textColor_danger {
  color: $danger;
}
.t-checkbox_col_white {
  &:before {
    background: $white;
    border: 1px solid #e4e4e4;
  }
}
.c-checkbox_inline:checked + .t-checkbox_col_white {
  &:before {
    border: 1px solid $primary;
  }
}

.c-clipboardCopy {
  position: relative;
  color: $text-link;
  margin-left: 5px;
  cursor: pointer;
  text-decoration: none;
  & .c-clipboardCopy__isChecked {
    position: absolute;
    left: 0;
    & i {
      color: $green;
    }
  }
}
.c-clipboardCopy_tooltip {
  opacity: 0;
  height: 0;
  font-size: 0;
  @extend %transition_default;
  white-space: nowrap;
  top: -10px;
  left: 0;
  background: $dark70;
  padding: 3px 5px;
  border-radius: calc(#{$base-radius} / 2);
  color: $white;
  transition: all 0.2s linear;
}
.c-icon_success:hover + .c-clipboardCopy_tooltip {
  top: -20px;
  opacity: 1;
  height: 18px;
  font-size: $font-xs;
}

.c-icon_search:before {
  content: '\e91b';
}
.c-icon_close:before {
  content: '\e930';
}

.t-textColor_white {
  color: $white;
}
.t-textColor_base {
  color: $text;
}
.t-textColor_sub {
  color: $text-sub;
}
.t-textColor_primary {
  color: $primary;
}
.t-textColor_green {
  color: $green;
}
.t-textColor_beige {
  color: $beige;
}
.t-textColor_brown {
  color: $brown;
}
.t-textColor_blue {
  color: $blue;
}
.t-textColor_red {
  color: $brightRed;
}
.t-textColor_yellow {
  color: $yellow;
}

.u-fz {
  &_xl {
    font-size: 3rem;
  }
  &_l {
    font-size: $font-l;
  }
  &_m {
    font-size: $font-m;
  }
  &_default {
    font-size: $font-default;
  }
  &_s {
    font-size: $font-s;
  }
  &_xs {
    font-size: $font-xs;
  }
}

/*
  utility
*/
/*ユーザー入力した文章を改行をそのまま出すよう*/
.u-pre_wrap {
  white-space: pre-wrap;
  word-break: break-all;
}
.u-line_dot,
.u-line_plane,
.u-line_dot_white {
  border: none;
  margin: 10px auto;
}
.u-line_dot {
  border-bottom: 2px dotted $scales60;
}
.u-line_dot_white {
  border-bottom: 2px dotted $white;
}
.u-line_plane {
  border-bottom: 1px solid $scales60;
}

/* interval */
.u-mgt {
  &_l {
    margin-top: $interval-l;
  }
  &_m {
    margin-top: $interval-m;
  }
  &_s {
    margin-top: $interval-s;
  }
  &_xs {
    margin-top: $interval-xs;
  }
  &_0 {
    margin-top: 0;
  }
}
.u-mgr {
  &_l {
    margin-right: $interval-l;
  }
  &_m {
    margin-right: $interval-m;
  }
  &_s {
    margin-right: $interval-s;
  }
  &_xs {
    margin-right: $interval-xs;
  }

  &_0 {
    margin-right: 0 !important;
  }
}
.u-mgb {
  &_l {
    margin-bottom: $interval-l !important;
  }
  &_m {
    margin-bottom: $interval-m;
  }
  &_s {
    margin-bottom: $interval-s;
  }
  &_xs {
    margin-bottom: $interval-xs;
  }
  &_0 {
    margin-bottom: 0 !important;
  }
}
.u-mgl {
  &_l {
    margin-left: $interval-l;
  }
  &_m {
    margin-left: $interval-m;
  }
  &_s {
    margin-left: $interval-s;
  }
  &_xs {
    margin-left: $interval-xs;
  }
}

.u-pdt {
  &_l {
    padding-top: $interval-l;
  }
  &_m {
    padding-top: $interval-m;
  }
  &_s {
    padding-top: $interval-s;
  }
  &_xs {
    padding-top: $interval-xs;
  }
}
.u-pdr {
  &_l {
    padding-right: $interval-l;
  }
  &_m {
    padding-right: $interval-m;
  }
  &_s {
    padding-right: $interval-s;
  }
  &_xs {
    padding-right: $interval-xs;
  }
}
.u-pdb {
  &_l {
    padding-bottom: $interval-l;
  }
  &_m {
    padding-bottom: $interval-m;
  }
  &_s {
    padding-bottom: $interval-s;
  }
  &_xs {
    padding-bottom: $interval-xs;
  }
}
.u-pdl {
  &_l {
    padding-left: $interval-l;
  }
  &_m {
    padding-left: $interval-m;
  }
  &_s {
    padding-left: $interval-s;
  }
  &_xs {
    padding-left: $interval-xs;
  }
}

.is-hidden {
  display: none;
}

// .u-box_center {
//   margin-right: auto;
//   margin-left: auto;
// }
.u-align_center {
  text-align: center;
}
.u-align_right {
  text-align: right;
}
.u-align_left {
  text-align: left;
}
.u-w {
  &_100 {
    width: 100%;
  }
}
// .u-font_lato {
//   @extend %font-family-Lato;
// }
// .u-textdecoration_under {
//   text-decoration: underline;
// }
// @include media_pc {
//   .u-w_48_pc {
//     width: 48%;
//   }
// }
@include media_sp {
  .u-w_100_sp {
    width: 100%;
  }
  .u-w_60_sp {
    width: 60%;
  }
}

.c-errorBox {
  width: 100%;
  background: rgba($white, 65%);
  text-align: center;
  padding: 120px 0;
}
.c-errorImage {
  width: 60%;
  max-width: 690px;
  margin: 0 auto;
  & img {
    width: 100%;
  }
}
.c-errorCode,
.c-errorText {
  color: $primary;
}
.c-errorCode {
  @extend %font-family-Lato;
  font-size: 5rem;
}

@include media_sp {
  .c-errorImage {
    width: 80%;
    margin: 0 auto;
  }
}

.c-tabList {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid $scales60;
}
.c-tabListItem {
  cursor: pointer;
  display: block;
  padding: 0px 13px 10px 13px;
  border-top: 1px solid #666;
  border-left: 1px solid #666;
  border-right: 1px solid #666;
  padding: 4px 6px;
  margin-right: 6px;
  border-radius: 6px 6px 0 0;
  background-color: #eee;
  color: #666;
  &:hover {
    color: $scales60;
    border-bottom: 3px solid $scales60;
  }
  &.is-active {
    background-color: #fff;
    color: inherit;
    border-bottom: 3px solid $primary;
    font-weight: 700;
  }
}

.u-borderTop_none {
  border-top: none !important;
}

.u-radiusLt_base {
  border-radius: $base-radius 0 0 0;
}

.u-radiusRt_base {
  border-radius: 0 $base-radius 0 0;
}

.c-no_data_text {
  color: #d9d9d9;
}
