@import './import/import.scss';
/*===================
layout
====================*/
.l-col_wrap {
  width: 100%;
  & .l-col_auto {
    flex: auto;
  }
  & .l-col_24 {
    width: 100%;
  }
  & .l-col_20 {
    width: calc(100% / 6 * 5);
  }
  & .l-col_18 {
    width: calc(100% / 4 * 3);
  }
  & .l-col_16 {
    width: calc(100% / 3 * 2);
  }
  & .l-col_12 {
    width: calc(100% / 2);
  }
  & .l-col_8 {
    width: calc(100% / 3);
  }
  & .l-col_7 {
    width: calc(100% / 24 * 7);
  }
  & .l-col_6 {
    width: calc(100% / 4);
  }
  & .l-col_5 {
    width: calc(100% / 24 * 5);
  }
  & .l-col_4 {
    width: calc(100% / 6);
  }
}
@include media-sp {
  .l-col_wrap__isSpMode {
    & .l-col_12 {
      width: 100%;
    }
    & .l-col_8 {
      width: 100%;
    }
    & .l-col_7 {
      width: calc(100% / 12 * 7);
    }
    & .l-col_6 {
      width: 50%;
    }
    & .l-col_5 {
      width: calc(100% / 12 * 5);
    }
    & .l-col_4 {
      width: calc(100% / 3);
    }
  }
}

/* has gutter */
.l-has_gutter {
  & .l-col_auto {
    margin: 5px;
  }
  & .l-col {
    &_18,
    &_16,
    &_12,
    &_8,
    &_6,
    &_4 {
      margin: 5px;
    }
  }
  .l-col_12 {
    width: calc(100% / 2 - 10px);
  }
  .l-col_8 {
    width: calc(100% / 3 - 10px);
  }
  .l-col_6 {
    width: calc(100% / 4 - 10px);
  }
  .l-col_4 {
    width: calc(100% / 6 - 10px);
  }
}
@include media-sp {
  /* has gutter */
  .l-has_gutter__isSpMode {
    .l-col_12 {
      width: calc(100% - 10px);
    }
    .l-col_8 {
      width: calc(100% - 10px);
    }
    .l-col_6 {
      width: calc(50% - 10px);
    }
    .l-col_4 {
      width: calc(100% / 3 - 10px);
    }
  }
}

.l-flex,
.l-flex_center,
.l-flex_center_line,
.l-flex_end,
.l-flex_start,
.l-flex_between,
.l-flex_between_center,
.l-flex_align_end,
.l-flex_align_start,
.l-flex_align_base {
  display: flex;
  flex-wrap: wrap;
  &.nowrap {
    flex-wrap: nowrap;
  }
  &.l-flex__direction_column {
    flex-direction: column;
  }
  & .l-right_auto {
    margin-right: auto;
  }
  & .l-left_auto {
    margin-left: auto;
  }
  & .l-noshrink {
    flex-shrink: 0;
  }
}
.l-flex_between,
.l-flex_between_center {
  justify-content: space-between;
}
.l-flex_end {
  justify-content: flex-end;
}
.l-flex_start {
  justify-content: flex-start;
}
.l-flex_center_line,
.l-flex_center,
.l-flex_between_center {
  align-items: center;
}
.l-flex_center_line {
  justify-content: center;
}
.l-flex_align_end {
  align-items: flex-end;
}
.l-flex_auto {
  flex: auto;
}
.l-flex_align_start {
  align-items: flex-start;
}
.l-flex_align_base {
  align-items: baseline;
}
.l-relative {
  position: relative;
}
.l-absolute {
  position: absolute;
}
.l-box_center {
  margin-right: auto;
  margin-left: auto;
}
.l-clearfix {
  &:after {
    content: '';
    display: table;
    clear: both;
  }
}
.l-float_l {
  float: left;
}
.l-float_r {
  float: right;
}

/*===================
[PC]
layout
====================*/
@include media-pc {
  main {
    display: flex;
    min-height: calc(100% - 48px);
  }
  .l-content_wrap {
    min-width: 980px;
    width: 100%;
    padding: 30px 20px;
  }
  .l-content {
    display: flex;
    min-width: $main-minwidth-pc;
    max-width: 100%;
  }
  .l-center_wrap {
    min-width: calc(100% - 390px);
    width: 100%;
  }
  .l-right_column {
    flex: 0 0 370px;
    margin-left: 20px;
  }
  .l-right_sticky_pc {
    position: sticky;
    top: 0;
    max-height: 100vh;
    overflow: auto;
    & .c-frame:last-child {
      margin-bottom: 0;
    }
  }
  .l-right_sticky_pc::-webkit-scrollbar {
    width: 8px;
  }
  .l-right_sticky_pc::-webkit-scrollbar-track {
    border-radius: 8px;
    background: #f2f2f2;
  }
  .l-right_sticky_pc::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background: #a6a6a6;
  }
}

/*===================
[SP]
layout
====================*/
@include media-sp {
  main {
  }
  .l-content_wrap {
    width: 100%;
    padding: 20px 2%;
  }
  .l-content {
    margin-left: -2%;
    margin-right: -2%;
    &.l-content__isSpReverse {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  .l-center_wrap {
  }
  /*PCレイアウトの打ち消し*/
  .l-flex_between,
  .l-flex,
  .l-flex_center,
  .l-flex_between_center,
  .l-flex_end {
    &.l-flex_between__isSpInput,
    .l-flex__isSpInput,
    .l-flex_center__isSpInput,
    .l-flex_between_center__isSpInput,
    .l-flex_end__isSpInput {
      display: block;
      & .c-dataLabel {
        margin-right: 0;
        margin-bottom: 4px;
        flex: auto;
        width: 100%;
      }
    }
  }
}
