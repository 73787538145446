.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 10px;

  .breadcrumbLink {
    text-decoration: none;
  }

  .breadcrumbArrow {
    margin-left: 5px;
    margin-right: 5px;
  }
}
