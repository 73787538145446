.separator {
  margin-left: 8px;
  margin-right: 8px;
  width: auto;
}

.example {
  margin-left: 150px;
  padding: 4px 10px;
}
