@import '../import';

.p-certificationkeysShow {
  &_keyBundles,
  &_mailForm {
    width: calc(50% - #{$base-padding-narrow});

    & .c-frame {
      min-height: 410px;
    }
    & .c-emptyState_box {
      height: 100%;
    }
    &__fullWidth {
      width: 100%;
    }
  }
  &_keyBundles {
    tr .p-certificationkeysShow_keyBundles_certificationkeys:not(:first-child) {
      margin-top: $interval-xs;
    }
  }
  &_csvDownload_btn {
    padding: 6px 8px;
  }
}

@include media-sp {
  .p-certificationkeysShow {
    &_keyBundles,
    &_mailForm {
      width: 100%;
      & .c-frame {
        min-height: auto;
      }
    }
  }
}
