@import '@/css/import/import.scss';

.p-eventShow_societyIdLabel {
  font-weight: normal;
  font-size: 1.2rem;
}

.p-eventShow_dataTitle {
  font-weight: bold;
  margin-right: 10px;
}
.p-eventShow_thumbnailImage {
  display: inline-block;
}
.p-eventShow_categoriesThumbnail_img {
  background-color: #fffcdd;
  text-align: center;
}
.p-eventShow_categoriesThumbnail {
  max-width: 66px;
  min-width: 66px;
}
.p-eventShow_categoryList {
  border-top: 1px solid $scales60;
  border-bottom: 1px solid $scales60;
  margin-bottom: 25px;
  width: 100%;
  thead th {
    color: $primary;
    font-weight: bold;
    text-align: left;

    &.u-align_center {
      text-align: center;
    }
  }
  th,
  td {
    border-top: 1px solid $scales60;
    padding: $base-padding-narrow $base-padding;
    min-width: 2rem;
  }
}
.p-eventShow_categoryList_parentRow {
  background-color: $bg_paleGr40;
}
.p-eventShow_categoryList_parentHiddenRow {
  background-color: $scales30;
}
.p-eventShow_categoryList_hiddenRow {
  background-color: $scales30;
}
.p-eventShow_cancelBox {
  padding: $base-padding;
  border-radius: $base-radius;
  background-color: $scales60;
}
.p-eventShow_cancelBoxLabel {
  font-size: $font-m;
  font-weight: bold;
}
.p-eventShow_photographersTable {
  text-align: left;
  & th:not(:first-child) {
    padding-right: 20px;
  }
}
.p-eventShow_nippoAccordionContent.c-accordion_content.is-open {
  max-height: 10000px;
}
.p-eventShow_autoTypeSetting_frameSelect {
  flex-grow: 1;
  margin-left: $interval-s;
}
@include media-sp {
  .priceTableOverwrite table {
    th,
    td {
      padding: 2px 0;
    }
  }
}
