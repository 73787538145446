@import '../../../../css/import/variables.scss';

.p-photographerIndex_bgBox {
  background: rgba(255, 255, 255, 0.6);
  min-height: 32px;
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 1.2rem;
  & .c-label_checkbox_inline {
    line-height: 1rem;
  }
}

.p-photographerIndex_searchForm {
  & .c-input_multiSelect__menu {
    z-index: 101;
  }
}

.p-photographerIndex_accordion_content:not(.is-open) {
  max-height: 0;
  overflow: hidden;
  padding: 0;
  opacity: 0;
  transition: 0.5s;
}

.p-photographerIndex_accordion_content.is-open {
  opacity: 1;
  max-height: 800px;
  transition: 0.5s;
  margin-top: 5px;
}

.p-photographerIndex_scrollbar {
  width: 100%;
  overflow-x: auto;
  margin: 15px 0;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(#a6a6a6, 60%);
    border-radius: 10px;
  }
}

.p-photographerIndex_label {
  font-size: 1rem;
  padding: 2px 8px;
  background: $scales;
  border-radius: calc(#{$base-radius} / 2);
  margin-right: 0;
}

.p-photographerIndex_grid_value {
  & .c-icon-arrowRight {
    color: #4a90e2;
    margin-right: 2px;
  }
  & .u-w_85 {
    width: 85%;
  }
}

.p-photographerIndex_iconBtn {
  width: 18px;
  height: 18px;
  padding: 3px;
  border-radius: calc(6px / 2);
}

.p-photographerIndex_grid_wordWrap {
  word-wrap: break-word;
}

.p-photographerIndex_grid_row {
  display: contents;

  &.p-photographerIndex_grid_row__close {
    & .p-photographerIndex_grid_value,
    & .c-idLink_hasBg {
      background: $scales;
      color: $white;
    }
  }
}

.p-photographerIndex_grid_other_department_container,
.p-photographerIndex_grid_container {
  display: grid;

  background: #fafafa;
  border-bottom: 1px solid rgba(#a6a6a6, 60%);
  &.finished {
    background: #e8e8e8;
    & .p-photographerIndex_grid_value {
      background: #e8e8e8;
      &:nth-child(2n) {
        background: #e8e8e8;
      }
    }
  }
}

.p-photographerIndex_grid_other_department_container {
  min-width: 2000px;
  grid-template-columns:
    300px
    150px // 短縮名
    300px // 所属組織
    70px // 権限
    150px // 適格請求書発行事業者登録番号
    150px // 適格請求書発行事業者登録屋号
    70px // 都道府県
    150px // 市区町村
    150px // 番地・建物
    200px // メールアドレス
    120px // 電話番号
    repeat(3, 70px); // 性別、車所有、宿泊
}

.p-photographerIndex_grid_container_wrap,
.p-photographerIndex_grid_other_department_container_wrap {
  min-width: 2000px;
  background: #fafafa;
  max-height: 90vh;
  &.grid_header {
    position: sticky;
    z-index: 30;
    top: 0px;
    border-top: 1px solid rgba(166, 166, 166, 0.6);
  }
}

.p-photographerIndex_grid_caption,
.p-photographerIndex_grid_value {
  font-size: 1rem;
  padding: 5px 5px;
  background: #fafafa;
  text-align: center;
  &:nth-child(2n) {
    background: #ffffff;
  }
  &_1st {
    position: sticky;
    z-index: 20;
    left: 0px;
    text-align: left;
  }
  &_2nd {
    position: sticky;
    z-index: 20;
    left: 300px;
    text-align: left;
  }
  &_3rd {
    position: sticky;
    z-index: 20;
    left: 450px;
    text-align: left;
  }
  &_4rd {
    position: sticky;
    z-index: 20;
    left: 750px;
    text-align: left;
  }
  &.error {
    background: rgba(#f25252, 10%);
    & .c-icon-error {
      margin: 2px 2px 0 0;
    }
  }
  & .c-textlink {
    @extend %textdecoration-none;
  }
  & .c-idLink_hasBg {
    display: flex;
  }
}

.p-photographerIndex_grid_caption {
  color: #ff8e42;
  font-weight: bold;
  text-align: left;
  &_1st,
  &_2nd,
  &_3rd,
  &_4rd {
    top: 0px;
  }
  &_1st {
    left: 0px;
  }
  &_2nd {
    left: 300px;
  }
  &_3rd {
    left: 450px;
  }
  &_4rd {
    left: 750px;
    box-shadow: 8px 0px 8px -1px rgba(0, 0, 0, 0.06);
  }
}

.p-photographerIndex_grid_caption,
.p-photographerIndex_grid_value {
  min-height: 50px;
  text-align: left;
  word-wrap: break-word;
  display: flex;
  align-items: center;
  &_1st {
    left: 0px;
    text-align: left;
  }
  &_2nd {
    left: 300px;
    text-align: left;
  }
  &_3rd {
    left: 450px;
    text-align: left;
  }
  &_4rd {
    left: 750px;
    text-align: left;
    box-shadow: 8px 0px 8px -1px rgba(0, 0, 0, 0.06);
  }
}

.p-photographerIndex_grid_value_1st {
  padding-top: 5px;
  padding-bottom: 5px;
}

.p-photographerIndex_hasOption {
  width: calc(48% - 50px);
}

.p-photographerIndex_switchBox {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  padding: 5px 1px;
  border-radius: 6px;
  color: $text-sub;
}

.p-photographerIndex_scrollbar {
  font-family: 'Osaka', 'ＭＳ Ｐゴシック', Arial, sans-serif;
}

.p-photographerIndex_pdt_xs {
  padding-top: 2px;
}

.p-photographerIndex_status {
  padding: 5px;
  margin-right: 10px;
  border: 1px solid;
  &.not_working {
    background-color: grey;
    border-color: grey;
    color: #ffffff;
  }
  &.working {
    background-color: #f25252;
    border-color: #f25252;
    color: #ffffff;
    padding-right: 15px;
  }
  &.reserve {
    background-color: #ffffff;
    border-color: grey;
    color: grey;
    padding-right: 15px;
  }
}

/*===================
[SP]
layout
====================*/

@include media-sp {
  .p-photographerIndex_hasOption {
    width: 100%;
  }
  .p-photographerIndex_bgBox {
    width: 100%;
    margin: 5px 0px 0px;
  }
}
