@import '@/css/import/import';

.numberInput {
  display: inline-block;
  margin-right: 10px;
  width: 100px;
}

.cellLabel {
  font-size: $font-s;
  font-weight: bold;
}
