@import '../../../../css/import/import';

.p-CorporationList_info {
  font-size: $font-s;
  & li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 10px;
  }
}

/* 一覧画面 */
.p-incentive_bulkOperation {
  margin-left: 20px;
  margin-right: 20px;
  text-align: center;
}
.p-incentive_bulkOperation_box {
  display: flex;
  justify-content: center;
}
.p-incentive_bulkOperation_date {
  width: 190px;
}

.p-incentiveList_upper,
.p-incentiveList_date {
  float: left;
  width: 100%;
}
div + .p-incentiveList_upper,
div + .p-incentiveList_upper + .p-incentiveList_date {
  width: calc(100% - 48px);
}
.p-incentiveList_id {
  & .c-icon-partner {
    vertical-align: middle;
  }
}
.p-incentiveList_upper_info {
  width: calc(100% - 200px);
}
.p-incentiveList_upper_patner {
  display: block;
  width: calc(100% - 200px);
  @extend %text-ellipsis;
}
.p-incentiveList_id {
  min-width: 54px;
}
.p-incentiveList_inputBox {
  margin-left: auto;
  & form {
    display: inline-block;
  }
}
.p-incentive_PaymentDate {
  display: inline-block;
  border: 1px solid var(--scales60);
  border-radius: var(--base-radius);
  margin-right: 5px;
  &.p-incentive_unpaid {
    background: var(--bg_paleGr);
    pointer-events: none;
  }
  & span {
    height: 30px;
    padding: 5px 7px;
    display: inline-block;
    vertical-align: top;
    border-right: 1px solid var(--scales);
  }
  /* over ride */
  & .c-input_plane {
    min-height: auto;
    height: 30px;
    width: 128px;
    border: none;
    padding-left: 5px;
    background-position: 97% 50%;
  }
  & .c-input_date {
    background-size: auto 20px;
  }
}

.p-incentive_unpaid {
  & .c-icon_yen {
    filter: opacity(50%);
  }
  & .c-input_date {
    position: relative;
  }
  & .c-input_date:after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: var(--bg_paleGr);
    background-repeat: no-repeat;
    background-position: 97% 50%;
    background-size: auto 20px;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: var(--base-radius);
    z-index: 1;
  }
  & .c-input_date:before {
    content: '未払い';
    color: var(--scales);
    position: absolute;
    top: 16%;
    left: 5px;
    z-index: 3;
  }
}
.p-incentive_unpaid.is-ready {
  background-color: var(--white);
  & .c-icon_yen {
    filter: none;
  }
}
.p-incentive_unpaid.is-ready .c-input_date {
  pointer-events: auto;
}
.p-incentive_unpaid.is-ready .c-input_date:after {
  width: 70%;
  background-color: var(--white);
  background-image: none;
}
.p-incentive_itemName {
  opacity: 0;
  height: 0;
  font-size: 0;
  @extend %transition_default;
  white-space: nowrap;
  position: absolute;
  top: 0;
  left: 0;
  background: var(--dark70);
  padding: 3px 5px;
  border-radius: calc(var(--base-radius) / 2);
  color: var(--white);
}
.p-incentive_itemDate:hover + .p-incentive_itemName {
  top: -22px;
  opacity: 1;
  height: 20px;
  font-size: var(--font-xs);
}
/* override
incentivesは.c-fixedBox_bottomが２つあるため個別に追記*/
.c-fixedBox_bottom.is-open + .c-fixedBox_bottom + .l-center_wrap {
  padding-bottom: 90px;
}
/* チェックボックス出たときにつけるクラス */
.p-incentive_checkScroll {
  height: calc(100vh - 500px);
  overflow-y: auto;
}

/* ================
パートナー請求_owner・partner共通
 ================ */
/* ラベル */
.p-incentiveLabel {
  &_approved,
  &_incomplete,
  &_unapproved,
  &_transferRequest,
  &_transferred,
  &_bulkDelivery {
    padding-left: 8px;
    padding-right: 8px;
    font-size: var(--font-xs);
    min-width: 69px;
  }
}
/* 不備ありラベル */
.p-incentiveLabel_incomplete {
  background: var(--brightRed);
}
/* 承認済み */
.p-incentiveLabel_approved {
  background: var(--yellow);
}
/* 振込依頼 */
.p-incentiveLabel_transferRequest {
  background: var(--green);
}
/* 振込済み */
.p-incentiveLabel_transferred {
  background: color(var(--green) a(25%));
  color: var(--green);
}
/* 未承認 */
.p-incentiveLabel_unapproved {
  color: var(--text);
  border: 1px solid var(--scales);
}
/* 一括配送 */
.p-incentiveLabel_bulkDelivery {
  color: var(--text);
  background: #ffdcc4;
}
/* 明細種類別の見出し */
.p-incentive_typeTitle {
  font-size: var(--font-s);
  background: var(--bg_paleGr);
  border-top: 1px solid var(--scales30);
  border-bottom: 1px solid var(--scales30);
  padding: calc(var(--base-padding) * 0.4) var(--base-padding);
  margin-bottom: 20px;
  color: var(--scales);
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 100;
}

/* 請求明細内容テーブル */
.p-incentive_table {
  table-layout: fixed;
  width: 100%;
  &_id,
  &_eventName,
  &_shootingDay,
  &_incentive,
  &_sales,
  &_target,
  & td {
    padding: 10px 1.1% 10px 0;
  }
  &_id {
    width: 10%;
  }
  &_eventName {
    width: 40%;
  }
  &_shootingDay {
    width: 16%;
  }
  &_incentive {
    width: 13%;
  }
  &_sales {
    width: 17%;
  }
  &_target {
    padding-right: 0;
    width: 38px;
  }
  & tr {
    border-bottom: 1px solid var(--scales60);
    vertical-align: top;
    &.is-notTarget {
      background: var(--scales15);
      color: var(--scales);
      & .c-input_plane {
        @extend %disabled;
      }
    }
  }
  /* override */
  & .c-input_date {
    background-size: 20px auto;
  }
  & td:last-child {
    padding-right: 0;
    text-align: center;
  }
}
.p-incentive_tableCaption {
  background: var(--bg_paleBr10);
  border-radius: var(--base-radius);
  font-size: calc(var(--font-default) * 0.79);
  padding: 7px;
}
.p-incentive_table,
.p-incentive_tableOther {
  & td.p-incentive_unitTotalBox {
    padding: 10px 0 25px;
  }
}
.p-incentive_unitTotal {
  background: var(--scales15);
  padding-right: 7%;
  padding-top: 7px;
  padding-bottom: 7px;
  min-height: 35px;
}
.p-incentive_addEventBtn,
.p-incentive_addDetailBtn {
  position: absolute;
  left: 0;
  bottom: 30px;
}
/* tooltip */
.p-incentive_tooltip_help {
  background: var(--scales60);
  border-radius: calc(var(--base-radius) / 2);
  padding: 3px 10px;
  font-size: var(--font-xs);
  transform: translate(-100%, -100%);
  white-space: nowrap;
}

/* その他請求内容テーブル */
.p-incentive_tableOther {
  table-layout: fixed;
  width: 100%;
  &_detail,
  &_unitPrice,
  &_set,
  &_payment,
  &_del,
  & td {
    padding: 10px 1.1% 10px 0;
  }
  &_detail {
    width: 50%;
  }
  &_unitPrice {
    width: 16%;
  }
  &_set {
    width: 13%;
  }
  &_payment {
    width: 17%;
  }
  &_del {
    padding-right: 0;
    width: 38px;
  }
  & tr {
    border-bottom: 1px solid var(--scales60);
    vertical-align: top;
  }
}
/* 請求合計エリア */
.p-incentive_remarksBox {
  width: 50%;
}
.p-incentive_totalBox {
  width: 47%;
}
.p-incentive_totalCaption {
  width: 40%;
  text-align: right;
}
.p-incentive_totalItem {
  width: 56%;
  text-align: right;
  padding: 5px 10px 5px 5px;
  &_box {
    background: var(--white);
    border-radius: var(--base-radius);
  }
}
.p-incentive_totalBox.is-html {
  & .p-incentive_totalItem {
    padding-right: calc(38px + 1.1%);
  }
}

/* 卸値の内訳表 */
.p-wholesale_formula {
  display: inline-flex;
  font-size: var(--font-xs);
  background: var(--white);
  border-radius: var(--base-radius);
  padding: 3px 8px 2px;
}
.p-wholesale_tableBreakDown {
  table-layout: fixed;
  width: 100%;
  margin-top: 10px;
  background: var(--white);
  font-size: var(--font-xs);
  & th,
  & td {
    border-bottom: 1px solid var(--scales30);
    &:not(:last-child) {
      border-right: 1px solid var(--scales30);
    }
    &:nth-child(even) {
      background: var(--bg_paleGr40);
    }
  }
  &_size,
  &_totalSales,
  &_cost,
  &_sellingPrice,
  &_fee,
  &_numberSold,
  &_payment,
  &_values {
    padding: 6px 1.1% 6px 0;
  }
  & tr > *:last-child {
    padding-right: calc(0.7% + 38px);
  }
  &_totalSales,
  &_cost,
  &_sellingPrice,
  &_fee,
  &_numberSold,
  &_payment {
    text-align: center;
  }
  &_size {
    padding-left: 1%;
    text-align: left;
    font-weight: bold;
    width: 8%;
  }
  &_totalSales {
    width: 22%;
  }
  &_cost,
  &_sellingPrice {
    width: 10.5%;
  }
  &_fee {
    width: 16%;
  }
  &_numberSold {
    width: 13%;
  }
  &_payment {
  }
  &_values {
    text-align: right;
  }
}
