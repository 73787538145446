@import '../../css/import/import.scss';
/*=================
  global-menu
==================*/
.l-global_wrap {
  color: $dark90;
  display: flex;
  flex-grow: 1;
  align-items: center;
}
.p-global_titleWrap {
  position: relative;
}
.p-global_title {
  padding: 15px 44px 15px 10px;
  font-size: 2rem;
  position: relative;
  white-space: nowrap;
}
.p-global_menuList {
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
}
.p-global_menuList_title {
  display: block;
  padding: 20px 13px;
  position: relative;
}
.p-global_menuList_title__isActive {
  background-color: $dark;
  color: $white;
}
.p-global_icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  background-size: 18px auto;
  margin-right: 8px;
  vertical-align: text-bottom;
}
.p-global_icon__salesman {
  background-image: url(/images/icon_global_salesman_dark.png);
}
.p-global_menuList_title__isActive .p-global_icon__salesman {
  background-image: url(/images/icon_side_salesman.png);
}
.p-global_icon__photographer {
  background-image: url(/images/icon_global_photographer_dark.png);
}
.p-global_menuList_title__isActive .p-global_icon__photographer {
  background-image: url(/images/icon_side_photographer.png);
}
.p-global_icon__events {
  background-image: url(/images/icon_global_events_dark.png);
}
.p-global_menuList_title__isActive .p-global_icon__events {
  background-image: url(/images/icon_side_events.png);
}
.p-global_icon__users {
  background-image: url(/images/icon_global_users_dark.png);
}
.p-global_menuList_title__isActive .p-global_icon__users {
  background-image: url(/images/icon_side_users.png);
}
.p-global_icon__others {
  background-image: url(/images/icon_global_others_dark.png);
}
.p-global_menuList_title__isActive .p-global_icon__others {
  background-image: url(/images/icon_side_others.png);
}
.p-global_icon__groups {
  background-image: url(/images/icon_global_groups_dark.png);
}
.p-global_menuList_title__isActive .p-global_icon__groups {
  background-image: url(/images/icon_side_groups.png);
}
.p-global_child_menuList {
  background-color: $white;
  position: absolute;
  top: 100%;
  left: 0;
  width: auto;
  white-space: nowrap;
  z-index: 99;
  overflow-y: hidden;
}
.p-global_child_linkTxt {
  background-color: $dark90;
  color: $white;
  font-size: $font-s;
  display: block;
  padding: 20px 25px;
}
.p-global_child_linkTxt,
.p-global_child_menuListItem {
  @extend %link-el;
  @extend %textdecoration-none;
  &:hover {
    background: $dark90;
  }
}
.p-global_selectToolList {
  width: 200px;
  left: 0;
  top: 90%;
  transition: 0.2s all linear;
  position: absolute;
  @extend %default-box;
  z-index: 100;
}
.p-global_selectToolListItem {
  display: block;
  color: $text;
  padding: $base-padding;
  @extend %textdecoration-none;
  &.p-global_selectToolListItem__isActive {
    @extend %list-is-active;
    background-size: 16px auto;
    background-position: 95% 50%;
  }
}
li:first-child .p-global_selectToolListItem__isActive {
  border-radius: 6px 6px 0 0;
}
li:last-child .p-global_selectToolListItem__isActive {
  border-radius: 0 0 6px 6px;
}
/* search box */
.p-global_searchBox {
  padding: 10px 10px;
  position: relative;
}
.p-global_searchBox__isActive {
  // background-color: $dark90;
}
.p-global_search_input {
  @extend %form-common;
  @extend %font-family-base;
  width: 100%;
  padding: 5px 5px 5px 30px;
  resize: none;
  display: block;
}
.p-global_search_icon {
  position: absolute;
  top: 8px;
  left: 8px;
}
.p-global_search_submit {
  -webkit-appearance: none;
  border: none;
  height: 100%;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
}
.p-global_search_submitList {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - 20px);
  position: absolute;
  top: 50px;
  left: 10px;
  & li {
    flex-grow: 1;
  }
  & li:first-child {
    border-radius: 6px 0 0 6px;
    & input {
      border-radius: 6px 0 0 6px;
    }
  }
  & li:last-child {
    border-radius: 0 6px 6px 0;
    & input {
      border-radius: 0 6px 6px 0;
    }
  }
  & li:not(:last-child) {
    border-right: 1px solid $dark;
  }
}
.p-global_search_ListItem {
  height: 40px;
  background: #908c87;
  transition: all 0.2s linear;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.8);
  &:hover {
    background-color: $primary;
  }
}
.p-global_search_ListItem {
  &:hover:after {
    font-size: $font-xs;
    position: absolute;
    top: 130%;
    left: 0;
    display: inline-block;
    padding: 3px 5px;
    background: $white;
    border-radius: calc(#{$base-radius} / 2);
    color: $text;
    z-index: 11;
    white-space: nowrap;
    box-shadow: 0px 2px 1px 0px $shadow20;
  }
  &:hover:before {
    content: '▲';
    position: absolute;
    top: 104%;
    left: 30%;
    z-index: 10;
    color: $white;
    text-shadow: 0px -1px 2px $shadow20;
  }
  & :focus {
    background-color: $primary;
  }
  & input {
    border-radius: 0;
  }
}
.p-global_search_ListItem__society:hover:after {
  content: '団体（直販・先生）検索';
}

.p-global_search_ListItem__group:hover:after {
  content: 'パートナー団体検索';
}
.p-global_search_ListItem__event:hover:after {
  content: 'イベント検索';
}
.p-global_search_ListItem__partner:hover:after {
  content: 'パートナー検索';
}
.p-global_search_ListItem__member:hover:after {
  content: '会員検索';
}
.p-global_search_ListItem__order:hover:after {
  content: '注文検索';
}
.p-global_search_ListItem__photo:hover:after {
  content: '写真検索';
}
.p-global_search_ListItem__cameraman:hover:after {
  content: 'カメラマン検索';
  left: auto;
  right: 0;
}

.p-global_search_group {
  background-image: url('/images/icon_search_group.png');
  background-size: auto 18px;
}
.p-global_search_event {
  background-image: url('/images/icon_search_event.png');
  background-size: auto 19px;
}
.p-global_search_partner {
  background-image: url('/images/icon_search_partner.png');
  background-size: auto 17px;
}
.p-global_search_member {
  background-image: url('/images/icon_search_member.png');
  background-size: auto 20px;
}
.p-global_search_order {
  background-image: url('/images/icon_search_order.png');
  background-size: auto 20px;
}
.p-global_search_photo {
  background-image: url('/images/icon_search_photo.png');
  background-size: auto 16px;
}
.p-global_search_cameraman {
  background-image: url('/images/icon_search_cameraman.png');
  background-size: auto 21px;
  background-position: 50% 60%;
}

/* slideDown animation */
.p-global_slideDown-enter {
  max-height: 0;
}
.p-global_slideDown-enter-active {
  max-height: 300px;
  transition: max-height 0.3s ease-out;
}
.p-global_slideDown-exit {
  max-height: 0px;
}
.p-global_slideDown-exit-active {
  max-height: 0;
}

/* fadeIn animation */
.p-global_fadeIn-enter {
  opacity: 0;
}
.p-global_fadeIn-enter-active {
  opacity: 1;
  transition: opacity 0.3s ease-out;
}
.p-global_fadeIn-exit {
  opacity: 0;
}
.p-global_fadeIn-exit-active {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}
