.p-photographers_photographerTable {
  width: 100%;

  img {
    width: 150px;
  }

  tr.l-col_wrap {
    border: none;
    td {
      padding: 0;
    }

    .c-dataValue ul {
      height: auto;
      width: 100%;
    }
  }
  ul {
    height: 100%;

    li {
      padding-left: 10px;
      padding-top: 8px;
      padding-bottom: 7px;
    }

    li:nth-child(1) {
      flex: 0 0 160px;
      height: 100%;
    }

    li + li.c-dataLabel {
      background: #fff;
    }

    li li:nth-child(1) {
      flex: 0 0 70px;
    }
  }

  .l-col_wrap.l-flex {
    margin: 0;
  }

  .c-help_tooltip {
    z-index: 2;
  }
}
