@import '../../../css/import/import';

.title {
  color: $text;
  font-size: 1.8rem;
  font-weight: bold;
  margin-bottom: 10px;
  text-decoration: none;
}

.container {
  padding: $base-padding;

  @include media_pc {
    @include default-box;
  }

  @include media_sp {
    background: $white;
  }
}

.marginSmall {
  margin-top: 4px;
}

.marginMedium {
  margin-top: 8px;
}

.marginLarge {
  margin-top: 16px;
}
