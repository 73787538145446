@import '../../../css/import/import.scss';

.p-overlaySpinner {
  z-index: 10;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(#fff, 70%);
}
.p-overlaySpinner_spinnerBox {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  margin: auto;
  font-size: 100px;
  animation: 2s linear 1s infinite loading_color;
}
