@import '../import';

.p-partnerIndex {
  .c-searchForm {
    &_partnerInfo {
      width: calc(100% / 24 * 17);
      &_id {
        width: 20%;
        padding-right: $base-padding-narrow;
      }
      &_name {
        width: 40%;
        padding-right: $base-padding-narrow;
      }
      &_kana {
        width: 40%;
      }
    }
    &_tel {
      width: calc(100% / 24 * 7);
    }
  }
}

@include media-sp {
  .p-partnerIndex {
    .c-searchForm {
      &_partnerInfo {
        width: 100%;
        &_id {
          width: 30%;
          padding-bottom: $base-padding-narrow;
        }
        &_name {
          width: 70%;
          padding-right: 0;
          padding-bottom: $base-padding-narrow;
        }
        &_kana {
          width: 100%;
        }
      }
      &_tel {
        width: 100%;
      }
    }
  }
}
