@import '../../../../css/import/import.scss';
.p-salesIndex_table {
  font-size: 1rem;
  margin-bottom: 0px !important;
  & thead tr th {
    text-align: center !important;
  }
  & tr th {
    padding: 2px 5px !important;
  }
  & tbody tr:first-child th {
    color: rgba($primary, 70%);
  }
}
.p-salesIndex_tableWrapper {
  width: 100%;
  overflow-y: scroll;
  box-shadow: 8px 0px 8px -1px rgba(0, 0, 0, 0.1) inset;
}

.p-salesIndex_idField {
  width: 30%;
  padding-right: 8px;
}
.p-salesIndex_nameField {
  width: 70%;
}
.p-salesIndex_societyIdField {
  width: 30%;
  padding-right: 8px;
}
.p-salesIndex_societyContractIdField {
  width: 20%;
  padding-right: 8px;
}
.p-salesIndex_societyNameField {
  width: 50%;
}
.p-salesIndex_amountField {
  flex-grow: 1;
  padding-right: 8px;
}
.p-salesIndex_description {
  & h4 {
    margin-top: $interval-s;
  }
}
