@import '../../../css/import/import.scss';

@include media_sp {
  .p-index_wrapInSp {
    flex-wrap: wrap;
  }
}
.p-index_List {
  & li {
    margin-bottom: 5px;
  }
}
.p-index_innerHalf {
  width: 49%;
}
.p-index_scrollbar {
  width: 100%;
  overflow-x: auto;
  margin: 15px 0;
  max-height: 200px;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: $white;
  }
  &::-webkit-scrollbar-thumb {
    background: $scales60;
    border-radius: 10px;
  }
}
.p-index_Listline {
  border-top: 2px dotted rgba(166, 166, 166, 0.6);
}
.p-index_ListItem {
  border-bottom: 2px dotted rgba(166, 166, 166, 0.6);
  padding: 10px 0;
}
.p-index_links {
  & li {
    margin-right: 15px;
  }
}
@include media-sp {
  .p-index_innerHalf {
    width: 100%;
  }
  .p-index_linkstitle {
    margin-top: 5px;
  }
  .p-index_links {
    & li {
      margin-right: 15px;
      margin-top: 4px;
    }
  }
}
