.p-photographersForm_pageLinkArea {
  margin-left: 10px;
  margin-bottom: 6px;

  a {
    text-decoration: none;
    margin-left: 20px;
  }
  a:first-child {
    margin-left: 0;
  }
  .page_link_icon {
    transform: rotate(-90deg);
    width: 10px;
    font-size: 16px;
    line-height: 2px;
  }
}

.p-photographersForm_pageLinkIcon {
  transform: rotate(-90deg);
  width: 10px;
  font-size: 16px;
  line-height: 2px;
}
