@import '../import';

/* 検索フォーム */
.p-eventsIndex_bgBox {
  background: rgba(255, 255, 255, 0.6);
  min-height: 32px;
  padding: 6px 10px;
  border-radius: 6px;
  // border-radius: var(--base-radius);
  font-size: 1.2rem;
  // font-size: var(--font-s);
  & .c-label_checkbox_inline {
    line-height: 1rem;
  }
}

.p-eventsIndex_searchForm {
  & .c-input_multiSelect__menu {
    z-index: 101;
  }
  & .c-accordion_content.is-open {
    overflow-y: visible;
    padding-top: 1px;
  }
}

/* 検索結果表示フォーム */

.p-eventsIndex_scrollbar {
  width: 100%;
  overflow-x: auto;
  margin: 15px 0;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(#a6a6a6, 60%);
    border-radius: 10px;
  }
}

.c-icon-groups:before {
  content: '\e906';
}

.c-icon-arrowRight:before {
  content: '\e936';
}

.c-icon-error:before {
  content: '\e900';
}

.c-icon_XXsmall {
  width: 11px;
  height: 11px;
}

.p-eventsIndex_label {
  font-size: 1rem;
  padding: 2px 8px;
  background: $scales;
  border-radius: calc(#{$base-radius} / 2);
  margin-right: 0;
}

.p-eventsIndex_grid_value {
  & .c-icon-arrowRight {
    color: #4a90e2;
    margin-right: 2px;
  }
  & .u-w_85 {
    width: 85%;
  }
}

.p-eventsIndex_iconBtn {
  width: 18px;
  height: 18px;
  padding: 3px;
  border-radius: calc(6px / 2);
}

.p-eventsIndex_grid_wordWrap {
  word-wrap: break-word;
}

.p-eventsIndex_grid_wordBreak {
  word-break: break-word;
}

.p-eventsIndex_grid_row {
  display: contents;

  &.p-eventsIndex_grid_row__close {
    & .p-eventsIndex_grid_value,
    & .c-idLink_hasBg {
      background: $scales;
      color: $white;
    }
  }
}

.p-eventsIndex_grid_other_department_container,
.p-eventsIndex_grid_container {
  display: grid;

  background: #fafafa;
  border-bottom: 1px solid rgba(#a6a6a6, 60%);
  &.finished {
    background: #e8e8e8;
    & .p-eventsIndex_grid_value {
      background: #e8e8e8;
      &:nth-child(2n) {
        background: #e8e8e8;
      }
    }
  }
}

// .p-eventsIndex_grid_container {
//   min-width: 1830px;
//   grid-template-columns:
//     repeat(2, 80px)
//     42px
//     200px
//     50px
//     60px
//     50px
//     repeat(2, 30px)
//     80px
//     repeat(6, 124px)
//     136px
//     repeat(2, 124px)
//     repeat(3, 70px);

//   // repeat(2, 80px)
//   // 42px
//   // 200px
//   // 50px
//   // 60px
//   // 50px
//   // repeat(2, 30px)
//   // 80px
//   // repeat(6, 124px)
//   // 136px
//   // repeat(2, 124px);
// }

.p-eventsIndex_grid_other_department_container {
  min-width: 2015px;
  grid-template-columns: 80px // パートナー
    140px // 団体
    70px // 撮影日
    200px // イベント
    50px // 種類
    60px // 申請オプション
    50px // 公開設定
    30px // 事前確認依頼メール
    80px // フォルダ
    80px // 認証キー
    80px // 価格No
    repeat(4, 70px) // 掲載開始日、バリュー終了日、掲載終了日、責任者終了日
    repeat(5, 125px) // チラシ、SB、FAX用紙、DVD、確認用SB
    70px // 一括配送入金締め日
    60px // プラン
    70px // 担当営業
    70px // 詳細対応者
;
}

.p-eventsIndex_grid_container_wrap,
.p-eventsIndex_grid_other_department_container_wrap {
  // 見づらいので一日以下
  min-width: 2015px;
  background: #fafafa;
  max-height: 90vh;
  &.grid_header {
    position: sticky;
    z-index: 30;
    top: 0px;
    border-top: 1px solid rgba(166, 166, 166, 0.6);
  }
}

.p-eventsIndex_grid_caption,
.p-eventsIndex_grid_value {
  font-size: 1rem;
  padding: 5px 5px;
  background: #fafafa;
  text-align: center;
  &:nth-child(2n) {
    background: #ffffff;
  }
  &_1st {
    position: sticky;
    z-index: 20;
    left: 0px;
    text-align: left;
  }
  &_2nd {
    position: sticky;
    z-index: 20;
    left: 80px;
    text-align: left;
  }
  &_3rd {
    position: sticky;
    z-index: 20;
    left: 220px;
    text-align: left;
  }
  &_4th {
    position: sticky;
    z-index: 20;
    left: 290px;
    text-align: left;
    box-shadow: 8px 0px 8px -1px rgba(0, 0, 0, 0.06);
  }
  &.error {
    background: rgba(#f25252, 10%);
    & .c-icon-error {
      margin: 2px 2px 0 0;
    }
  }
  & .c-textlink {
    @extend %textdecoration-none;
  }
  & .c-idLink_hasBg {
    display: flex;
  }
}

.p-eventsIndex_grid_caption {
  color: #ff8e42;
  font-weight: bold;
  text-align: left;
  &_1st,
  &_2nd,
  &_3rd,
  &_4th {
    top: 0px;
  }
  &_1st {
    left: 0px;
  }
  &_2nd {
    left: 80px;
  }
  &_3rd {
    left: 220px;
  }
  &_4th {
    left: 290px;
    box-shadow: 8px 0px 8px -1px rgba(0, 0, 0, 0.06);
  }
}

.p-eventsIndex_grid_caption,
.p-eventsIndex_grid_value {
  text-align: center;
  &_1st {
    left: 0px;
    text-align: left;
  }
  &_2nd {
    left: 80px;
    text-align: left;
  }
  &_3rd {
    left: 220px;
    text-align: left;
  }
  &_4th {
    left: 290px;
    text-align: left;
    box-shadow: 8px 0px 8px -1px rgba(0, 0, 0, 0.06);
  }
}

.p-eventsIndex_grid_value {
  & .c-icon-arrowRight {
    color: #4a90e2;
    margin-right: 2px;
  }
  & .u-w_85 {
    width: 85%;
  }
}

.p-eventsIndex_hasOption {
  width: calc(48% - 50px);
}

.p-eventsIndex_switchBox {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  padding: 5px 1px;
  border-radius: 6px;
  color: $text-sub;
}

.p-eventsIndex_scrollbar {
  font-family: 'Osaka', 'ＭＳ Ｐゴシック', Arial, sans-serif;
}

.p-eventsIndex_pdt_xs {
  padding-top: 2px;
}

/*===================
[SP]
layout
====================*/

@include media-sp {
  .p-eventsIndex_hasOption {
    width: 100%;
  }
  .p-eventsIndex_bgBox {
    width: 100%;
    margin: 5px 0px 0px;
  }
}
