@import '../../../../css/import/import.scss';
.p-photographInsert_dropArea {
  margin: 10px;
  border: $scales 4px dashed;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $scales;
  &.is-dragging {
    border: $primary 4px dashed;
    color: $primary;
  }
}
.p-photographInsert_dropAreaText {
  text-align: center;
  font-size: 2rem;
  &.is_waitingUpload {
    font-size: 4rem;
  }
  &_notice {
    font-size: 2rem;
  }
}
.p-photographInsert_photographerLabel {
  flex: 0 0 120px;
  line-height: 1.2;
  font-weight: 700;
  margin-right: 10px;
}
.p-photographInsert_photographerSelect {
  flex: 1 0;
}
