@import '../../../css/import/import.scss';

.p-salesForecastsIndex-headBlock {
  display: inline-flex;
  padding: $base-padding;
  align-items: center;
  border-radius: $base-radius;

  & .p-salesForecastsIndex-headBlock_year,
  & .p-salesForecastsIndex-headBlock_name {
    font-weight: bold;
    font-size: $font-l;
  }

  & .p-salesForecastsIndex-headBlock_idArea {
    height: 100%;
    background-color: $bg_paleGr;
    padding: 4px 14px;
    border-radius: $base-radius;
    font-size: $font-m;
  }
}
