@import '@/css/import/import';

.arrow {
  color: $primary;
  cursor: pointer;
  display: inline-block;
  margin: 5px;
}

.resultTableButtonsCell {
  align-items: center;
  display: flex;
}

.innerTableButtons {
  margin: 5px;
  text-align: center;
}

.closeDetailLinkWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
}

.closeDetailLink {
  color: $scales60;
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-decoration: underline;
}

.detailForm table td:nth-last-child(1) {
  width: 50%;
}

textarea {
  min-height: 20em;
}

.selectBoxMinWidth {
  min-width: 110px;
}
