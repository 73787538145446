@import '../../../../css/import/import';

html {
  scroll-behavior: smooth;
}

.p-groupsSummary_dataLabel_s {
  flex: 0 0 140px;
}

.p-groupsSummary_dataLabel_m {
  flex: 0 0 180px;
}

.p-groupsSummary_dataLabel_l {
  flex: 0 0 220px;
}

.p-groupsSummary_dataLabel_xl {
  flex: 0 0 240px;
}

.p-groupsSummary_dataLabel_xxl {
  flex: 0 0 360px;
}

.t-bgBox_brown {
  & .p-groupsSummary_dataLabel {
    flex: 0 0 160px;
  }
}
.p-groupsSummary_tooltip_help {
  top: -20px;
  left: 0;
  font-size: $font-xs;
  color: $white;
  background: $dark70;
  padding: 2px 5px;
  font-weight: 100;
}

ul.s-administrator-infoList li {
  display: inline-flex;
  margin-right: 5px;
  width: 200px;
}

ul {
  &.s-owneraccount_ul {
    li {
      &.s-owneraccounts-li {
        width: 100% !important;
        padding-top: 20px !important;
        &:nth-of-type(n + 2) {
          border-top: 2px dotted rgba(166, 166, 166, 0.6);
        }
      }

      div {
        &.s-owneraccount-detailBox {
          margin-top: 10px;
        }
      }
    }
  }
}

.s-owneraccount-bold {
  font-weight: bold;
}

.p-contractTabListItem {
  cursor: pointer;
  display: block;
  width: 200px;
  border-top: 1px solid #999;
  border-left: 1px solid #999;
  border-right: 1px solid #999;
  padding: 4px 6px;
  margin-right: 6px;
  border-radius: 6px 6px 0 0;
  background-color: #eee;
  color: #999;
  text-align: center;
}

.p-contractTabListItem.is-active {
  background-color: #fff;
  color: #111;
}

@include media_sp {
  .p-groupsSummary_Label {
    & div {
      margin-top: 5px;
    }
  }
  .p-groupsSummary_outline {
    & div {
      margin-top: 5px;
    }
  }
  .p-groupsSummary_upper {
    border-top: 1px solid rgba(166, 166, 166, 0.15);
    padding-top: 5px;
    margin-top: 5px;
    width: 100%;
  }

  .p-societies_contractTab {
    flex-wrap: nowrap !important;
  }

  .p-contractTabListItem {
    width: 100px;
    height: 44px;
    line-height: 44px;
    padding: 0;
    margin: 0;
  }
}
