@import '../import';

.p-partnersSummary_listItem {
  &_latestItemname {
    width: 50%;
    & .c-idLink_hasBg {
      min-width: 70px;
    }
  }
  &_latestItemInfo {
    width: 50%;
  }
  &_latestItemInfoDetail {
    width: 60%;
    & .c-idLink_hasBg {
      min-width: 70px;
    }
  }
  &_detail {
    width: calc(100% - 80px);
    flex-wrap: nowrap;
  }
}

.p-partnersSummary_label {
  background-color: $scales60;
  margin-right: 0;
  min-width: 80px;
}
.p-partnersSummary_registrationDate {
  min-width: 70px;
}

.p-partnerSummary_name {
  width: calc(100% - 120px);
}
.p-partnerSummary_label {
  min-width: 128px;
  padding: 5px 10px;
  background: $primary;
}

.p-partnerSummary {
  .c-indexList {
    margin-bottom: 5px;
  }
  .c-ownerList {
    .c-accordion_button {
      right: 50px;
      &.is-open {
        top: -38px;
      }
      &:not(.is-open) {
        top: -38px;
      }
    }
  }
}

.p-partnerSummary_adminUser_name {
  flex: 0 0 180px;
}
.p-partnerSummary_adminUser_line {
  width: calc(100% - 32px);
  word-break: break-all;
}
.p-partnerSummary_adminUser_tel {
  flex: 0 0 150px;
  margin-right: 10px;
}
.p-partnerSummary_adminUser_mail {
  width: calc(100% - 400px);
  word-break: break-all;
}
.p-partnerSummary_adminUser_authorityList {
  font-size: $font-xs;
  & .c-label__monotone,
  & .c-statusLabel__positive,
  & .c-statusLabel__negative {
    font-size: $font-xs;
    padding: 0 7px;
  }
  & .c-statusLabel__positive,
  & .c-statusLabel__negative {
    margin-right: 7px;
  }
  & .c-label__monotone {
    color: rgba($text, 50%);
  }
}
.p-partnerSummary_adminUser_authoritySwitch {
  margin-right: 7px;
  color: $white;
  font-size: $font-xs;
  & .is-authority_on,
  & .is-authority_off {
    display: inline-block;
    padding: 1px 6px;
  }
  & .is-authority_on {
    background: $primary;
  }
  & .is-authority_off {
    background: $scales30;
  }
  & .is-authority_on,
  & .is-authority_off {
    border-radius: calc(#{$base-radius} / 2) 0 0 calc(#{$base-radius} / 2);
  }
  & .is-authority_off + .is-authority_on {
    border-radius: 0 calc(#{$base-radius} / 2) calc(#{$base-radius} / 2) 0;
  }
  & .is-authority_on + .is-authority_off {
    border-radius: 0 calc(#{$base-radius} / 2) calc(#{$base-radius} / 2) 0;
  }
}
.p-is-authority_camera {
  font-size: $font-xs;
  padding: 0 6px;
  background: $primary;
}
.p-is-authority_icon_camera {
  vertical-align: middle;
  & + span {
    vertical-align: middle;
  }
}

@include media-pc {
  .p-partnerSummary_adminUser_password {
    &.c-value_read {
      display: flex;
      justify-content: space-between;
    }
  }
}

@include media-sp {
  .p-partnersSummary_listItem_latestItemname,
  .p-partnersSummary_listItem_latestItemInfo,
  .p-partnersSummary_namesItem {
    width: 100%;
  }
  .p-partnersSummary_namesItem {
    margin-bottom: 5px;
  }

  .p-partnerSummary_adminUser_line {
    margin-bottom: 5px;
    display: flex;
    flex-direction: column-reverse;
  }
  .p-partnerSummary_adminUser_line {
    width: 100%;
    margin-bottom: 0;
  }
  .p-partnerSummary_adminUser_name,
  .p-partnerSummary_adminUser_tel,
  .p-partnerSummary_adminUser_mail {
    width: calc(100% - 50px);
  }
  .p-partnerSummary_adminUser_explainLink {
    text-align: right;
  }
  .p-partnerSummary_adminUser_authorityList {
    & .c-statusLabel__positive,
    & .c-statusLabel__negative {
      margin-top: 3px;
    }
  }
  .p-partnerSummary_adminUser_authorityEdit {
    position: absolute;
    top: 0;
    right: 0;
  }
}
