$white: #fff;

$text: #333333;
$text-sub: #a6a6a6;
$text-link: #4a90e2;

$primary: #ff8e42;
$primary-variant: #fff0e6;

$dark90: rgba(#202020, 90%);
$dark70: rgba(#202020, 70%);
$dark: #202020;

$bg_paleGr: #f2f2f2;
$bg_paleGr60: rgba(#f2f2f2, 60%);
$bg_paleGr50: rgba(#f2f2f2, 50%);
$bg_paleGr40: rgba(#f2f2f2, 40%);
$bg_paleBr10: rgba(#917a66, 10%);

$scales: #a6a6a6;
$scales60: rgba(#a6a6a6, 60%);
$scales30: rgba(#a6a6a6, 30%);
$scales15: rgba(#a6a6a6, 15%);

$shadow10: rgba(#000000, 10%);
$shadow20: rgba(#000000, 20%);

$frame-border: #e6e6e6;

$font-xs: 1.1rem;
$font-s: 1.2rem;
$font-default: 1.4rem;
$font-m: 1.6rem;
$font-l: 1.8rem;

$base-padding: 10px;
$base-radius: 6px;

$base-padding-narrow: 8px;

$interval-l: 50px;
$interval-m: 20px;
$interval-s: 10px;
$interval-xs: 5px;

$side-width-pc: 250px;
$main-minwidth-pc: 768px;
$content-width-pc: 1018px;

/* その他 */
$danger: #dd3434;
$brightRed: #f25252;
$green: #5fad09;
$blue: #4a90e2;
$yellow: #f4cb00;
$beige: #f3eeeb;
$brown: #917a66;

@mixin link-el {
  cursor: pointer;
  transition: 0.2s opacity linear;
  &:hover {
    opacity: 0.7;
  }
}
%link-el {
  @include link-el;
}
%font-family-base {
  font-family: 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3',
    'Hiragino Sans', 'ヒラギノ角ゴ W3', 'メイリオ', Meiryo, 'ＭＳ ゴシック',
    sans-serif;
}
%font-family-Lato {
  font-family: 'latoregular';
}
%text-link-el {
  color: $text-link;
}
%textdecoration-none {
  text-decoration: none;
}
@mixin default-box {
  background: $white;
  border-radius: $base-radius;
  box-shadow: 0 1px 3px $shadow10;
  border: 1px solid $frame-border;
}
%default-box {
  @include default-box;
}
@mixin btn-shadow {
  box-shadow: 0 2px 2px $shadow20;
}
%btn-shadow {
  @include btn-shadow;
}
%form-outline {
  border: 1px solid $scales60;
  border-radius: $base-radius;
  background-color: $white;
  padding: 5px 10px;
}
@mixin form-common {
  min-height: 32px;
  padding: 6px $base-padding;
  background-color: $white;
  border: 1px solid $scales;
  border-radius: $base-radius;
}
%form-common {
  @include form-common;
}
%btn-disabled {
  box-shadow: none;
  background-color: rgba($dark, 10%);
  pointer-events: none;
}
%list-is-active {
  background-color: $bg_paleGr;
  background-image: url(/images/select_current.png);
  background-repeat: no-repeat;
  font-weight: bold;
}
%lines-hover {
  background: rgba($primary-variant, 40%);
}
%text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
%disabled {
  pointer-events: none;
  color: $text-sub;
  background-color: $scales15;
}
%transition_default {
  transition: all 0.2s linear;
}
%label {
  @extend %textdecoration-none;
  display: inline-block;
  color: $white;
  font-size: $font-s;
  text-align: center;
  padding: 2px 15px;
  border-radius: calc(#{$base-radius} / 1.5);
  margin-right: 10px;
}

@mixin media-pc {
  @media screen and (min-width: 769px) {
    @content;
  }
}
@mixin media-sp {
  @media screen and (max-width: 768px) {
    @content;
  }
}
