@import '../../../../css/import/import.scss';

/* 価格テーブル */

/* 価格登録のリストスタイル */
ul {
  li.p-price-lists {
    margin-left: 1.5rem;
    list-style-type: disc;
  }
}

/* 価格登録リンクボタン */
.p-priceList-link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
}

.p-groupsName {
  word-break: break-word;
}
