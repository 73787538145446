@import '../../../../css/import/variables.scss';

.p-photographerOrganizationIndex_bgBox {
  background: rgba(255, 255, 255, 0.6);
  min-height: 32px;
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 1.2rem;
  & .c-label_checkbox_inline {
    line-height: 1rem;
  }
}

.p-photographerOrganizationIndex_searchForm {
  & .c-input_multiSelect__menu {
    z-index: 101;
  }
}

.p-photographerOrganizationIndex_accordion_content:not(.is-open) {
  max-height: 0;
  overflow: hidden;
  padding: 0;
  opacity: 0;
  transition: 0.5s;
}

.p-photographerOrganizationIndex_accordion_content.is-open {
  opacity: 1;
  max-height: 800px;
  transition: 0.5s;
  margin-top: 5px;
}

.p-photographerOrganizationIndex_scrollbar {
  width: 100%;
  overflow-x: auto;
  margin: 15px 0;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(#a6a6a6, 60%);
    border-radius: 10px;
  }
}

.p-photographerOrganizationIndex_label {
  font-size: 1rem;
  padding: 2px 8px;
  background: $scales;
  border-radius: calc(#{$base-radius} / 2);
  margin-right: 0;
}

.p-photographerOrganizationIndex_grid_value {
  & .c-icon-arrowRight {
    color: #4a90e2;
    margin-right: 2px;
  }
  & .u-w_85 {
    width: 85%;
  }
}

.p-photographerOrganizationIndex_iconBtn {
  width: 18px;
  height: 18px;
  padding: 3px;
  border-radius: calc(6px / 2);
}

.p-photographerOrganizationIndex_grid_wordWrap {
  word-wrap: break-word;
}

.p-photographerOrganizationIndex_grid_row {
  display: contents;

  &.p-photographerOrganizationIndex_grid_row__close {
    & .p-photographerOrganizationIndex_grid_value,
    & .c-idLink_hasBg {
      background: $scales;
      color: $white;
    }
  }
}

.p-photographerOrganizationIndex_grid_other_department_container,
.p-photographerOrganizationIndex_grid_container {
  display: grid;

  background: #fafafa;
  border-bottom: 1px solid rgba(#a6a6a6, 60%);
  &.finished {
    background: #e8e8e8;
    & .p-photographerOrganizationIndex_grid_value {
      background: #e8e8e8;
      &:nth-child(2n) {
        background: #e8e8e8;
      }
    }
  }
}

.p-photographerOrganizationIndex_grid_other_department_container {
  min-width: 1500px;
  grid-template-columns: 300px // カメラマン組織
    70px // 組織種別
    200px // 適格請求事業者登録番号
    200px // 適格請求事業者登録屋号
    200px // 法人番号
    80px // 所在地
    100px // 所属人数
    400px // メモ
;
}

.p-photographerOrganizationIndex_grid_container_wrap,
.p-photographerOrganizationIndex_grid_other_department_container_wrap {
  min-width: 1500px;
  background: #fafafa;
  max-height: 90vh;
  &.grid_header {
    position: sticky;
    z-index: 30;
    top: 0px;
    border-top: 1px solid rgba(166, 166, 166, 0.6);
  }
}

.p-photographerOrganizationIndex_grid_caption,
.p-photographerOrganizationIndex_grid_value {
  font-size: 1rem;
  padding: 5px 5px;
  background: #fafafa;
  text-align: center;
  height: 50px;
  display: flex;
  align-items: center;
  &:nth-child(2n) {
    background: #ffffff;
  }
  &_1st {
    position: sticky;
    z-index: 20;
    left: 0px;
    text-align: left;
  }
  &_2nd {
    position: sticky;
    z-index: 20;
    left: 300px;
    text-align: left;
    box-shadow: 8px 0px 8px -1px rgba(0, 0, 0, 0.06);
  }
  &.error {
    background: rgba(#f25252, 10%);
    & .c-icon-error {
      margin: 2px 2px 0 0;
    }
  }
}

.p-photographerOrganizationIndex_grid_caption {
  color: #ff8e42;
  font-weight: bold;
  text-align: left;
  &_1st,
  &_2nd {
    top: 0px;
  }
  &_1st {
    left: 0px;
  }
  &_2nd {
    left: 300px;
    box-shadow: 8px 0px 8px -1px rgba(0, 0, 0, 0.06);
  }
}

.p-photographerOrganizationIndex_grid_caption,
.p-photographerOrganizationIndex_grid_value {
  text-align: left;
  word-wrap: break-word;
  &_1st {
    left: 0px;
    text-align: left;
  }
  &_2nd {
    left: 300px;
    text-align: left;
    box-shadow: 8px 0px 8px -1px rgba(0, 0, 0, 0.06);
  }
}

.p-photographerOrganizationIndex_hasOption {
  width: calc(48% - 50px);
}

.p-photographerOrganizationIndex_switchBox {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  padding: 5px 1px;
  border-radius: 6px;
  color: $text-sub;
}

.p-photographerOrganizationIndex_scrollbar {
  font-family: 'Osaka', 'ＭＳ Ｐゴシック', Arial, sans-serif;
}

.p-photographerOrganizationIndex_pdt_xs {
  padding-top: 2px;
}

.p-photographerOrganizationIndex_status {
  padding: 5px;
  margin-right: 10px;
  border: 1px solid;
  &.not_working {
    background-color: grey;
    border-color: grey;
    color: #ffffff;
  }
  &.working {
    background-color: #f25252;
    border-color: #f25252;
    color: #ffffff;
    padding-right: 15px;
  }
  &.reserve {
    background-color: #ffffff;
    border-color: grey;
    color: grey;
    padding-right: 15px;
  }
}

@include media-sp {
  .p-photographerOrganizationIndex_hasOption {
    width: 100%;
  }
  .p-photographerOrganizationIndex_bgBox {
    width: 100%;
    margin: 5px 0px 0px;
  }
}
