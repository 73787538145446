@import '../../../../css/import/import.scss';

.p-groupsSocieties_searchForm_address {
  font-weight: bold;
  line-height: 1.2;
  padding: 8px 10px;
}

.p-groupsSocieties_searchForm_btn {
  .c-input_submit {
    padding: 6px 75px;
    border: none;
  }
}

/* 一覧画面 */
.p-groupsSocietiesList {
  & > li {
    @extend %transition_default;
    &:hover {
      @extend %lines-hover;
    }
  }
  td {
    vertical-align: top;
  }
}
.p-groupsSocieties_id {
  font-size: $font-xs;
  justify-content: flex-start;
  align-items: center;
}
.p-groupsSocietiesList_detailBox {
  width: calc(100% - 80px);
}
.p-groupsSocietiesList_upper {
  margin-bottom: 5px;
  width: 100%;
}
.p-groupsSocietiesList_info {
  font-size: $font-s;
  margin-top: -10px;
  margin-bottom: 5px;
  padding-bottom: 5px;
  & li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 10px;
  }
  &_address {
    width: 95%;
    margin-bottom: 2px;
  }
  &_other {
    width: 120px;
  }
}
.p-groupsSocietiesList_names {
  width: calc(100% - 460px);
}
.p-groupsSocietiesList_contract {
  border-top: 1px solid $scales15;
}
.p-groupsSocietiesList_contractStatus {
  font-size: $font-s;
  margin-top: 5px;
  padding-bottom: 5px;
  & li {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 10px;
  }
}
.p-groupsSocietiesList_contractInfo {
  background: $bg_paleBr10;
  padding: 2px 5px;
  border-radius: calc(#{$base-radius} / 2);
  font-size: $font-xs;
  margin-top: 3px;
}
.p-groupsSocietiesList_contractStatus_type {
  margin-right: 20px;
  width: 210px;
}
.p-groupsSocietiesList_contractInfoCaption {
  color: $brown;
  font-weight: bold;
  margin-right: 10px;
}
.p-groupsSocietiesList_contractInfoValue {
  display: inline-block;
  text-align: right;
  padding-right: 5px;
  margin-right: 5px;
}
.p-groupsSocietiesList_contractInfoValue.has-separate {
  border-right: 1px solid $bg_paleBr10;
  margin-right: 15px;
}

table.c-indexList {
  .p-groupsLabel {
    &_operation {
      & .c-statusLabel {
        font-size: $font-xs;
        width: 48px;
        padding: 0 5px;
        margin-right: 5px;
      }
    }
  }
}

.p-groupSocietiesList tbody tr td {
  word-break: break-all;
}

.p-groupSocietiesList-societyName {
  width: 15rem !important;
}

// 3, 4

@include media_sp {
  .p-groupsSocietiesList_detailBox,
  .p-groupsSocietiesList_names {
    width: 100%;
  }
  .p-groupsSocietiesList_names {
    margin-top: 5px;
    margin-bottom: 10px;
  }
  .p-groupsSocietiesList_upper {
    justify-content: flex-end;
  }
  .p-groupsSocietiesList_contractInfo {
    justify-content: flex-start;
    margin-right: 0;
  }
  .p-groupsSocietiesList_info {
    & li {
      margin-right: 0;
    }
  }
}
