.p-partnerIncentivePaymentsShow-memo-ui {
  list-style: none;
  padding-left: 0;
  margin-bottom: 20px;
  > li {
    position: relative;
    padding-left: 1em;
    &::before {
      content: '・';
      position: absolute;
      left: 0;
    }
    > p {
      margin: 0;
      text-indent: -1em;
    }
  }
}

.p-partnerIncentivePayment-totalCaption {
  width: 25%;
  text-align: right;
}
.p-partnerIncentivePayment-totalItem {
  width: 30%;
  text-align: right;
  padding: 5px 10px 5px 5px;
  &_box {
    background: var(--white);
    border-radius: var(--base-radius);
  }
}

.p-partnerIncentivePayment-tradingMonth {
  padding-right: 10px;
}
