@import '../../css/import/import.scss';
.l-header {
  width: 100%;
  min-width: 1230px;
  background: $white;
}
.p-header_inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  margin: 0;
}
.p-header_logoWrap {
  display: flex;
}
.p-header_infoBox {
  height: 42px;
}
.p-header_button_box {
  margin-right: 10px;
}
.p-header_button {
  padding: 10px 16px;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #a6a6a6;
  white-space: nowrap;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
}
.p-header_text {
  display: block;
  font-size: $font_s;
  padding-left: 22px;
  background-image: url('/images/icon_username.png');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: 17px auto;
}
/*=================
[PC]
header
==================*/
@include media-pc {
  .p-header_inner {
    min-width: $content_width_pc;
    padding: 0 10px;
  }
  .p-header_logo {
    width: 136px;
    margin: auto 0;
    padding-top: 6px;
  }
  .p-header_logoImg {
    width: 100%;
  }
}
/*=================
[SP]
header
==================*/
@include media-sp {
  .l-header {
    min-width: auto;
  }
  .p-header_inner {
    padding: 0 2%;
  }
  .p-header_logo {
    width: 110px;
    line-height: 1.2;
    margin: auto 0;
    padding-top: 6px;
  }
  .p-header_infoBox {
    height: 44px;
  }
  .p-header_text {
    padding-left: 18px;
    background-size: 12px auto;
    font-size: $font_xs;
    width: 84px;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .p-header_character {
    padding: 4px 4px;
  }
  .p-header_button_box {
    margin-right: 5px;
  }
}
