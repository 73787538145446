@import '@/css/import/import';

.listItem {
  border-bottom: 1px dotted rgb(166 166 166 / 60%);
  border-left: 1px dotted rgb(166 166 166 / 60%);
  border-right: 1px dotted rgb(166 166 166 / 60%);
  margin: 20px;

  ul {
    border-top: 1px dotted rgb(166 166 166 / 60%);
    display: flex;

    li {
      padding-bottom: 7px;
      padding-left: 10px;
      padding-top: 8px;
    }

    li:first-child {
      background: $bg_paleGr;
      flex: 0 0 160px;
    }
  }
}
