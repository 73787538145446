@import '@/css/import/import';

.arrow {
  color: $primary;
  cursor: pointer;
  display: inline-block;
  margin: 5px;
}

.resultTableButtonsCell {
  align-items: center;
  display: flex;
}

.innerTableButtons {
  margin: 5px;
  text-align: center;
}

.closeDetailLinkWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
}

.closeDetailLink {
  align-items: center;
  color: $scales60;
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-decoration: underline;
}

.detailForm table {
  td:nth-child(1) {
    width: 10%;
  }

  td:nth-child(2) {
    width: 10%;
  }

  td:nth-child(3) {
    width: 9%;
  }

  td:nth-child(4) {
    width: 8%;
  }

  td:nth-child(5) {
    width: 10%;
  }

  td:nth-child(6) {
    width: 10%;
  }

  td:nth-child(7) {
    width: 12%;
  }

  td:nth-child(8) {
    width: 7%;
  }

  td:nth-child(9) {
    width: 9%;
  }
}
