.search {
  background-color: #202020;
  border-radius: 6px;
  color: #fff;
}

.outline {
  border: #222 solid 1px !important;
  border-radius: 6px;
  color: #000;
  padding: 4px !important;
}

.submit {
  border: none;
  min-width: 100px;
  padding: 6px;
}

.loader {
  animation: l3 1s infinite linear;
  aspect-ratio: 1;
  background: #7a7a7a;
  border-radius: 50%;
  margin-left: 35px;
  mask: var(--mask);
  mask-composite: source-out;
  mask-composite: subtract;
  padding: 4px;
  width: 20px;

  --mask: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
}

@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}
