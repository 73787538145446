@import '../../../../css/import/import.scss';

ul {
  li {
    &.o-owneraccounts-dataLabel {
      flex: 0 0 120px;
    }
  }
}

section {
  &.o-owneraccounts-sec {
    background-color: rgba(128, 128, 128, 0.35);
    border-radius: 3px;
    padding: 0.5% 1%;
    margin-bottom: 10px;
  }
}
