/* 請求明細画面用のcomponents */
@import '../import/import.scss';

%incentivePayment_label {
  padding-left: $base-padding-narrow;
  padding-right: $base-padding-narrow;
  font-size: $font-xs;
  min-width: 69px;
}

.c-incentivePayments {
  .c-page_title {
    margin-right: 0;
  }
  &_searchForm {
    &_targetInfo {
      &_id {
        width: 30%;
        padding-right: $base-padding-narrow;
      }
      &_name {
        width: 70%;
      }
    }
  }
  table.c-indexList {
    .c-incentivePayments_paidAt {
      width: 14rem;
    }
    .c-indexList_column_checkbox {
      width: 3rem;
    }
  }
}

.c-incentivePayment {
  &_statusLabel {
    &__draft {
      @extend %incentivePayment_label;
      color: $text;
      background: $scales15;
    }
    &__verified {
      @extend %incentivePayment_label;
      color: $text;
      background: $scales60;
    }
    &__approved,
    &__unapproved {
      @extend %incentivePayment_label;
      color: $text;
      background: rgba($yellow, 37%);
      border: none;
    }
    &__claimed {
      @extend %incentivePayment_label;
      background: $green;
    }
    &__paid {
      @extend %incentivePayment_label;
      background: rgba($green, 25%);
      color: $green;
    }
    &__default {
      @extend %incentivePayment_label;
      color: $text;
      border: 1px solid $scales15;
    }
  }

  &_label {
    @extend %incentivePayment_label;
    // /* 不備ありラベル */
    // &_incomplete {
    //   background: $brightRed;
    // }
    /* 一括配送 */
    &__bulkDelivery {
      color: $text;
      background: #ffdcc4;
    }
  }

  /* 不備ありなしlabel&ボタン */
  &_bankAccount_hasMistakeToggle {
    font-size: $font-xs;
    padding: 2px 10px 3px;
    background-color: $green;

    &.is-wrong {
      background-color: $brightRed;
    }

    &.is-switch {
      @extend %link-el;
      @extend %btn-shadow;
      padding: 2px 25px 3px 10px;
      background-repeat: no-repeat;
      background-position: 90% 50%;
      background-size: 16px auto;
      background-image: url('/images/icon_switch_off.svg');

      &.is-wrong {
        background-image: url('/images/icon_switch_on.svg');
      }
    }
  }

  /* 明細種類別の見出し */
  &_typeTitle {
    font-size: $font-s;
    background: $bg_paleGr;
    border-top: 1px solid $scales30;
    border-bottom: 1px solid $scales30;
    padding: calc(#{$base-padding} * 0.4) $base-padding;
    margin-bottom: 20px;
    color: $scales;
    position: sticky;
    position: --webkit-sticky;
    top: 0;
    z-index: 100;
  }

  /* 請求明細内容テーブル */
  &_table {
    table-layout: fixed;
    width: 100%;
    &_id,
    &_eventName,
    &_shootingDay,
    &_commissionRate,
    &_incentive,
    &_sales,
    &_target,
    & td {
      padding: 10px 1.1% 10px 0;
    }
    &_id {
      width: 10%;
    }
    &_eventName {
      width: 40%;
    }
    &_shootingDay {
      width: 16%;
    }
    &_incentive {
      width: 13%;
    }
    &_sales {
      width: 17%;
    }

    &_id_cost,
    &_eventName_cost,
    &_shootingDay_cost,
    &_commissionRate_cost,
    &_incentive_cost,
    &_sales_cost,
    &_payments_cost,
    &_target_cost,
    & td {
      padding: 10px 1.1% 10px 0;
    }
    &_id_cost {
      width: 12%;
    }
    &_eventName_cost {
      width: 32%;
    }
    &_shootingDay_cost {
      width: 12%;
    }
    &_commissionRate_cost {
      width: 8%;
    }
    &_incentive_cost {
      width: 10%;
    }
    &_sales_cost {
      width: 13%;
    }
    &_payments_cost {
      width: 13%;
    }

    &_target {
      padding-right: 0;
      width: 38px;
    }
    & tr {
      border-bottom: 1px solid $scales60;
      vertical-align: top;
      &.is-notTarget {
        background: $scales15;
        color: $scales;
        & .c-input_plane {
          @extend %disabled;
        }
      }
    }
    /* override */
    & .c-input_date {
      background-size: 20px auto;
    }
    & td:last-child {
      padding-right: 0;
    }
  }
  &_tableCaption {
    background: $bg_paleBr10;
    border-radius: $base-radius;
    font-size: calc(#{$font-default} * 0.79);
    padding: 7px;
  }
  &_table,
  &_tableOther {
    & td.c-incentivePayment_unitTotalBox {
      padding: 10px 0 25px;
    }
    & td:last-child {
      padding-right: 0;
    }
  }
  &_unitTotal {
    background: $scales15;
    padding-right: 7%;
    padding-top: 7px;
    padding-bottom: 7px;
    min-height: 35px;
  }
  &_addDetailButton {
    position: absolute;
    left: 0;
    bottom: 20px;
  }
  /* tooltip */
  &_tooltip_help {
    background: $scales60;
    border-radius: calc(#{$base-radius} / 2);
    padding: 3px 10px;
    font-size: $font-xs;
    transform: translate(-100%, -100%);
    white-space: nowrap;
  }

  /* その他請求内容テーブル */
  &_tableOther {
    table-layout: fixed;
    width: 100%;
    &_detail,
    &_unitPrice,
    &_set,
    &_payment,
    &_del,
    & td {
      padding: 10px 1.1% 10px 0;
    }
    &_detail {
      width: 50%;
    }
    &_unitPrice {
      width: 16%;
    }
    &_set {
      width: 13%;
    }
    &_payment {
      width: 17%;
    }
    &_del {
      padding-right: 0;
      width: 38px;
    }
    & tr {
      border-bottom: 1px solid $scales60;
      vertical-align: top;
    }
  }
  /* 請求合計エリア */
  &_remarksBox {
    width: 50%;
  }
  &_totalBox {
    width: 47%;
  }
  &_totalCaption {
    width: 40%;
    text-align: right;
  }
  &_totalItem {
    width: 56%;
    text-align: right;
    padding: 5px 10px 5px 5px;
    &_box {
      background: $white;
      border-radius: $base-radius;
    }
  }

  /* 卸値の内訳表 */
  &_wholesale_formula {
    display: inline-flex;
    font-size: $font-xs;
    background: $white;
    border-radius: $base-radius;
    padding: 3px 8px 2px;
  }
  &_wholesale_tableBreakDown {
    table-layout: fixed;
    width: 100%;
    background: $white;
    font-size: $font-xs;
    & th,
    & td {
      border-bottom: 1px solid $scales30;
      &:not(:last-child) {
        border-right: 1px solid $scales30;
      }
      &:nth-child(even) {
        background: $bg_paleGr40;
      }
    }
    &_size,
    &_totalSales,
    &_cost,
    &_sellingPrice,
    &_fee,
    &_numberSold,
    &_payment,
    &_values {
      padding: 6px 1.1% 6px 0;
    }
    & tr > *:last-child {
      padding-right: calc(0.7% + 38px);
    }
    &_totalSales,
    &_cost,
    &_sellingPrice,
    &_fee,
    &_numberSold,
    &_payment {
      text-align: center;
    }
    &_size {
      padding-left: 1%;
      text-align: left;
      font-weight: bold;
      width: 8%;
    }
    &_totalSales {
      width: 22%;
    }
    &_cost,
    &_sellingPrice {
      width: 10.5%;
    }
    &_fee {
      width: 16%;
    }
    &_numberSold {
      width: 13%;
    }
    &_values {
      text-align: right;
    }
  }

  /* 明細の保存、確認済み、承認依頼のボタン */
  &_actionButton {
    width: 160px;

    &:not(:last-child) {
      margin-right: 10px;
    }
  }

  /* 作成・明細画面（PDF） */
  &_fileUpload {
    padding: 7px;
    margin-right: 10px;
    width: 543px;
  }
}

// edit用
.c-incentivePayment {
  &_table.is-edit,
  &_tableOther.is-edit {
    & td:last-child {
      text-align: center;
    }
  }
  &_totalBox.is-edit {
    .c-incentivePayment_totalItem {
      padding-right: calc(38px + 1.1%);
    }
  }
}
