.paymentDayForm {
  bottom: 50px;
  height: 105px;
  position: fixed;
  width: 70%;
}

.bottomSpace {
  padding: 20px;
}
