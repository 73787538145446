.p-photographers_file {
  input[type='file'] {
    display: none;
  }
  .p-photographers_fileName {
    background: #fff0e6;
    border: 1px solid #ff6600;
    border-radius: 5px;
    width: 150px;
    height: 35px;
    color: #ff6600;
    font-size: 15px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 2px 2px 2px rgba(53, 47, 47, 0.25);
  }
}
