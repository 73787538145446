@import '../../../../css/import/import';

.sideMenu {
  background-color: #fff;
  border-radius: 4px;
  height: calc(100% - 200px);
  overflow-y: scroll;
  position: fixed;
  right: 0;
  width: calc(100% / 4);
}

.sideMenuLinkGroup {
  background-color: $bg_paleGr;
  line-height: 2;
  margin: 20px;
  padding: 10px;
  width: calc(100% - 40px);
}
