@import '../../../../css/import/import.scss';

ul {
  li {
    &.e-events-dataLabel {
      flex: 0 0 120px;
    }

    div {
      span {
        &.p-events-cautionText {
          color: red;
          font-size: smaller;
          font-weight: bold;
        }
        &.p-events-annotation {
          font-size: smaller;
        }
      }
      a {
        &.p-events-aboutVisibleEvent {
          text-decoration: none;
          font-size: smaller;
        }
      }
    }

    button {
      &.e-events-button {
        margin-left: 20px;
      }
    }
  }
}

.p-eventSummary_datePicker {
  margin-right: 10px;
}

.p-eventSummary_price {
  padding: 8px;
  border: 1px solid #a6a6a6;
  border-radius: 6px;
  margin-bottom: 4px;

  &.p-eventSummary_price__default {
    background-color: $primary;
  }
  & .c-priceTable {
    margin-bottom: 0;
  }
}
.p-eventSummary_priceBox {
  background-color: $white;
}
.p-eventSummary_price_tableContainer {
  padding: $interval-xs $interval-xs $interval-xs 0;
}

.e-events-buttonLabel,
.e-events-linkLabel {
  font-weight: bold;
}

/* 価格テーブル */
.p-index {
  table.p-indexList {
    font-size: $font-s;
    border-bottom: 1px solid $scales60;
    margin-bottom: 25px;
    width: 100%;
    thead th {
      color: $primary;
      font-weight: bold;
      text-align: left;
      &.u-align_center {
        text-align: center;
      }
    }
    th,
    td {
      border-top: 1px solid $scales60;
      border-right: 2px dotted $scales60;
      padding: $base-padding-narrow $base-padding;
      width: 7rem;
      &.p-backgroud {
        background-color: $bg_paleGr40;
      }
      &.p-bold {
        font-weight: bold;
        white-space: nowrap;
      }
      p {
        &.primary {
          color: $primary;
        }
      }
    }

    .c-statusLabel {
      font-size: $font-s;
      padding: 2px 5px;

      &.c-statusLabel__small {
        font-size: $font-xs;
      }
    }
    .c-indexList_column {
      &__nowrap {
        white-space: nowrap;
      }
      &_xs {
        width: 5rem;
        min-width: 5rem;
      }
      &_s {
        width: 7rem;
        min-width: 5rem;
      }
      &_m {
        width: 10rem;
        min-width: 7rem;
      }
      &_l {
        width: 20rem;
        min-width: 15rem;
      }
    }
  }
}

.p-event_createForm_defaultSelect {
  padding: 5px;
  background-color: $primary;
}

.p-event_createForm_discrete_create_key {
  background-color: #dcd3ff;
}
