@import '../../../../../css/import/import';

.wrapper {
  background: #fafafa;
  border-bottom: 1px solid rgba(#a6a6a6, 60%);
  border-top: 1px solid rgb(166 166 166 / 60%);
  position: sticky;
  top: 0;
  z-index: 30;
}

.container {
  background: #fafafa;
  display: grid;
  grid-template-columns: 1fr;
}

.row {
  display: grid;
}

.column {
  background: #fafafa;
  color: #ff8e42;
  font-size: 1.5rem;
  font-weight: bold;
  height: 30px;
  padding: 5px;
  text-align: left;

  &:nth-child(2n) {
    background: #fff;
  }
}
