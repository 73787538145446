@import '../../../css/import/import';

.dialog {
  border: 1px solid #b4b4b4;
  border-radius: 4px;
  min-width: 500px;
}

.inner {
  padding: 2px;
}

.body {
  color: $text;
  font-size: $font-m;
  margin-bottom: 20px;
  padding: 10px;
}
