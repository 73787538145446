@import '@/css/import/import';

.panel {
  border: 1px solid;
  margin: 1rem 0;
  padding: 12px;
}

.panelSmall {
  padding: 8px;
}

.title {
  align-items: center;
  display: flex;
  font-size: $font-default;
  font-weight: bold;
}

.titleSmall {
  font-size: $font-s;
}

.titleWithChildren {
  margin-bottom: 0.5rem;
}

.icon {
  height: 1.5rem;
  margin-right: 0.5rem;
  width: 1.5rem;
}

.error {
  background-color: #ffe8e3;
  border-color: #c92a2e;
  color: #c92a2e;
}

.warning {
  background-color: #fff7e0;
  border-color: #f4cb00;

  .icon {
    color: #ffb400;
  }
}
