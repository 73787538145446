@import '../../../css/import/import';

.baseNotice {
  background: rgba($brightRed, 10%);
  border-radius: $base-radius;
  margin-bottom: 30px;
  padding: 15px;
  position: relative;
  width: 100%;
}

.errorBlock {
  background: rgba($brightRed, 10%);
}

.warningBlock {
  background: #fff3cd;
}

.successBlock {
  background: rgba($green, 10%);
}

.baseListItem {
  font-size: $font-default;
  list-style-type: disc;
  margin-bottom: 3px;
  margin-left: 1em;
}

.warningListItem {
  color: #856404;
}

.errorListItem {
  color: #721c24;
}

.successListItem {
  color: #104e25;
}

.closeButton {
  background: none;
  border: none;
  color: rgba($dark70, 20%);
  cursor: pointer;
  font-size: 3rem;
  position: absolute;
  right: 10px;
  top: 8px;
}
