.changeTimeWrap {
  display: flex;

  li:first-child {
    flex: 0 0 auto;
  }

  li:last-child {
    flex-grow: 1;
    margin-left: 1rem;
  }
}

.meetingTimeNotice {
  font-weight: bold;
}
