.base {
  height: 100%;
  width: 100%;
}

.directionRow {
  display: flex;
  flex-direction: row;
}

.directionRowReverse {
  display: flex;
  flex-direction: row-reverse;
}

.directionColumn {
  display: flex;
  flex-direction: column;
}

.justifyContentFlexStart {
  -webkit-box-pack: start;
  justify-content: flex-start;
}

.justifyContentCenter {
  -webkit-box-pack: center;
  justify-content: center;
}

.justifyContentFlexEnd {
  justify-content: flex-end;
}

.justifyContentSpaceBetween {
  justify-content: space-between;
}

.justifyContentSpaceAround {
  justify-content: space-around;
}

.justifyContentSpaceEvenly {
  justify-content: space-evenly;
}

.alignItemsFlexStart {
  align-items: flex-start;
}

.alignItemsCenter {
  align-items: center;
  -webkit-box-align: center;
}

.alignItemsFlexEnd {
  align-items: flex-end;
}

.alignItemsStretch {
  align-items: stretch;
  -webkit-box-align: stretch;
}

.alignItemsBaseline {
  align-items: baseline;
  -webkit-box-align: baseline;
}

.rowSpacing1 *:not(:first-child) {
  margin-left: 8px;
}

.rowSpacing2 *:not(:first-child) {
  margin-left: 16px;
}

.rowSpacing3 *:not(:first-child) {
  margin-left: 24px;
}

.rowSpacing4 *:not(:first-child) {
  margin-left: 32px;
}

.rowSpacing5 *:not(:first-child) {
  margin-left: 40px;
}

.rowSpacing6 *:not(:first-child) {
  margin-left: 48px;
}

.rowSpacing7 *:not(:first-child) {
  margin-left: 56px;
}

.rowSpacing8 *:not(:first-child) {
  margin-left: 64px;
}

.rowSpacing9 *:not(:first-child) {
  margin-left: 72px;
}

.rowSpacing10 *:not(:first-child) {
  margin-left: 80px;
}

.rowSpacing11 *:not(:first-child) {
  margin-left: 88px;
}

.rowSpacing12 *:not(:first-child) {
  margin-left: 96px;
}

.columnSpacing1 *:not(:first-child) {
  margin-top: 8px;
}

.columnSpacing2 *:not(:first-child) {
  margin-top: 16px;
}

.columnSpacing3 *:not(:first-child) {
  margin-top: 24px;
}

.columnSpacing4 *:not(:first-child) {
  margin-top: 32px;
}

.columnSpacing5 *:not(:first-child) {
  margin-top: 40px;
}

.columnSpacing6 *:not(:first-child) {
  margin-top: 48px;
}

.columnSpacing7 *:not(:first-child) {
  margin-top: 56px;
}

.columnSpacing8 *:not(:first-child) {
  margin-top: 64px;
}

.columnSpacing9 *:not(:first-child) {
  margin-top: 72px;
}

.columnSpacing10 *:not(:first-child) {
  margin-top: 80px;
}

.columnSpacing11 *:not(:first-child) {
  margin-top: 88px;
}

.columnSpacing12 *:not(:first-child) {
  margin-top: 96px;
}
