@import 'import/import.scss';

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
li,
dl,
dt,
dd,
p {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

* {
  box-sizing: border-box;
}
html {
  position: relative;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 62.5%;
  height: 100%;
}
body {
  height: 100%;
  font-size: $font-default;
  background: $bg_paleGr;
  color: $text;
  @extend %font-family-base;
}
body.is-open {
  overflow: hidden;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}
select,
input,
textarea,
button {
  font-size: $font-default;
}
select {
  appearance: none;
  border: none;
}
input[type='text'] {
  @extend %font-family-base;
}
input[type='checkbox'],
input[type='radio'] {
  /* html5のrequiredが効かなくなるのでコメントアウト-> display: none; */
  opacity: 0;
  margin: 0;
  width: 1px;
}
a,
input[type='submit'],
input[type='reset'],
input[type='button'] {
  @extend %link-el;
}
input[type='submit'],
input[type='reset'],
input[type='button'] {
  outline: none;
  appearance: none;
  border-radius: $base-radius;
}
input[type='date']::-webkit-inner-spin-button,
input[type='month']::-webkit-inner-spin-button,
input[type='year']::-webkit-inner-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type='date'],
input[type='month'],
input[type='year'] {
  &::-webkit-calendar-picker-indicator {
    opacity: 0;
  }
}
*::placeholder {
  color: $scales;
}
