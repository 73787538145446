@import '../import';

/* 検索フォーム */
.p-orderInfoIndex_bgBox {
  background: rgba(255, 255, 255, 0.6);
  min-height: 32px;
  padding: 6px 10px;
  border-radius: 6px;
  font-size: 1.2rem;
  & .c-label_checkbox_inline {
    line-height: 1rem;
  }
}

.p-orderInfo_dataLabel {
  flex: 0 0 80px;
  margin-right: 10px;
  font-weight: bold;
  line-height: 1.2;
  &.tin {
    font-weight: normal;
  }
  & small {
    font-size: $font-xs;
  }
  & .c-required {
    display: block;
  }
  padding: $base-padding-narrow $base-padding;
  margin-right: 0;
  margin-bottom: 4px;
}

.p-orderInfoIndex_searchForm {
  .p-searchForm {
    &_useraccount {
      width: calc(100% / 24 * 8);
    }
    &_society {
      width: calc(100% / 24 * 7);
    }
    &_event {
      width: calc(100% / 24 * 7);
    }
    &_createdday {
      width: calc(100% / 24 * 12);
    }
    &_paymentday {
      width: calc(100% / 24 * 12);
    }
  }
  & .c-input_multiSelect__menu {
    z-index: 101;
  }
}

.p-btn_toggle {
  padding: $base-padding-narrow $base-padding;
  margin-right: 0;
  margin-bottom: 4px;
}

.p-input_orderInfoDateBlock {
  display: flex;
  justify-content: space-between;
  position: relative;
  & > li {
    width: 45%;
  }
  &:after {
    content: '〜';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, 0);
    margin-top: -10px;
  }
}

/* 検索結果表示フォーム */

.p-orderInfoIndex_scrollbar {
  width: 100%;
  overflow-x: auto;
  margin: 15px 0;
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  &::-webkit-scrollbar-track {
    background: #fff;
  }
  &::-webkit-scrollbar-thumb {
    background: rgba(#a6a6a6, 60%);
    border-radius: 10px;
  }
}

.c-icon-groups:before {
  content: '\e906';
}

.c-icon-arrowRight:before {
  content: '\e936';
}

.c-icon-error:before {
  content: '\e900';
}

.c-icon_XXsmall {
  width: 11px;
  height: 11px;
}

.p-orderInfoIndex_label {
  font-size: 1rem;
  padding: 2px 8px;
  background: $scales;
  border-radius: calc(#{$base-radius} / 2);
  margin-right: 0;
}

.p-orderInfoIndex_grid_value {
  & .c-icon-arrowRight {
    color: #4a90e2;
    margin-right: 2px;
  }
  & .u-w_85 {
    width: 85%;
  }
}

.p-orderInfoIndex_iconBtn {
  width: 18px;
  height: 18px;
  padding: 3px;
  border-radius: calc(6px / 2);
}

.p-orderInfoIndex_grid_wordWrap {
  word-wrap: break-word;
}

.p-orderInfoIndex_grid_wordBreak {
  word-break: break-word;
}

.p-orderInfoIndex_grid_row {
  display: contents;

  &.p-orderInfoIndex_grid_row__close {
    & .p-orderInfoIndex_grid_value,
    & .c-idLink_hasBg {
      background: $scales;
      color: $white;
    }
  }
}

.p-orderInfoIndex_grid_container_header {
  display: grid;

  background: #fafafa;
  border-bottom: 2px solid rgba(#a6a6a6, 60%);
  min-width: 2270px;
  grid-template-columns: 120px // 注文ID
    80px // まとめ<br/>発送番号
    80px // まとめ<br/>期限日
    80px // 購入商品
    80px // 会員ID
    80px // 会員名
    80px // クラス
    80px // 注文日時
    80px // 入金日時
    80px // 支払状況
    200px // 支払い方法
    100px // コンビニ支払期限
    200px // コンビニ支払い番号
    250px // 住所
    180px // 電話番号
    180px // メールアドレス
    80px // 合計金額
    80px // 現金支払い金額
    80px // フォトマネー使用金額
    80px // クーポン使用金額
;
  &.finished {
    background: #e8e8e8;
  }
}

.p-orderInfoIndex_grid_other_department_container,
.p-orderInfoIndex_grid_container {
  display: grid;

  background: #fafafa;
  border-bottom: 1px solid rgba(#a6a6a6, 60%);
  &.finished {
    background: #e8e8e8;
    & .p-orderInfoIndex_grid_value {
      background: #e8e8e8;
      &:nth-child(2n) {
        background: #e8e8e8;
      }
    }
  }
}

.p-orderInfoIndex_grid_other_department_container {
  min-width: 2270px;
  grid-template-columns: 120px // 注文ID
    80px // まとめ<br/>発送番号
    80px // まとめ<br/>期限日
    80px // 購入商品
    80px // 会員ID
    80px // 会員名
    80px // クラス
    80px // 注文日時
    80px // 入金日時
    80px // 支払状況
    200px // 支払い方法
    100px // コンビニ支払期限
    200px // コンビニ支払い番号
    250px // 住所
    180px // 電話番号
    180px // メールアドレス
    80px // 合計金額
    80px // 現金支払い金額
    80px // フォトマネー使用金額
    80px // クーポン使用金額
;
}

.p-orderInfoIndex_grid_container_wrap,
.p-orderInfoIndex_grid_other_department_container_wrap {
  min-width: 2270px;
  background: #fafafa;
  max-height: 90vh;
  &.grid_header {
    position: sticky;
    z-index: 30;
    top: 0px;
  }
}

.p-orderInfoIndex_grid_caption,
.p-orderInfoIndex_grid_value {
  font-size: 1rem;
  padding: 5px 5px;
  background: #fafafa;
  &:nth-child(2n) {
    background: #ffffff;
  }
  &_1st {
    position: sticky;
    z-index: 20;
    left: 0px;
    text-align: left;
  }
  &_2nd {
    position: sticky;
    z-index: 20;
    left: 80px;
    text-align: left;
  }
  &_3rd {
    position: sticky;
    z-index: 20;
    left: 220px;
    text-align: left;
  }
  &_4th {
    position: sticky;
    z-index: 20;
    left: 290px;
    text-align: left;
    box-shadow: 8px 0px 8px -1px rgba(0, 0, 0, 0.06);
  }
  &.error {
    background: rgba(#f25252, 10%);
    & .c-icon-error {
      margin: 2px 2px 0 0;
    }
  }
  & .c-textlink {
    @extend %textdecoration-none;
  }
  & .c-idLink_hasBg {
    display: flex;
  }
}

.p-orderInfoIndex_grid_caption {
  font-weight: bold;
  text-align: left;
}

.p-orderInfoIndex_grid_value {
  text-align: center;
}

.p-orderInfoIndex_grid_caption,
.p-orderInfoIndex_grid_value {
  &_1st {
    left: 0px;
    text-align: left;
  }
  &_2nd {
    left: 80px;
    text-align: left;
  }
  &_3rd {
    left: 220px;
    text-align: left;
  }
  &_4th {
    left: 290px;
    text-align: left;
    box-shadow: 8px 0px 8px -1px rgba(0, 0, 0, 0.06);
  }
}

.p-orderInfoIndex_grid_value {
  & .c-icon-arrowRight {
    color: #4a90e2;
    margin-right: 2px;
  }
  & .u-w_85 {
    width: 85%;
  }
}

.p-orderInfoIndex_hasOption {
  width: calc(48% - 50px);
}

.p-orderInfoIndex_switchBox {
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  align-items: center;
  padding: 5px 1px;
  border-radius: 6px;
  color: $text-sub;
}

.p-orderInfoIndex_scrollbar {
  font-family: 'Osaka', 'ＭＳ Ｐゴシック', Arial, sans-serif;
}

.p-orderInfoIndex_pdt_xs {
  padding-top: 2px;
}
