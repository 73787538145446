.container {
  display: flex;
  margin-top: 8px;
  text-align: center;
}

.content {
  display: flex;
  flex: 1 1;
  gap: 20px;
  justify-content: center;
  padding: 5px 10px;
}
