@import '../../../../../css/import/import';

.table {
  background-color: white;
  border-bottom: 1px solid $scales60;
  font-size: $font-s;
  margin-bottom: 25px;
  width: 100%;

  thead th {
    color: $primary;
    font-weight: bold;
    text-align: left;
  }

  tbody th,
  td {
    border-right: 2px dotted $scales60;
    border-top: 1px solid $scales60;
    padding: $base-padding-narrow $base-padding;
    width: 7rem;

    &:last-child {
      border-right: none;
    }
  }
}
