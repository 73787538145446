@import '@/css/import/import';

.textBorder {
  border: 1px solid $scales;
  border-radius: 3px;
  margin-right: 6px;
  padding: 2px 4px;
}

.noneUnderLine {
  color: black;
  text-decoration: none;
}

.tabLink {
  align-items: center;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;

  a {
    text-decoration: none;
  }
}

@include media-pc {
  div {
    .contentOverwrite {
      min-width: auto;
    }
  }
}
