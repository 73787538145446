.svg-loading-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  margin: auto;
  text-align: center;
}

.svg-loading-icon {
  margin-top: 2em;
  width: 25%;
  height: 25%;
}

.svg-loading-text {
  margin-bottom: 2em;
}
