@import '@/css/import/import';

.checkListOverlay {
  background-color: white;
  background-color: #f0f6ff;
  box-shadow: 0 1px 3px $shadow10;
  height: calc(100% - 30px);
  position: fixed;
  right: 50px;
  top: 30px;
  width: 250px;
  z-index: 100;
}

.checkListHead {
  align-items: center;
  background-color: #0e4dca;
  border-radius: 5px 5px 0 0;
  color: white;
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: space-between;
  padding: 0 10px;
}

.checkListContainer {
  height: calc(100vh - 60px);
  overflow: scroll;
  position: relative;
}

.uncheckedChildItem {
  color: #c4362b;
  font-weight: bold;
}

.starChildItem {
  color: #0e4dca;
  font-weight: bold;
}

.uncheckedChildItem,
.starChildItem,
.checkedChildItem {
  align-items: center;
  cursor: pointer;
  display: flex;
  padding-left: 20px;

  &:hover {
    background-color: #d9e4fc;
  }
}

.childItemIcon {
  margin-right: 5px;
}

.checkedParentItem {
  color: black;
}

.uncheckedParentItem {
  color: #c4362b;
}

.checkedParentItem,
.uncheckedParentItem {
  cursor: pointer;
  font-weight: bold;
  margin-top: 10px;
  padding-left: 10px;

  &:hover {
    text-decoration: underline;
  }
}

.closedCheckBoxButton {
  background-color: #0e4dca;
  border: 0;
  border-radius: 5px 5px 0 0;
  bottom: 0;
  color: white;
  cursor: pointer;
  height: 30px;
  margin: 0 0 0 60px !important;
  padding: 0 10px !important;
  position: fixed;
  right: 75px;
  z-index: 99;
}

.closeButtonIcon {
  margin-right: 5px;
}

.closeButton {
  align-items: center;
  background-color: transparent;
  border: 0;
  color: #0e4dca;
  cursor: pointer;
  display: flex;

  &:hover {
    text-decoration: underline;
  }
}
