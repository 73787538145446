/* icon font */
@font-face {
  font-family: 'icon';
  src: url('/fonts/icon-font.eot?hbi277');
  src: url('/fonts/icon-font.eot?hbi277#iefix') format('embedded-opentype'),
    url('/fonts/icon-font.ttf?hbi277') format('truetype'),
    url('/fonts/icon-font.woff?hbi277') format('woff'),
    url('/fonts/icon-font.svg?hbi277#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^='c-icon_'],
[class*=' c-icon_'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*size icon*/
.c-icon {
  width: 25px;
  height: 25px;
  font-size: 25px;
  display: inline-block;
  text-align: center;
  &.c-icon__small {
    width: 20px;
    height: 20px;
    font-size: 20px;
  }
  &.c-icon__xsmall {
    width: 14px;
    height: 14px;
    font-size: 14px;
  }
  &.c-icon__xxsmall {
    width: 11px;
    height: 11px;
    font-size: 11px;
  }
}
@include media_sp {
  .c-icon {
    width: 18px;
    height: 18px;
  }
}

/* web font icons
preview URL
 */
.c-icon_error:before {
  content: '\e900';
}
.c-icon_event:before {
  content: '\e901';
}
.c-icon_export:before {
  content: '\e902';
}
.c-icon_eyeApear:before {
  content: '\e903';
}
.c-icon_eyeInvisible:before {
  content: '\e904';
}
.c-icon_grid:before {
  content: '\e905';
}
.c-icon_groups:before {
  content: '\e906';
}
.c-icon_heart:before {
  content: '\e907';
}
.c-icon_help:before {
  content: '\e908';
}
.c-icon_import:before {
  content: '\e909';
}
.c-icon_in:before {
  content: '\e90a';
}
.c-icon_key:before {
  content: '\e90b';
}
.c-icon_leader:before {
  content: '\e90c';
}
.c-icon_mail:before {
  content: '\e90d';
}
.c-icon_map:before {
  content: '\e90e';
}
.c-icon_member:before {
  content: '\e90f';
}
.c-icon_out:before {
  content: '\e910';
}
.c-icon_partner:before {
  content: '\e911';
}
.c-icon_person:before {
  content: '\e912';
}
.c-icon_person2:before {
  content: '\e913';
}
.c-icon_photo:before {
  content: '\e914';
}
.c-icon_photos:before {
  content: '\e915';
}
.c-icon_plan:before {
  content: '\e916';
}
.c-icon_popup:before {
  content: '\e917';
}
.c-icon_printOffice:before {
  content: '\e918';
}
.c-icon_refresh:before {
  content: '\e919';
}
.c-icon_salesman:before {
  content: '\e91a';
}
.c-icon_search:before {
  content: '\e91b';
}
.c-icon_search2:before {
  content: '\e91c';
}
.c-icon_setting:before {
  content: '\e91d';
}
.c-icon_sortAsc:before {
  content: '\e91e';
}
.c-icon_sortDesc:before {
  content: '\e91f';
}
.c-icon_star:before {
  content: '\e920';
}
.c-icon_success:before {
  content: '\e921';
}
.c-icon_tel:before {
  content: '\e922';
}
.c-icon_time:before {
  content: '\e923';
}
.c-icon_trash:before {
  content: '\e924';
}
.c-icon_yen:before {
  content: '\e925';
}
.c-icon_edit:before {
  content: '\e926';
}
.c-icon_download:before {
  content: '\e927';
}
.c-icon_dateRequest:before {
  content: '\e928';
}
.c-icon_create:before {
  content: '\e929';
}
.c-icon_date:before {
  content: '\e92a';
}
.c-icon_dateCheck:before {
  content: '\e92b';
}
.c-icon_dateInput:before {
  content: '\e92c';
}
.c-icon_dateMoney:before {
  content: '\e92d';
}
.c-icon_cameraman:before {
  content: '\e92e';
}
.c-icon_clip:before {
  content: '\e92f';
}
.c-icon_close:before {
  content: '\e930';
}
.c-icon_copy:before {
  content: '\e931';
}
.c-icon_bubble:before {
  content: '\e932';
}
.c-icon_bubbles:before {
  content: '\e933';
}
.c-icon_arrowDown:before {
  content: '\e934';
}
.c-icon_arrowLeft:before {
  content: '\e935';
}
.c-icon_arrowRight:before {
  content: '\e936';
}
.c-icon_arrowUp:before {
  content: '\e937';
}
.c-icon_blank:before {
  content: '\e938';
}
