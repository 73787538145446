@import '@/css/import/import';

@media print {
  header {
    visibility: hidden;
  }

  .content {
    position: absolute;
    top: 0;
  }
}

.content {
  padding: 50px 100px;
}

.contentWrap {
  margin: auto;
  max-width: 1600px;
  min-width: 400px;
}

.texBorder {
  border-bottom: 2px solid $dark;
  border-collapse: collapse;
}

.rowColorTable {
  tr,
  td {
    border: 1px solid rgb(166 166 166 / 60%);
  }

  td:nth-child(1) {
    background: #fafafa;
  }

  td:nth-child(2) {
    background: none;
  }
}

.colColorTable {
  table {
    border-collapse: collapse;
  }

  thead td:nth-child(n) {
    background: #fafafa;
    border: 1px solid rgb(166 166 166 / 60%);
  }

  tbody td:nth-child(n) {
    background: none;
    border: 1px solid rgb(166 166 166 / 60%);
  }
}

.transferDetailTable {
  tbody {
    /* 日付 */
    td:nth-child(1) {
      width: 5%;
    }

    /* 項目 */
    td:nth-child(2) {
      width: 5%;
    }

    /* カメラマン */
    td:nth-child(3) {
      width: 10%;
    }

    /* 団体名 */
    td:nth-child(4) {
      width: 15%;
    }

    /* イベント */
    td:nth-child(5) {
      width: 27%;
    }

    /* 備考 */
    td:nth-child(6) {
      width: 25%;
    }

    /* 税率 */
    td:nth-child(7) {
      width: 5%;
    }

    /* 金額(税抜) */
    td:nth-child(8) {
      width: 8%;
    }
  }
}

.paymentStatusLogo {
  background: $blue;
  border: 1px solid;
  border-radius: 4px;
  display: inline-block;
  font-size: 13px;
  height: 40px;
  margin-right: 10px;
  padding: 8px;
}
