@import '../../../css/import/import';

.title {
  color: $text;
  font-size: 2.4rem;
  font-weight: bold;
  margin-bottom: $interval-m;
  margin-right: 10px;
  text-decoration: none;

  @include media-sp {
    font-size: $font-l;
  }
}

.container {
  @include media-pc {
    display: flex;
    max-width: 100%;
    min-width: $main-minwidth-pc;
  }

  @include media-sp {
    margin-left: -2%;
    margin-right: -2%;
  }
}

.wrapper {
  @include media-pc {
    min-width: calc(100% - 390px);
    width: 100%;
  }
}
