@import '@/css/import/import';

.background {
  background-color: $scales15;
  margin-bottom: 0.5rem;
  padding: 0.5rem 1rem;
}

.clickableBlock {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.title {
  align-items: center;
  display: flex;
  font-size: 1.5rem;
  font-weight: bold;
}

.icon {
  color: #c92a2e;
  height: 1.5rem;
  margin-right: 0.5rem;
  width: 1.5rem;
}

.arrow {
  color: $scales;
  cursor: pointer;
  display: inline-block;
  margin: 5px;
}
