@import '@/css/import/import';

.main {
  align-items: center;
  background: #fff;
  bottom: 0;
  box-shadow: 0 -1px 1px $shadow20;
  display: flex;
  height: 60px; /* 縦幅を調整 */
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 1;

  button {
    font-weight: bold;
    margin: 20px;
    padding: 10px 50px;
  }
}

.padding {
  height: 80px;
}
