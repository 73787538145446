@import '@/css/import/import';

.table {
  width: 100%;
}

.tableHead {
  font-weight: bold;
}

.tableRow {
  border-bottom: 1px solid $scales60;
}

.tableCell {
  padding: 5px;

  &:nth-child(2n) {
    background-color: #fafafa;
  }

  &:nth-child(2n + 1) {
    background-color: white;
  }

  .highlighted & {
    background-color: $primary-variant;
  }

  .disabled & {
    background-color: $scales60;
  }
}

.openedDetailTableCell {
  padding: 0;
}

.openedDetailContainer {
  background-color: white;
  padding: 10px;
}

.openedDetailFooter {
  background-color: transparent;
  height: 20px;
  width: 100%;
}

.innerTable {
  width: 100%;
}

.innerTableCell {
  background-color: white;
  border: 1px solid $scales60;
  height: 20px;
  padding: 5px;

  .innerTableHead & {
    background-color: #fafafa;
  }

  .disabled & {
    background-color: $scales60;
  }
}
