@import '../import';

$first-event-color: #ffe0e098;
$second-event-color: #e0f4ff98;

.p-recommendedPhotographers_container {
  display: flex;
  flex-direction: row;
  & > .p-recommendedPhotographers_eventsContainer {
    width: 25rem;
    padding-right: 8px;
  }
  & > .p-recommendedPhotographers_photographersContainer {
    width: calc(100% - 25rem);
    display: flex;
    .p-recommendedPhotographers_photographers {
      padding: 4px;
      &:nth-child(1) {
        width: 60%;
        background-color: $first-event-color;
      }
      &:nth-child(2) {
        width: 40%;
        background-color: $second-event-color;
      }
      &.p-recommendedPhotographers_photographers__fullWidth {
        width: 100%;
      }
    }
  }
  & .c-searchForm .c-accordion_content.is-open {
    overflow: visible;
  }
}
.p-recommendedPhotographers_events_header {
  padding: 0 4px 8px 4px;
}

.p-recommendedPhotographers_events_event {
  padding: 8px 4px;
  border-top: 1px solid $scales30;
  background: url('/images/pager_next.png') no-repeat right center / 24px auto;
  a {
    display: block;
    color: $text;
    text-decoration: none;
  }
  &_check {
    width: 4rem;
  }
  &_info {
    padding-left: 4px;
    width: calc(100% - 4rem);
  }
  &__selected {
    &_first {
      background-color: $first-event-color;
    }
    &_second {
      background-color: $second-event-color;
    }
  }
}

.p-recommendedPhotographers_photographersContainer {
  .c-indexList {
    margin-bottom: 8px !important;
  }
}
