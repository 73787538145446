@import '../../../css/import/import';

.wrapper {
  background-color: rgb(0 0 0 / 40%);
  height: 100vh;
  left: 0;
  position: fixed;
  text-align: center;
  top: 0;
  transition: 0.3s linear;
  width: 100vw;
  z-index: 9999;
}

.progress {
  background-color: rbg(255, 255, 255);
  inset: 0;
  margin: auto;
  position: absolute;
}

.loader {
  animation: l3 1s infinite linear;
  aspect-ratio: 1;
  background: rgba($primary, 80%);
  border-radius: 50%;
  mask: var(--mask);
  mask-composite: source-out;
  mask-composite: subtract;
  padding: 4px;
  width: 40px;

  --mask: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
}

@keyframes l3 {
  to {
    transform: rotate(1turn);
  }
}
