@import '@/css/import/import';

.arrow {
  color: $primary;
  cursor: pointer;
  display: inline-block;
  margin: 5px;
}

.resultTableButtonsCell {
  align-items: center;
  display: flex;
}

.resultDetailRow {
  background-color: $white;
}

.innerTable {
  margin: 10px;
  width: calc(100% - 20px);
}

.innerTableCell {
  background-color: white;
  border: 1px solid $scales60;
  padding: 5px;
}

.canceled .innerTableCell {
  background-color: $scales;
}

.innerTableButtons {
  margin: 5px;
  text-align: center;
}

.paymentsStatusLabel {
  display: inline-block;
  font-size: 1rem;
  font-weight: bold;
  margin-right: 5px;
  padding-left: 5px;
  padding-right: 5px;

  &.working {
    background-color: $primary;
    border: 1px solid $primary;
    color: white;
  }

  &.closed {
    border: 1px solid $primary;
    color: $primary;
  }

  &.canceled {
    background-color: $scales;
    border: 1px solid $scales;
    color: white;
  }
}

.closeLinkWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
}

.closeLink {
  color: $scales60;
  cursor: pointer;
  display: flex;
  justify-content: center;
  text-decoration: underline;
}

.paymentsIndexExternalMemo {
  max-width: 500px;
  overflow: hidden;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
