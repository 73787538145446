@import '@/css/import/import';

.tabListItem {
  align-items: center;
  border-radius: 6px 6px 0 0;
  color: #999;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-right: 6px;
  margin-top: 5px;
  padding: 4px 6px;
  text-align: center;
  width: 100%;
}

.tabListItem.isActive {
  background-color: #eee;
  border-bottom: 2px solid $primary;
  color: $primary;
  font-weight: bold;
}

.tabListItem a {
  display: block;
  height: 100%;
  width: 100%;
}

.tabListItem.isActive a {
  color: $primary;
}
