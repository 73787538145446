@import '../import/import.scss';

.react-datepicker-wrapper {
  width: auto;
}

.react-datepicker-popper {
  z-index: 100;
}

.c-input_datePicker_wrapper {
  // NOTE: popoverのpositionが狂わないために必要
  position: relative;
}

.c-input_datePicker {
  @include form-common;
  @extend %font-family-base;
  width: 13rem;

  &.is-error {
    border-color: $brightRed;
  }
  &::-webkit-calendar-picker-indicator {
    display: none;
  }
  &_hidden {
    background-color: rgba(255, 255, 255, 0);
    width: 0;
    border: 0;
  }
  &.c-input_datePicker__xsmall {
    width: 10.5rem;
    min-height: 30px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #a3c3db;
}

.react-datepicker__day--today {
  background-color: #f0d0d0;
  border-radius: 0.3rem;
}

.react-datepicker__day--outside-month {
  color: #999;
}

.react-datepicker__week {
  // sunday, saturday
  .react-datepicker__day:first-of-type,
  .react-datepicker__day:last-of-type,
  .react-datepicker__day.is-holiday {
    color: rgb(209, 0, 0);
    &.react-datepicker__day--outside-month {
      color: rgb(204, 125, 125);
    }
  }
}

.react-datepicker__close-icon {
  right: 0.3rem;
  width: 2rem;

  &::after {
    background-color: rgba(255, 255, 255, 0);
    color: #999;
    font-size: 2rem;
  }
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  margin: 0 1.2rem;
}
.react-datepicker__header__dropdown {
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: center;
  align-items: center;
}
.react-datepicker__month-read-view {
  .react-datepicker__month-read-view--down-arrow {
    // NOTE: 若干上に出るので微調整
    top: 2px;
  }
}

// NOTE: default font-size が小さすぎるので拡大

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  font-size: 1.5rem;
}

.react-datepicker__header__dropdown {
  font-size: 1.2rem;
}

.react-datepicker {
  font-size: 1.4rem;
}
.react-datepicker__year,
.react-datepicker__month {
  margin: 1rem;
}
.react-datepicker__year .react-datepicker__year-text,
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  width: 8.5rem;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list {
  height: calc(195px + (2.4rem / 2));
}
.react-datepicker__week-number,
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2.2rem;
  line-height: 2.2rem;
  margin: 0.3rem;
}
.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 6rem;
  line-height: 6rem;
}
@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 4.8rem;
    line-height: 4.8rem;
  }
}
.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 2rem;
}
