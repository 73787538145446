@import '../../../css/import/import';

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.panel {
  margin-bottom: 15px;
  padding: calc(#{$base-padding} * 1.5);
  width: 49%;

  @include default-box;

  @include media-sp {
    width: 100%;
  }
}

.noFramePanel {
  width: 49%;
}
