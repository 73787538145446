@import '../../../../../css/import/import';

.wrapper {
  background: #fafafa;
  position: sticky;
  top: 0;
  z-index: 30;
}

.container {
  background: #fafafa;
  display: grid;
  grid-template-columns: 1fr;
}

.row {
  border-bottom: 1px solid rgba(#a6a6a6, 60%);
  display: grid;
}

.column {
  background: #fafafa;
  font-size: 1.5rem;
  min-height: 30px;
  padding: 5px;
  text-align: left;

  &:nth-child(2n) {
    background: #fff;
  }
}
