/* 項目の幅を広げる 改行をなるべくなくすため */
.l-content_wrap .dataLabel-large {
  flex: 0 0 128px;
}

.delivery_period_text {
  vertical-align: middle;
  display: table-cell;
  height: 35px;
  padding-left: 4px;
}

.flyer_form ul {
  margin-top: 10px;
}

.period_text {
  margin-bottom: 10px;
}
