@import '../import';

.p-groupIndex {
  .c-searchForm {
    &_modelInfo {
      width: calc(100% / 24 * 17);
      &_id {
        width: 20%;
        padding-right: $base-padding-narrow;
      }
      &_name {
        width: 40%;
        padding-right: $base-padding-narrow;
      }
      &_kana {
        width: 40%;
      }
    }
    &_tel,
    &_fax {
      width: calc(100% / 24 * 7);
    }
  }
  .c-popup_submenuWrap {
    right: 5rem;
    top: inherit;
    white-space: nowrap;
  }
}

@include media-sp {
  .p-groupIndex {
    .c-searchForm {
      &_modelInfo {
        width: 100%;
        &_id {
          width: 30%;
          padding-bottom: $base-padding-narrow;
        }
        &_name {
          width: 70%;
          padding-right: 0;
          padding-bottom: $base-padding-narrow;
        }
        &_kana {
          width: 100%;
        }
      }
      &_tel,
      &_fax {
        width: 100%;
      }
    }
  }
}
