@import '../../css/import/import.scss';
/*=================
 side-menu
==================*/
.l-side_wrap {
  background: $dark90;
  color: $white;
}
.p-side_titleWrap {
  position: relative;
}
.p-side_title {
  background-repeat: no-repeat;
}
.p-side_menuList_title {
  background: $dark;
  display: block;
  padding: 18px 0 18px 10px;
}
.p-side_menuList {
  padding-bottom: 60px;
}
.p-side_icon {
  display: inline-block;
  width: 18px;
  height: 18px;
  background-repeat: no-repeat;
  background-size: 18px auto;
  margin-right: 8px;
  vertical-align: text-bottom;
}
.p-side_icon__salesman {
  background-image: url(/images/icon_side_salesman.png);
}
.p-side_icon__photographer {
  background-image: url(/images/icon_side_photographer.png);
}
.p-side_icon__events {
  background-image: url(/images/icon_side_events.png);
}
.p-side_icon__users {
  background-image: url(/images/icon_side_users.png);
}
.p-side_icon__others {
  background-image: url(/images/icon_side_others.png);
}
.p-side_icon__groups {
  background-image: url(/images/icon_side_groups.png);
}
.p-side_child_linkTxt {
  color: $white;
  font-size: $font-s;
  display: block;
  padding: 20px 10px 20px 36px;
}
.p-side_child_linkTxt,
.p-side_child_menuListItem {
  @extend %link-el;
  @extend %textdecoration-none;
  &:hover {
    background: rgba($dark, 30%);
  }
}
.p-side_selectToolList {
  transition: 0.2s all linear;
  position: absolute;
  top: 100%;
  @extend %default-box;
  z-index: 100;
}
.p-side_selectToolListItem {
  display: block;
  color: $text;
  padding: $base-padding;
  @extend %textdecoration-none;
  &.p-side_selectToolListItem__isActive {
    @extend %list-is-active;
    background-size: 16px auto;
    background-position: 95% 50%;
  }
}
li:first-child .p-side_selectToolListItem__isActive {
  border-radius: 6px 6px 0 0;
}
li:last-child .p-side_selectToolListItem__isActive {
  border-radius: 0 0 6px 6px;
}
/* search box */
.p-side_searchBox {
  padding: 10px;
  border-top: 1px solid $scales;
}
.p-side_search_input {
  @extend %form-common;
  @extend %font-family-base;
  width: 100%;
  padding: 5px 5px 5px 30px;
  margin-bottom: 10px;
  resize: none;
  display: block;
}
.p-side_search_icon {
  position: absolute;
  top: 8px;
  left: 8px;
}
.p-side_search_submit {
  -webkit-appearance: none;
  border: none;
  height: 100%;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  width: 100%;
}
.p-side_search_submitList {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  & li {
    flex-grow: 1;
  }
  & li:first-child {
    border-radius: 6px 0 0 6px;
  }
  & li:last-child {
    border-radius: 0 6px 6px 0;
  }
  & li:not(:last-child) {
    border-right: 1px solid $dark;
  }
}
.p-side_search_ListItem {
  height: 40px;
  background: #908c87;
  transition: all 0.2s linear;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.8);
  &:hover {
    background-color: $primary;
  }
}

/*=================
[PC]
side-menu
==================*/

@include media-pc {
  .l-side_wrap {
    height: 100vh;
    min-height: calc(100% - 48px);
    flex: 0 0 $side-width-pc;
    overflow: scroll;
    position: sticky;
    top: 0;
    bottom: 0;
  }
  .p-side_selectToolList {
    width: 90%;
    left: 50%;
    transform: translate(-50%, 0);
  }
  .p-side_search_ListItem {
    &:hover:after {
      font-size: $font-xs;
      position: absolute;
      top: 130%;
      left: 0;
      display: inline-block;
      padding: 3px 5px;
      background: $white;
      border-radius: calc(#{$base-radius} / 2);
      color: $text;
      z-index: 11;
      white-space: nowrap;
    }
    &:hover:before {
      content: '▲';
      position: absolute;
      top: 104%;
      left: 30%;
      z-index: 10;
      color: $white;
    }
    & :focus {
      background-color: $primary;
    }
    & input {
      border-radius: 0;
    }
  }
  .p-side_title {
    padding: 20px 44px 20px 10px;
    font-size: 2rem;
    position: relative;
  }
  .p-side_menuList_title__isActive {
    position: relative;
    &:after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 7.5px 8px 7.5px 0;
      border-color: transparent #f3f2f1 transparent transparent;
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -7px;
    }
  }
  .p-side_search_ListItem.group {
    & input {
      border-radius: 6px 0 0 6px;
    }
    &:hover:after {
      content: '団体検索';
    }
  }
  .p-side_search_ListItem.event:hover:after {
    content: 'イベント検索';
  }
  .p-side_search_ListItem.partner:hover:after {
    content: 'パートナー検索';
  }
  .p-side_search_ListItem.member:hover:after {
    content: '会員検索';
  }
  .p-side_search_ListItem.order:hover:after {
    content: '注文検索';
  }
  .p-side_search_ListItem.photo:hover:after {
    content: '写真検索';
  }
  .p-side_search_ListItem.cameraman {
    & input {
      border-radius: 0 6px 6px 0;
    }
    &:hover:after {
      content: 'カメラマン検索';
      left: auto;
      right: 0;
    }
  }
}

.p-side_search_group {
  background-image: url('/images/icon_search_group.png');
  background-size: auto 18px;
}
.p-side_search_event {
  background-image: url('/images/icon_search_event.png');
  background-size: auto 19px;
}
.p-side_search_partner {
  background-image: url('/images/icon_search_partner.png');
  background-size: auto 17px;
}
.p-side_search_member {
  background-image: url('/images/icon_search_member.png');
  background-size: auto 20px;
}
.p-side_search_order {
  background-image: url('/images/icon_search_order.png');
  background-size: auto 20px;
}
.p-side_search_photo {
  background-image: url('/images/icon_search_photo.png');
  background-size: auto 16px;
}
.p-side_search_cameraman {
  background-image: url('/images/icon_search_cameraman.png');
  background-size: auto 21px;
  background-position: 50% 60%;
}

/*=================
[SP]
side-menu
==================*/
@include media_sp {
  /* side menu */
  .l-side_wrap {
    position: relative;
  }
  .p-side_titleWrap {
    display: flex;
    justify-content: space-between;
  }
  .p-side_title {
    padding: 18px 45px 18px 15px;
    font-size: $font-m;
    margin-right: auto;
  }
  .p-side_searchBtn_sp,
  .p-side_menuBtn_sp {
    display: block;
    width: 54px;
    height: 60px;
  }
  .p-side_searchBtn_sp {
    padding: 16px 14px 10px 14px;
  }
  .p-side_menuBtn_sp {
    overflow: hidden;
    position: relative;
  }
  .p-side_menuWrap {
    transition: 0.2s all linear;
    width: 100%;
    height: 100vh;
    overflow-y: scroll;
    background: rgba(0, 0, 0, 0);
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1000;
    transform: translate(100%);
  }
  .p-side_menuWrap.is-open {
    transform: translate(0);
    background: rgba(0, 0, 0, 0.7);
  }
  .p-side_menuCloseBtn_sp {
    display: block;
    width: 23px;
    height: 23px;
    position: absolute;
    top: 20px;
    right: 77%;
  }
  .p-side_menuList {
    width: 75%;
    height: 100vh;
    overflow-y: scroll;
    position: absolute;
    right: 0;
    top: 0;
    background: $dark90;
  }
  .p-side_selectToolList {
    width: 50%;
    left: 10px;
  }
  .p-side_selectToolListItem__isActive {
    background-position: 95% 50%;
  }
  /* search box */
  .p-side_searchBox {
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    background: mix(white, $dark90, 10%);
    z-index: 100;
  }
  .p-side_search_input {
    background-position: 10px 50%;
  }
  .p-side_search_mask_sp {
    width: 100%;
    overflow-x: auto;
  }
  .p-side_search_mask_sp::-webkit-scrollbar {
    height: 3px;
  }
  .p-side_search_mask_sp::-webkit-scrollbar-track {
    border-radius: 2px;
    background: $dark;
  }
  .p-side_search_mask_sp::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #908c87;
  }
  .p-side_search_submitList {
    width: auto;
    flex-wrap: nowrap;
    padding-bottom: 5px;
    & li,
    & li:first-child,
    & li:last-child {
      border-radius: 6px;
    }
    & li:not(:last-child) {
      border-right: none;
    }
  }
  .p-side_search_ListItem {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
    height: 34px;
    padding: 3px 10px 3px 2px;
    margin-right: 14px;
    width: auto;
    position: relative;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.8);
    &:hover:after {
      content: none;
    }
    &:hover:before {
      content: none;
    }
  }
  .p-side_search_label {
    font-size: $font-s;
  }
  .p-side_search_submit {
    width: 20px;
  }
}
