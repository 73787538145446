@import '@/css/import/import';

.arrow {
  color: $primary;
  cursor: pointer;
  display: inline-block;
  margin: 5px;
}

.resultTableButtonsCell {
  align-items: center;
  display: flex;
}

.resultDetailRow {
  background-color: $white;
}

.innerTableButtons {
  margin: 5px;
  text-align: center;
}

.closeDetailLinkWrapper {
  align-items: center;
  display: flex;
  justify-content: center;
}

.closeDetailLink {
  align-items: center;
  color: $scales60;
  cursor: pointer;
  display: flex;
  text-decoration: underline;
}
