@import '@/css/import/import';

.fileCell {
  border: 1px solid $scales;
  padding: 5px 10px;
}

.fileCellHead {
  background: $bg_paleGr40;
  border: 1px solid $scales;
}

.externalLinkIcon {
  vertical-align: middle;
}
